const mutations = {
  SET_HORSES_LIST(state, data) {
    state.horsesList = data;
  },
  SET_TOTAL_ELEMENTS(state, data) {
    state.totalElements = data;
  },
  SET_HORSE_TRACKING_CARD(state, data) {
    state.horseTrackingCard = data;
  },
  SET_SHARING_IN_LIST(state, data) {
    state.sharingInList = data;
  },
  SET_SHARING_OUT_LIST(state, data) {
    state.sharingOutList = data;
  },
};

export default mutations;

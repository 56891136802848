import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ErrorPageView from "@/views/ErrorPageView.vue";
import routeNames from "@/router/routes";

import authRoutes from "@/modules/auth/router";
import horsesRoutes from "@/modules/horses/router";
import trainingRoutes from "@/modules/training/router";

const baseRoutes = [
  {
    path: "/",
    name: routeNames.home,
    component: HomeView,
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      next({ path: `/${routeNames.horses}/0` });
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: routeNames.errorPage,
    component: ErrorPageView,
    meta: { requiresAuth: true },
  },
];

// Combine all routes
const routes = [
  ...authRoutes,
  ...horsesRoutes,
  ...trainingRoutes,
  ...baseRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  const routerAuthCheck = to.matched.some((route) => route.meta.requiresAuth);
  const accessToken = JSON.parse(
    localStorage.getItem("horsecare-userTokens")
  )?.refreshToken;

  if (routerAuthCheck) {
    if (accessToken) next();
    else next({ name: routeNames.login });
  } else if (!routerAuthCheck) {
    if (!accessToken) next();
    else next({ path: `/${routeNames.horses}/0` });
  }
});

export default router;

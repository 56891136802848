<template>
  <div
    v-if="horseTrackingCard"
    class="w-full md:max-w-[460px] mx-auto mb-4 p-4 flex flex-col justify-center items-center bg-white shadow rounded-lg"
  >
    <div class="min-w-[260px] flex items-center justify-center">
      <img
        :src="setAvatarUrl(horseTrackingCard.avatarUrl)"
        class="mr-2 text-white rounded-full object-cover"
        :class="avatarSize"
        alt="avatar"
      />
      <div
        class="flex flex-col justify-center items-start"
        :class="{ 'w-full': $route.name === routeNames.training }"
      >
        <div class="text-[#5A5A5F] text-[21px] font-semibold">
          {{ horseTrackingCard.name }}
        </div>
        <div
          v-if="this.$route.name !== routeNames.comparison"
          class="text-[#A3A3A3] text-[15px]"
        >
          {{ formattedDate }}
        </div>
        <div
          v-if="this.$route.name !== routeNames.comparison"
          class="text-[#A3A3A3] text-[15px]"
        >
          {{ setFormattedToTimezoneTime(selectedTrainingTimeData.startTime) }} -
          {{ setFormattedToTimezoneTime(selectedTrainingTimeData.endTime) }}
        </div>
      </div>
    </div>
    <div
      v-if="this.$route.name === routeNames.training"
      class="w-full flex flex-col justify-start items-center"
    >
      <div
        class="w-full flex flex-col md:flex-row justify-between items-center"
      >
        <Button class="md:mr-1" @click="goToTraining">
          {{ $t("tracking.Tracking") }}
        </Button>
        <Button
          class="md:ml-1"
          @click="$router.push({ name: routeNames.comparison })"
        >
          {{ $t("training.Trainings history") }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "date-fns";
import { mapGetters } from "vuex";
import Button from "@/shared/components/ui/Button.vue";
import { enUS, ru } from "date-fns/locale";
import routeNames from "@/router/routes";

export default {
  name: "HorseInfoComponent",
  components: { Button },
  props: {
    horseTrackingCard: Object,
  },
  data() {
    return {
      routeNames,
    };
  },
  computed: {
    ...mapGetters(["selectedDayTrainingsMetadata", "selectedTrainingTimeData"]),
    formattedDate() {
      const locale = this.$i18n.locale === "Ru" ? ru : enUS;
      const date = this.selectedDayTrainingsMetadata.date
        ? new Date(this.selectedDayTrainingsMetadata.date)
        : new Date();
      return format(date, "dd MMM yyyy", { locale: locale });
    },
    avatarSize() {
      return this.$route.name === routeNames.training
        ? "w-[160px] h-[100px]"
        : "w-16 h-16";
    },
  },
  methods: {
    goToTraining() {
      this.$router.push({
        name: routeNames.tracking,
        params: { trainingId: this.$route.params.trainingId },
      });
    },
    setAvatarUrl(url) {
      if (url && url.includes("https://")) return url;
      else return require("@/assets/images/horse.png");
    },
    setFormattedToTimezoneTime(time) {
      const time_parts = time.split(":");
      const millisecond = time_parts[0] * (60000 * 60) + time_parts[1] * 60000;
      const userTz = new Date().getTimezoneOffset() * 60000;
      const milliseconds = millisecond + Math.abs(userTz);

      let minutes = Math.floor(milliseconds / 3600000);
      let seconds = ((milliseconds % 3600000) / 60000).toFixed(0);

      return (
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds
      );
    },
  },
};
</script>

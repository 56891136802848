<template>
  <div class="flex gap-2 w-full ml-2 mb-4">
    <div>
      <SelectControl
        :style="{
          flexDirection: 'row',
          marginBottom: '8px',
          width: '100%',
        }"
        dataKey="trackingMode"
        :data="trackingMode"
        :options="trackingModesOptions"
        @update="handleTrackingMode"
      />
      <TableComponent
        v-if="reports && reports.TRACKING"
        :tableData="tableData[0]"
      />
    </div>
    <TableComponent
      v-if="reports && reports.TRACKING"
      :tableData="tableData[1]"
    />
    <TableComponent
      v-if="reports && reports.TRACKING"
      :tableData="tableData[2]"
    />
  </div>

  <DoughnutsList />
  <TabCharts />
</template>

<script>
import DoughnutsList from "@/modules/training/components/DoughnutsList.vue";
import SelectControl from "@/shared/components/ui/SelectControl.vue";
import TableComponent from "@/shared/components/ui/TableComponent.vue";
import { mapActions, mapGetters } from "vuex";
import { trackingModeKeys, trackingModes } from "@/modules/training/constants";
import { convertSecondsToTs } from "@/modules/training/helpers";
import TabCharts from "@/modules/training/components/TabCharts.vue";

export default {
  name: "TrainingGeneralAnalysis",
  components: {
    TabCharts,
    TableComponent,
    SelectControl,
    DoughnutsList,
  },
  computed: {
    ...mapGetters([
      "trackingMode",
      "reports",
      "selectedChartsList",
      "selectedDayTrainingsMetadata",
      "GPSDatafiles",
      "HRDatafiles",
    ]),
    trackingModesOptions() {
      if (
        this.GPSDatafiles?.length &&
        this.reports.GPS?.resampled !== null &&
        this.reports.GPS?.filtered !== null
      ) {
        return trackingModes;
      }
      return [trackingModeKeys.ML];
    },

    trainingDuration() {
      if (!this.reports.training_duration_seconds) return "";
      if (this.trackingMode === trackingModeKeys.GPS) {
        return convertSecondsToTs(this.reports.total_training_duration_GPS);
      } else if (this.trackingMode === trackingModeKeys.ML) {
        return convertSecondsToTs(this.reports.total_training_duration_ML);
      }
      return "00:00:00";
    },
    allEnergyCost() {
      return this.reports.total_energy_expended
        ? (this.reports.total_energy_expended / 1000).toFixed(2)
        : 0;
    },
    totalDistance() {
      if (this.GPSDatafiles && this.reports.total_distance_gps_meters) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return Math.ceil(this.reports.total_distance_gps_meters);
        } else if (this.trackingMode === trackingModeKeys.ML) {
          return Math.ceil(this.reports.total_distance_ml_meters);
        }
      } else if (this.reports.TRACKING && this.reports.TRACKING.ts) {
        return Math.ceil(this.reports.total_distance_ml_meters);
      }
      return "-";
    },
    maxSpeed() {
      if (this.reports.total_max_speed_ML) {
        return this.reports.total_max_speed_ML.toFixed(2);
      }
      return "-";
    },
    avgSpeed() {
      if (this.reports.TRACKING && this.reports.TRACKING.ts) {
        const [minutes, seconds] = this.trainingDuration.split(":").map(Number);
        const timeInSeconds = minutes * 60 + seconds;

        const avgSpeed = this.totalDistance / timeInSeconds;
        return Math.round(avgSpeed * 100) / 100;
      }
      return "-";
    },
    maxAcceleration() {
      if (this.reports && this.reports.total_max_acceleration_ML) {
        return this.reports.total_max_acceleration_ML.toFixed(2);
      }
      return "-";
    },
    avgTouchStiffness() {
      if (this.reports && this.reports.total_average_stiffness_power) {
        return this.reports.total_average_stiffness_power.toFixed(2);
      }
      return "-";
    },
    avgHR() {
      if (this.reports && this.HRDatafiles?.length && this.reports.hr_average) {
        return Math.round(this.reports.hr_average);
      }
      return "-";
    },
    maxHR() {
      if (this.reports && this.HRDatafiles?.length && this.reports.hr_max) {
        return Math.round(this.reports.hr_max);
      }
      return "-";
    },
    rhythmDeviation() {
      if (!this.reports.total_average_rhythm_deviation) return "-";
      return this.reports.total_average_rhythm_deviation.toFixed(2);
    },
    jumpCount() {
      if (!this.reports.JUMP_count) return "-";
      return this.reports.JUMP_count;
    },

    tableData() {
      return [
        // first table
        [
          {
            name: this.$t("training.Training total distance"),
            value: this.totalDistance,
            disabled: false,
          },
          {
            name: this.$t("training.Training duration"),
            value: this.trainingDuration,
            disabled: false,
          },
          {
            name: this.$t("training.Total energy expended"),
            value: this.allEnergyCost,
            disabled: false,
          },
        ],

        // second table
        [
          {
            name: this.$t("training.Average speed"),
            value: this.avgSpeed,
            disabled: false,
          },
          {
            name: this.$t("training.Max speed"),
            value: this.maxSpeed,
            disabled: false,
          },
          {
            name: this.$t("training.Max acceleration"),
            value: this.maxAcceleration,
            disabled: false,
          },
          {
            name: this.$t("training.Average touch stiffness"),
            value: this.avgTouchStiffness,
            disabled: false,
          },
        ],

        // third table
        [
          {
            name: this.$t("training.Average HR"),
            value: this.avgHR,
            disabled: Boolean(!this.HRDatafiles),
          },
          {
            name: this.$t("training.Max HR"),
            value: this.maxHR,
            disabled: Boolean(!this.HRDatafiles),
          },
          {
            name: this.$t("training.Rhythm deviation"),
            value: this.rhythmDeviation,
            disabled: false,
          },
          {
            name: this.$t("training.Jump count"),
            value: this.jumpCount,
            disabled: false,
          },
        ],
      ];
    },
  },

  watch: {
    selectedCharts(newData) {
      this.availableChartsList.map((i) => {
        newData.includes(i.data) ? (i.isEnabled = true) : (i.isEnabled = false);
      });
    },
  },

  methods: {
    ...mapActions(["handleTrackingMode"]),
  },
};
</script>

<style lang="scss" scoped>
:deep(#trackingMode) {
  height: 40px;
  font-size: 14px;
}
</style>

<template>
  <div
    v-show="isMapReady"
    class="controls absolute top-[10px] left-0 w-full h-400px z-10"
  >
    <button id="trigger" @click="setHeading(10)">
      <ArrowUturnLeftIcon class="h-6 w-6 text-center" aria-hidden="true" />
    </button>
    <button @click="setHeading(-10)">
      <ArrowUturnRightIcon class="h-6 w-6 text-center" aria-hidden="true" />
    </button>
    <button @click="zoomIn">
      <MagnifyingGlassPlusIcon class="h-6 w-6 text-center" aria-hidden="true" />
    </button>
    <button @click="zoomOut">
      <MagnifyingGlassMinusIcon
        class="h-6 w-6 text-center"
        aria-hidden="true"
      />
    </button>
    <button
      v-if="!isFullscreenMapOpen"
      style="margin-left: auto"
      @click.prevent="handleClick"
    >
      <ArrowsPointingOutIcon class="h-6 w-6 text-center" aria-hidden="true" />
    </button>
  </div>

  <div v-if="!isMapReady" class="mt-8 ml-8">Loading map...</div>
  <div
    :id="mapId"
    class="rounded-lg h-full w-full"
    :class="isMapReady ? 'opacity-100' : 'opacity-0'"
  ></div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  version: "beta",
  libraries: ["places"],
});

let polylines = [];

import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from "@heroicons/vue/20/solid";
import { trackingModeKeys } from "@/modules/training/constants";
import { mapGetters, mapMutations } from "vuex";
import { ArrowsPointingOutIcon } from "@heroicons/vue/24/outline";
import { debounce } from "lodash";

export default {
  name: "GoogleMaps",
  components: {
    ArrowUturnRightIcon,
    MagnifyingGlassPlusIcon,
    MagnifyingGlassMinusIcon,
    ArrowUturnLeftIcon,
    ArrowsPointingOutIcon,
  },
  props: {
    mapId: String,
    tracking: Array,
    selectedGaits: Array,
    trackingMarkersLength: Number,
    isTrackColored: {
      type: Boolean,
      default: true,
      required: false,
    },
    fromToCutValue: Array,
    cutValue: Array,
  },
  data() {
    return {
      map: null,
      marker: null,
      currentPositionMarker: null,
      trackPlanCoordinates: [],
      isTrackingMarkersLengthChanged: false,
      selectedGaitColors: [],
      mapOptions: {
        center: { lat: 0, lng: 0 },
        mapTypeId: "satellite",
        tilt: 0,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        keyboardShortcuts: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        zoom: 17,
      },
      isMapReady: true,
    };
  },
  computed: {
    ...mapGetters([
      "currentTrackIndex",
      "initialPositionGPS",
      "GPSDatafiles",
      "reports",
      "trackingMode",

      "googleMapsZoom",
      "googleMapsHeading",
      "googleMapsCenter",
      "isFullscreenMapOpen",
    ]),
  },
  watch: {
    async tracking() {
      if (this.trackingMode === trackingModeKeys.GPS) {
        // eslint-disable-next-line
        this.marker = new google.maps.Marker({
          position: {
            lat: this.mapOptions.center.lat,
            lng: this.mapOptions.center.lng,
          },
          map: this.map,
          title: "Start Position",
        });
      }
    },
    async isTrackColored() {
      await this.setTrackCoordinates();
    },
    async trackingMarkersLength() {
      this.isTrackingMarkersLengthChanged = true;
      await this.setTrackCoordinates();
      this.updateCurrentPositionMarker(this.currentTrackIndex);
    },
    currentTrackIndex(newVal) {
      this.updateCurrentPositionMarker(newVal);
    },
    selectedGaits: {
      handler: async function (newVal) {
        if (this.map) {
          this.selectedGaitColors = newVal
            .filter((i) => i.checked)
            .map((i) => i.backgroundColor);
          if (!this.isTrackingMarkersLengthChanged) {
            await this.setTrackCoordinates();
          }
          this.isTrackingMarkersLengthChanged = false;
        }
      },
      deep: true,
    },
    fromToCutValue: {
      handler: async function (newVal) {
        this.debouncedMarkersHandler(newVal);
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations([
      "SET_GOOGLE_MAPS_ZOOM",
      "SET_GOOGLE_MAPS_HEADING",
      "SET_GOOGLE_MAPS_CENTER",
      "SET_IS_FULLSCREEN_MAP_OPEN",
    ]),

    debouncedMarkersHandler: debounce(function () {
      this.updateCutMarkers();
    }, 100),

    handleClick() {
      this.SET_IS_FULLSCREEN_MAP_OPEN(true);
    },

    updateMapCenter() {
      const center = this.map.getCenter();
      this.SET_GOOGLE_MAPS_CENTER({
        lat: center.lat(),
        lng: center.lng(),
      });
    },

    async updateCutMarkers() {
      if (!this.fromToCutValue || this.fromToCutValue.length < 2) return;
      const [cutFrom, cutTo] = this.fromToCutValue;

      // Remove previous markers
      if (this.cutMarkers) {
        this.cutMarkers.forEach((marker) => marker.setMap(null));
      }

      this.cutMarkers = [];

      [cutFrom, cutTo].forEach((i) => {
        const newIndex = this.cutValue ? i - this.cutValue[0] : i;

        const point = this.trackPlanCoordinates[newIndex];
        if (!point || point.lat === null || point.lng === null) return;

        // eslint-disable-next-line
        const marker = new google.maps.Marker({
          position: { lat: point.lat, lng: point.lng },
          map: this.map,
          icon: {
            // eslint-disable-next-line
            path: google.maps.SymbolPath.CIRCLE,
            scale: 2,
            fillColor: "black",
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: "black",
          },
        });

        this.cutMarkers.push(marker);
      });
    },

    updateCurrentPositionMarker(newVal) {
      if (this.trackingMode === trackingModeKeys.GPS) {
        const position = this.trackPlanCoordinates[newVal];
        if (position && position.lat !== null && position.lng !== null) {
          if (this.currentPositionMarker) {
            this.currentPositionMarker.setPosition(position);
          } else {
            this.createCurrentPositionMarker(position);
          }
        } else if (this.currentPositionMarker) {
          this.currentPositionMarker.setMap(null);
        }
      }
    },
    async createCurrentPositionMarker(position) {
      // eslint-disable-next-line
      this.currentPositionMarker = new google.maps.Marker({
        position: position,
        map: this.map,
        icon: {
          // eslint-disable-next-line
          path: google.maps.SymbolPath.CIRCLE,
          scale: 2,
          fillColor: "red",
          fillOpacity: 1,
          strokeWeight: 5,
          strokeColor: "red",
        },
      });
    },

    initializeGoogleMaps() {
      return new Promise((resolve, reject) => {
        loader
          .load()
          .then(async (google) => {
            this.isMapReady = false;
            const mapElement = document.getElementById(this.mapId);

            this.map = new google.maps.Map(mapElement, {
              ...this.mapOptions,
              center: this.googleMapsCenter,
              zoom: this.googleMapsZoom,
              heading: this.googleMapsHeading,
            });

            google.maps.event.addListenerOnce(this.map, "tilesloaded", () => {
              resolve(this.map);
            });

            google.maps.event.addListener(
              this.map,
              "center_changed",
              this.updateMapCenter
            );

            google.maps.event.addListener(this.map, "zoom_changed", () => {
              const newZoom = this.map.getZoom();
              this.SET_GOOGLE_MAPS_ZOOM(newZoom);
              this.setHeading();
            });

            this.marker = new google.maps.Marker({
              position: {
                lat: this.mapOptions.center.lat,
                lng: this.mapOptions.center.lng,
              },
              map: this.map,
              title: "Start Position",
            });

            await this.setTrackCoordinates();

            this.currentPositionMarker = null;
            const position = this.trackPlanCoordinates[this.currentTrackIndex];
            await this.createCurrentPositionMarker(position);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    async setTrackCoordinates() {
      if (this.trackingMode !== trackingModeKeys.GPS) return;

      // Clear existing polylines only if necessary
      if (polylines.length > 0) {
        polylines.forEach((polyline) => polyline.setMap(null));
      }

      await this.$nextTick();

      const newPolylines = [];
      let segmentCoordinates = [];
      let currentColor = this.tracking[0] ? this.tracking[0].color : "#FF0000"; // Start with the first color
      let isCurrentSegmentValid = [...this.selectedGaitColors].includes(
        currentColor
      ); // Track if the current segment is valid

      const trackPlanCoordinates = [];

      this.tracking.forEach((point, index) => {
        const nextPoint = this.tracking[index + 1];
        const nextColor = nextPoint?.color || currentColor; // Safely access the next point's color

        if (point.lat !== null && point.lng !== null) {
          // Accumulate valid lat/lng into segment
          segmentCoordinates.push({ lat: point.lat, lng: point.lng });
          trackPlanCoordinates.push({ lat: point.lat, lng: point.lng });

          const isLastPoint = index === this.tracking.length - 1;
          const isColorChange = isLastPoint || nextColor !== currentColor;

          // Handle color changes or last segment
          if (isColorChange) {
            // Determine if the current segment should be drawn in the selected colors or as grey
            const strokeColor =
              this.isTrackColored && isCurrentSegmentValid
                ? currentColor // Use currentColor for valid segments
                : "rgba(255,255,255,0.3)"; // Default to grey when gaits are toggled off

            // Add the polyline only if it is valid (grey or colored based on the selection)
            newPolylines.push(
              // eslint-disable-next-line
              new google.maps.Polyline({
                path: segmentCoordinates,
                geodesic: true,
                strokeColor: strokeColor,
                strokeOpacity: 1.0,
                strokeWeight: 2,
              })
            );

            // Reset segment coordinates for the next polyline
            segmentCoordinates = [];

            // Update currentColor and validity for the next segment
            if (!isLastPoint) {
              currentColor = nextColor;
              isCurrentSegmentValid = [...this.selectedGaitColors].includes(
                nextColor
              );
            }
          }
        } else {
          // Handle gaps in the data (null lat/lng)
          if (segmentCoordinates.length > 0) {
            // Draw the current segment, either grey or colored based on isTrackColored
            const strokeColor =
              this.isTrackColored && isCurrentSegmentValid
                ? currentColor // Use current color if valid
                : "#F5F5F5"; // Default to grey for gaps

            newPolylines.push(
              // eslint-disable-next-line
              new google.maps.Polyline({
                path: segmentCoordinates,
                geodesic: true,
                strokeColor: strokeColor,
                strokeOpacity: 1.0,
                strokeWeight: 2,
              })
            );

            segmentCoordinates = []; // Clear segment after drawing
          }
          trackPlanCoordinates.push(null); // Represent the gap in track coordinates
        }
      });

      // Set the polylines on the map in one batch
      newPolylines.forEach((polyline) => polyline.setMap(this.map));
      polylines = newPolylines;

      this.trackPlanCoordinates = trackPlanCoordinates;
      this.isTrackingMarkersLengthChanged = false;
    },

    async setHeading(deg = 0) {
      await this.$nextTick();
      let newHeading = this.googleMapsHeading + deg;
      // Normalize the angle to be between 0 and 359 degrees
      newHeading = ((newHeading % 360) + 360) % 360;
      this.map.heading = newHeading;
      // eslint-disable-next-line
      google.maps.event.trigger(this.map, "center_changed");
      this.SET_GOOGLE_MAPS_HEADING(newHeading);
    },

    zoomIn() {
      if (this.map) {
        const newZoom = this.map.getZoom() + 1;
        this.map.setZoom(newZoom);
        this.SET_GOOGLE_MAPS_ZOOM(newZoom);
      }
    },
    zoomOut() {
      if (this.map) {
        const newZoom = this.map.getZoom() - 1;
        this.map.setZoom(newZoom);
        this.SET_GOOGLE_MAPS_ZOOM(newZoom);
      }
    },
  },

  async mounted() {
    this.selectedGaitColors = this.selectedGaits
      .filter((i) => i.checked)
      .map((i) => i.backgroundColor);

    this.mapOptions = {
      ...this.mapOptions,
      center: {
        lat: this.initialPositionGPS.latitude,
        lng: this.initialPositionGPS.longitude,
      },
    };

    try {
      await this.initializeGoogleMaps();
      // eslint-disable-next-line
      google.maps.event.trigger(this.map, "zoom_changed");
      // eslint-disable-next-line
      google.maps.event.trigger(this.map, "center_changed");

      setTimeout(() => {
        this.isMapReady = true;
      }, 350);
    } catch (error) {
      console.error("Map loading error", error);
    }
  },
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;

  button {
    padding: 5px 10px;
    margin: 0 5px;
    background: #fff;
    color: #666;
  }
}
</style>

const movements = [
  {
    id: "stay",
    title: "tracking.Stay",
    backgroundColor: "grey",
    checked: true,
  },
  {
    id: "walk",
    title: "tracking.Walk",
    backgroundColor: "#1AB0B0",
    checked: true,
  },
  {
    id: "trot",
    title: "tracking.Trot",
    backgroundColor: "#FFCD4B",
    checked: true,
  },
  {
    id: "gallop",
    title: "tracking.Gallop",
    backgroundColor: "#F85C7F",
    checked: true,
  },
  {
    id: "jump",
    title: "tracking.Jumps",
    backgroundColor: "black",
    checked: true,
  },
];

const allures = ["walk", "trot", "gallop"];

const keyboardShortcutsInfo = [
  {
    id: "0",
    description: "shortcuts.0",
    key: "0",
  },
  {
    id: "1",
    description: "shortcuts.1",
    key: "1",
  },
  {
    id: "2",
    description: "shortcuts.2",
    key: "2",
  },
  {
    id: "3",
    description: "shortcuts.3",
    key: "3",
  },
  {
    id: "4",
    description: "shortcuts.4",
    key: "4",
  },
  {
    id: "5",
    description: "shortcuts.5",
    key: "5",
  },
  {
    id: "arrowRight",
    description: "shortcuts.ArrowRight",
    key: "→",
  },
  {
    id: "arrowLeft",
    description: "shortcuts.ArrowLeft",
    key: "←",
  },
  {
    id: "Space",
    description: "shortcuts.Space",
    key: "shortcuts.SpaceTitle",
  },
  {
    id: "Alt",
    description: "shortcuts.Alt",
    key: "Alt",
  },
  {
    id: "Shift",
    description: "shortcuts.Shift",
    key: "Shift",
  },
];

const comparisonMultichartInfo = [
  {
    id: "Alt",
    description: "shortcuts.Alt",
    key: "Alt",
  },
  {
    id: "Click",
    description: "shortcuts.Click",
    key: "Click",
  },
];

const trackingModeKeys = {
  GPS: "GPS",
  ML: "ML",
};

const trackingModes = [trackingModeKeys.GPS, trackingModeKeys.ML];

const defaultGaitData = {
  second: 0,
  ts: "00:00:00.0",
  gait: "stay",
  color: "silver",
};

const animationSpeedOptions = [
  { id: 0, label: "0.25x", speed: 0.25 },
  { id: 1, label: "0.5x", speed: 0.5 },
  { id: 2, label: "1x", speed: 1 },
  { id: 3, label: "1.5x", speed: 1.5 },
  { id: 4, label: "2x", speed: 2 },
];

const defaultSeparateWindowsPosition = {
  map: {
    x: 20,
    y: 20,
    width: 400,
    height: 400,
  },
  navigation: {
    x: 20,
    y: 20,
    width: 1200,
    height: 350,
  },
  multichart: {
    x: 20,
    y: 20,
    width: 1200,
    height: 650,
  },
  animation: {
    x: 20,
    y: 20,
    width: 800,
    height: 750,
  },
};

export {
  movements,
  allures,
  trackingModes,
  trackingModeKeys,
  defaultGaitData,
  keyboardShortcutsInfo,
  comparisonMultichartInfo,
  animationSpeedOptions,
  defaultSeparateWindowsPosition,
};

import api from "@/axiosConfig";
import i18n from "@/translations";
import { useToast } from "vue-toastification";
const toast = useToast();

const actions = {
  async getHorsesList({ commit }, pageNumber) {
    return api.get("horses", { params: { page: pageNumber } }).then((res) => {
      commit("SET_HORSES_LIST", res.data.content);
      commit("SET_TOTAL_ELEMENTS", res.data.totalElements);
    });
  },

  async addHorse(_, data) {
    await api
      .post("horses", data)
      .then(() =>
        toast.success(
          i18n.global.t("add-horse.Horse profile have been created")
        )
      )
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },

  async updateHorse(_, data) {
    return await api
      .put(`horses/${data.id}`, data.horseData)
      .then(() =>
        toast.success(
          i18n.global.t("add-horse.Horse details have been changed")
        )
      )
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },

  async deleteHorse(_, id) {
    return await api
      .delete(`horses/${id}`)
      .then(() =>
        toast.success(
          i18n.global.t("add-horse.Horse profile have been deleted")
        )
      )
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },

  async shareHorse(_, data) {
    return api
      .post(
        `sharing/out/request-to-user?horse-id=${data.horseId}&email=${data.email}`
      )
      .then((res) => {
        if (!res.data.success) {
          toast.error(res.data.error.error_message);
        } else {
          toast.success(
            i18n.global.t("share.Horse data was sent to user") +
              " " +
              data.email
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },

  async getSharingInList({ commit }) {
    return api
      .get(`sharing/in/requests`)
      .then((res) => {
        commit("SET_SHARING_IN_LIST", res.data.requests);
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },

  async getSharingOutList({ commit }) {
    return api
      .get(`sharing/out/requests`)
      .then((res) => {
        commit("SET_SHARING_OUT_LIST", res.data.requests);
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },

  async acceptSharingInRequest(_, id) {
    return api
      .post(`sharing/in/requests/${id}/accept`)
      .then((res) => {
        if (!res.data.success) {
          toast.error(res.data.error.error_message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },

  async deleteSharingInRequest(_, id) {
    return api
      .post(`sharing/in/requests/${id}/decline`)
      .then((res) => {
        if (!res.data.success) {
          toast.error(res.error.error_message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
};

export default actions;

<template>
  <BaseModal
    ref="animationModal"
    id="animationWindow"
    title="Animation"
    :position="position"
    :z-index="zIndex"
    :min-width="600"
    :min-height="750"
    content-class="p-4 h-full"
    @activated="$emit('activated')"
    @close="$emit('close')"
    @save-position="$emit('save-position', $event)"
    @save-size="$emit('save-size', $event)"
    @reset-position="$emit('reset-position')"
  >
    <div class="h-full">
      <TrainingSimulator
        :selectedJump="selectedJump"
        :legendWithData="legendWithData"
        :cutValue="cutValue"
        :jumpOptions="jumpOptions"
        :multicharts="multicharts"
        class="flex-grow"
      />
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import TrainingSimulator from "@/modules/training/submodules/tracking/components/TrainingSimulator.vue";

export default {
  name: "AnimationModal",
  components: {
    BaseModal,
    TrainingSimulator,
  },
  props: {
    position: {
      type: Object,
      required: true,
    },
    zIndex: {
      type: Number,
      required: true,
    },
    selectedJump: {
      type: Object,
      required: true,
    },
    legendWithData: {
      type: Array,
      required: true,
    },
    cutValue: {
      type: Array,
      required: true,
    },
    jumpOptions: {
      type: Array,
      required: true,
    },
    multicharts: {
      type: Array,
      required: true,
    },
  },
  emits: ["activated", "close", "save-position", "save-size", "reset-position"],
  mounted() {
    this.updateModalSize();
    window.addEventListener("resize", this.updateModalSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateModalSize);
  },
  methods: {
    updateModalSize() {
      this.$nextTick(() => {
        if (this.$refs.animationModal && this.$refs.animationModal.$el) {
          const modalEl = this.$refs.animationModal.$el;
          modalEl.style.height = `${this.position.height}px`;
          modalEl.style.width = `${this.position.width}px`;
        }
      });
    },
  },
};
</script>

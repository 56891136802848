<template>
  <div
    v-if="!isHorseDontHaveTrainings && reports"
    class="w-full self-start font-monrope"
  >
    <div
      class="w-full mx-auto flex flex-wrap flex-col xl:flex-row items-center xl:items-start justify-center"
      :class="setHealthClass"
    >
      <div class="z-1">
        <HorseInfoComponent
          v-if="horseTrackingCard && reports?.steps_map"
          class="mx-0 mt-0 mb-2 self-start min-w-[335px] w-full"
          :horseTrackingCard="horseTrackingCard"
        />
        <HorseHealthComponent
          :lamenessData="reports.LAMENESS_REPORT"
          :reports="reports"
        />
        <div class="flex">
          <Button
            class="md:mr-1 flex justify-center"
            @click="goToPrevInfoPage"
            :disabled="pageForInfo === 0"
          >
            <ArrowLongLeftIcon class="h-5 w-5 text-center" aria-hidden="true" />
          </Button>
          <Button
            class="md:ml-1 flex justify-center"
            @click="goToNextInfoPage"
            :disabled="pageForInfo === 1"
          >
            <ArrowLongRightIcon
              class="h-5 w-5 text-center"
              aria-hidden="true"
            />
          </Button>
        </div>
      </div>

      <div>
        <div v-if="pageForInfo === 0">
          <TrainingGeneralAnalysis />
        </div>

        <div v-if="pageForInfo === 1" class="w-full ml-2">
          <SignaturesComponent
            v-if="horseLameness"
            :title="$t('charts-selector.Lameness history')"
            :chartsData="horseLameness"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="isHorseDontHaveTrainings"
    class="h-[calc(100vh_-_150px)] w-full flex justify-center items-center text-[#5A5A5F] text-[18px] font-semibold text-center"
  >
    {{ $t("tracking.No training data for this horse") }}
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import HorseHealthComponent from "@/modules/training/components/HorseHealthComponent.vue";
import SignaturesComponent from "@/modules/training/components/SignaturesComponent.vue";
import HorseInfoComponent from "@/modules/training/components/HorseInfoComponent.vue";
import Button from "@/shared/components/ui/Button.vue";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/vue/20/solid";
import TrainingGeneralAnalysis from "@/modules/training/components/TrainingGeneralAnalysis.vue";

export default {
  name: "ChartsComponent",
  components: {
    TrainingGeneralAnalysis,
    Button,
    HorseInfoComponent,
    SignaturesComponent,
    HorseHealthComponent,
    ArrowLongLeftIcon,
    ArrowLongRightIcon,
  },
  data() {
    return {
      pageForInfo: 0,
    };
  },
  computed: {
    ...mapGetters([
      "horseLameness",
      "reports",
      "horseTrackingCard",
      "isHorseDontHaveTrainings",
    ]),
    setHealthClass() {
      if (this.reports.steps_map?.length || this.reports.STIFFNESS_METRICS) {
        return "xl:flex-nowrap";
      } else {
        return "xl:flex-wrap";
      }
    },
  },
  methods: {
    ...mapMutations([
      "SET_REPORTS_DATA",
      "SET_GPS_DATAFILES",
      "SET_HR_DATAFILES",
      "SET_HORSE_LAMENESS",
    ]),

    goToNextInfoPage() {
      this.pageForInfo = this.pageForInfo + 1;
    },
    goToPrevInfoPage() {
      this.pageForInfo = this.pageForInfo - 1;
    },
  },
  beforeUnmount() {
    this.SET_REPORTS_DATA([]);
    this.SET_GPS_DATAFILES(null);
    this.SET_HR_DATAFILES(null);
    this.SET_HORSE_LAMENESS(null);
  },
};
</script>

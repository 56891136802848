import routeNames from "@/router/routes";

const horsesRoutes = [
  {
    path: "/horses/:page",
    name: routeNames.horses,
    component: () => import("../views/HorsesView.vue"),
    meta: { requiresAuth: true },
  },
];

export default horsesRoutes;

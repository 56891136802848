<template>
  <div
    v-if="!isEmpty"
    class="bg-white drop-shadow-md rounded-lg p-4 flex flex-col justify-start items-center"
  >
    <div
      class="max-w-[600px] w-full flex flex-col-reverse 2xl:flex-row justify-between flex-wrap"
    >
      <div
        class="w-full 2xl:w-1/2 h-[160px] flex flex-col items-center justify-center mt-4"
      >
        <div class="max-w-[200px] mx-auto sm:mx-0 mb-4 ms:mb-0">
          <div class="relative">
            <Doughnut
              :data="computedDoughnutData"
              :options="options"
              :ref="id"
            />
            <div
              v-if="comparedData"
              class="absolute w-[90px] text-center left-[calc(50%_-_47px)] top-[40%] flex flex-col justify-center items-center text-[#191A1E] text-[16px] font-semibold"
            >
              <span>{{ comparedData }}</span>
              <span>{{ type }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full 2xl:w-[47%] 2xl:ml-1">
        <div class="w-full flex flex-col tracking-wider">
          <p
            class="text-[#5A5A5F] text-[16px] font-semibold text-center 2xl:text-start mb-4"
          >
            {{ title }}
          </p>
          <div
            v-for="(item, index) in computedListData"
            :key="index"
            class="w-full flex justify-between my-1"
          >
            <div class="flex items-center">
              <div
                class="w-[10px] h-[10px] rounded-full"
                :style="{ backgroundColor: item.color }"
              ></div>
              <p
                class="ml-2 text-[#0A0A0A] text-[18px] lg:text-[16px] font-semibold"
              >
                {{ $t("tracking." + item.key) }}
              </p>
            </div>
            <p
              class="text-[#9D9D9D] lg:text-[14px] xl:text-[16px] 2xl:text-[14px] flex items-center"
            >
              {{ item.value }} {{ type }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  CategoryScale,
  ChartDataLabels
);

export default {
  name: "DoughnutChart",
  components: { Doughnut },
  props: {
    id: { type: String, default: "doughnut-chart" },
    title: { type: String, default: "" },
    type: { type: String, default: "" },
    data: { type: Array, default: () => [] },
    isEmpty: { type: Boolean, default: true },
    comparedData: { type: Number, default: null },
  },
  data() {
    return {
      colors: ["#1AB0B0", "#FFCD4B", "#F85C7F", "#A0A0A0"],
      options: {
        animation: false,
        cutout: 50,
        layout: {
          padding: { left: 37, right: 40, top: 30, bottom: 30 },
        },
        plugins: {
          crosshair: false,
          datalabels: {
            color: "#9D9D9D",
            align: "end",
            anchor: "end",
            font: { size: 12, weight: "600" },
            formatter: (value, context) =>
              context.dataset.data[context.dataIndex] > 0
                ? Math.ceil(context.dataset.data[context.dataIndex]) + "%"
                : null,
            padding: 2,
          },
          tooltip: { enabled: false },
        },
      },
    };
  },
  computed: {
    computedDoughnutData() {
      if (!Array.isArray(this.data) || this.data.length === 0) {
        return { labels: [], datasets: [] };
      }
      const backgroundColors = this.colors.slice(0, this.data.length);
      return {
        labels: this.data.map((item) => item.title),
        datasets: [
          {
            backgroundColor: backgroundColors,
            data: this.data.map((item) => item.percent),
          },
        ],
      };
    },
    computedListData() {
      if (!Array.isArray(this.data) || this.data.length === 0) {
        return [];
      }
      return this.data.map((item, index) => ({
        key: item.title,
        value: item.data,
        color: this.colors[index % this.colors.length],
      }));
    },
  },
};
</script>

function getSecondsFromMinutesTimestamp(timeString) {
  const [minutes, seconds] = timeString.split(":").map(Number);
  return minutes * 60 + seconds;
}

function getTrackIndexFromTimestamp(timestamp) {
  if (!timestamp || typeof timestamp !== "string") {
    throw new Error(
      'Invalid timestamp format. Expected "mm:ss.s" or "hh:mm:ss.s".'
    );
  }

  const parts = timestamp.split(":").map(Number);
  let hours = 0,
    minutes = 0,
    secondsAndTenths = 0;

  if (parts.length === 3) {
    [hours, minutes, secondsAndTenths] = parts;
  } else if (parts.length === 2) {
    [minutes, secondsAndTenths] = parts;
  } else {
    throw new Error("Invalid timestamp format.");
  }

  const [seconds, tenths = 0] = secondsAndTenths
    .toString()
    .split(".")
    .map(Number);

  // Convert the entire timestamp into milliseconds
  const totalMilliseconds =
    hours * 60 * 60 * 1000 + // Convert hours to milliseconds
    minutes * 60 * 1000 + // Convert minutes to milliseconds
    seconds * 1000 + // Convert seconds to milliseconds
    tenths * 100; // Convert tenths to milliseconds

  return Math.floor(totalMilliseconds / 200);
}

function decimateData(data, threshold) {
  if (data.length <= threshold || threshold === 0) return data;

  const sampled = [];
  const dataLength = data.length;
  // Calculate the size of the buckets (first and last elements are always selected)
  const bucketSize = (dataLength - 2) / (threshold - 2);
  let a = 0;
  sampled.push(data[a]); // select first element

  for (let i = 0; i < threshold - 2; i++) {
    const bucketStart = Math.floor((i + 1) * bucketSize) + 1;
    const bucketEnd = Math.floor((i + 2) * bucketSize) + 1;
    const bucketEndClamped = Math.min(bucketEnd, dataLength);

    // Calculate the average value using the following buckets
    let avgX = 0,
      avgY = 0,
      count = 0;
    for (let j = bucketStart; j < bucketEndClamped; j++) {
      avgX += data[j].x !== undefined ? data[j].x : j;
      avgY += data[j].y !== undefined ? data[j].y : data[j];
      count++;
    }
    avgX /= count;
    avgY /= count;

    const rangeStart = Math.floor(i * bucketSize) + 1;
    const rangeEnd = Math.floor((i + 1) * bucketSize) + 1;

    let maxArea = -1;
    let selectedIndex = rangeStart;

    // Looking for a point in the current bucket with maximal triangle area
    for (let j = rangeStart; j < Math.min(rangeEnd, dataLength); j++) {
      const pointAX = data[a].x !== undefined ? data[a].x : a;
      const pointAY = data[a].y !== undefined ? data[a].y : data[a];
      const pointBX = data[j].x !== undefined ? data[j].x : j;
      const pointBY = data[j].y !== undefined ? data[j].y : data[j];

      const area = Math.abs(
        (pointAX - avgX) * (pointBY - pointAY) -
          (pointAX - pointBX) * (avgY - pointAY)
      );
      if (area > maxArea) {
        maxArea = area;
        selectedIndex = j;
      }
    }

    sampled.push(data[selectedIndex]);
    a = selectedIndex;
  }

  sampled.push(data[dataLength - 1]); // select last element
  return sampled;
}

function formatTimestampToSingleDecimal(timestamp) {
  const [time, milliseconds] = timestamp.split(".");
  const roundedMilliseconds = Math.round(Number(milliseconds) / 200) * 200;

  // If milliseconds becomes 1000, add one second to the base time
  if (roundedMilliseconds === 1000) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    const newTime = new Date(0, 0, 0, hours, minutes, seconds + 1);
    return newTime.toTimeString().split(" ")[0] + ".0";
  }

  // Convert milliseconds to a string and add the missing zeros
  const formattedMilliseconds = (roundedMilliseconds / 1000)
    .toFixed(1)
    .slice(2);

  return `${time}.${formattedMilliseconds}`;
}

function getBackgroundColorByGait(gaitType) {
  switch (gaitType) {
    case "walk":
      return "rgba(26, 176, 176, 0.3)";
    case "trot":
      return "rgba(255, 205, 75, 0.3)";
    case "gallop":
      return "rgba(248, 92, 127, 0.3)";
    default:
      return "rgba(192, 192, 192, 0.3)";
  }
}

function setLocalZoomData(chart) {
  const minMax = {};
  const chartScales = Object.keys(chart.scales);
  chartScales.forEach((axisId) => {
    const display = chart.options.scales[axisId].display;
    minMax[axisId] = {
      min: display ? chart.scales[axisId].min : undefined,
      max: display ? chart.scales[axisId].max : undefined,
    };
  });
  return minMax;
}

function setScalesMinMax(chart, localZoom) {
  const chartScales = Object.keys(chart.scales);
  chartScales.forEach((axisId) => {
    chart.options.scales[axisId].min = localZoom[axisId]?.min;
    chart.options.scales[axisId].max = localZoom[axisId]?.max;
  });
}

function generateZeroLine(id) {
  return {
    type: "line",
    drawTime: "beforeDatasetsDraw",
    mode: "horizontal",
    scaleID: id,
    value: 0,
    borderColor: "gray",
    borderWidth: 1,
    borderDash: [5, 5],
  };
}

function syncDatasetsByX(labels, datasets) {
  return datasets.map((dataset) => {
    const syncedData = labels.map((label) => {
      // Find the data point with matching `x`
      const point = dataset.data.find((dataPoint) => dataPoint.x === label);
      return point ? point : { x: label, y: null }; // Fill with `null` if missing
    });
    return { ...dataset, data: syncedData };
  });
}

function extractValueInBrackets(text) {
  const match = text.match(/\(([^)]+)\)/);
  return match ? match[1] : "";
}

export {
  getSecondsFromMinutesTimestamp,
  getTrackIndexFromTimestamp,
  decimateData,
  formatTimestampToSingleDecimal,
  getBackgroundColorByGait,
  setLocalZoomData,
  setScalesMinMax,
  generateZeroLine,
  extractValueInBrackets,
  syncDatasetsByX,
};

<template>
  <div class="separate-modals absolute top-0 left-0 z-60">
    <MultichartModal
      v-if="isFullscreenMultichartOpen"
      :key="multichartDragKey"
      :position="separateWindowPositions.multichart"
      :z-index="mainLayer === 'multichart' ? 53 : 52"
      :mappedData="mappedData"
      :selectedGaits="selectedGaits"
      :cutValue="cutValue"
      :secondsByGait="secondsByGait"
      :trackingMarkersLength="trackingMarkersLength"
      :multicharts="multicharts"
      :reports="reports"
      @close="closeChartModal"
      @activated="handleActivated('multichart')"
      @save-position="saveCoordinates($event.x, $event.y, 'multichart')"
      @save-size="
        saveSize(
          $event.left,
          $event.top,
          $event.width,
          $event.height,
          'multichart'
        )
      "
      @reset-position="resetPosition('multichart')"
    />

    <NavigationModal
      v-if="isFullscreenNavigationOpen"
      :key="navigationDragKey"
      :position="separateWindowPositions.navigation"
      :z-index="mainLayer === 'navigation' ? 53 : 52"
      :trackingMarkersLength="trackingMarkersLength"
      :cutValue="cutValue"
      :reports="reports"
      :movements="movements"
      :historyLength="historyLength"
      @update:historyLength="historyLength = $event"
      @close="closeNavigationWindow"
      @activated="handleActivated('navigation')"
      @save-position="saveCoordinates($event.x, $event.y, 'navigation')"
      @save-size="
        saveSize(
          $event.left,
          $event.top,
          $event.width,
          $event.height,
          'navigation'
        )
      "
      @reset-position="resetPosition('navigation')"
      @cut-tracking-value="cutTrackingValue"
      @change-cut-value="changeCutValue"
    />

    <AnimationModal
      v-if="isAnimationModalOpen"
      :key="animationDragKey"
      ref="animationModal"
      :position="separateWindowPositions.animation"
      :z-index="mainLayer === 'animation' ? 53 : 52"
      :selectedJump="selectedJump"
      :legendWithData="legendWithData"
      :cutValue="cutValue"
      :jumpOptions="jumpOptions"
      :multicharts="multicharts"
      @close="closeAnimationModal"
      @activated="handleActivated('animation')"
      @save-position="saveCoordinates($event.x, $event.y, 'animation')"
      @save-size="
        saveSize(
          $event.left,
          $event.top,
          $event.width,
          $event.height,
          'animation'
        )
      "
      @reset-position="resetPosition('animation')"
    />
  </div>
</template>

<script>
import MultichartModal from "./modals/MultichartModal.vue";
import NavigationModal from "./modals/NavigationModal.vue";
import AnimationModal from "./modals/AnimationModal.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { defaultSeparateWindowsPosition } from "@/modules/training/constants";

export default {
  name: "SeparateModals",
  components: {
    MultichartModal,
    NavigationModal,
    AnimationModal,
  },
  props: {
    mappedData: Array,
    selectedGaits: Array,
    cutValue: Array,
    secondsByGait: Array,
    trackingMarkersLength: Number,
    multicharts: Array,
    selectedJump: Object,
    legendWithData: Array,
    jumpOptions: Array,
    movements: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["cutTrackingValue", "changeCutValue"],
  data() {
    return {
      navigationDragKey: 0,
      multichartDragKey: 0,
      animationDragKey: 0,
      historyLength: 50,
      currentCoordinates: {
        x: 0,
        y: 0,
        windowKey: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "separateWindowPositions",
      "reports",
      "isFullscreenMultichartOpen",
      "isFullscreenNavigationOpen",
      "isAnimationModalOpen",
      "mainLayer",
      "currentTrackIndex",
    ]),
  },
  methods: {
    ...mapMutations([
      "SET_IS_FULLSCREEN_MULTICHART_OPEN",
      "SET_IS_FULLSCREEN_NAVIGATION_OPEN",
      "SET_MAIN_LAYER",
      "SET_IS_ANIMATION_MODAL_OPEN",
      "SET_CURRENT_TRACK_INDEX",
    ]),
    ...mapActions(["updateWindowPosition"]),

    cutTrackingValue(val) {
      this.$emit("cutTrackingValue", val);
    },

    changeCutValue(val) {
      this.$emit("changeCutValue", val);
    },

    async saveCoordinates(x, y, windowKey) {
      await this.updateWindowPosition({
        key: windowKey,
        ...this.separateWindowPositions[windowKey],
        x,
        y,
      });
    },

    async saveSize(x, y, width, height, windowKey) {
      await this.updateWindowPosition({
        key: windowKey,
        ...this.separateWindowPositions[windowKey],
        x,
        y,
        width,
        height,
      });
      await this.$nextTick();
      this.forceRecalculation(windowKey);

      // Special handling for animation modal
      if (windowKey === "animation" && this.$refs.animationModal) {
        this.$nextTick(() => {
          const modalEl = this.$refs.animationModal.$el;
          if (modalEl) {
            modalEl.style.height = `${height}px`;
            modalEl.style.width = `${width}px`;
          }
        });
      }
    },

    resetPosition(windowKey) {
      this.updateWindowPosition({
        key: windowKey,
        ...defaultSeparateWindowsPosition[windowKey],
      });
      this.forceRecalculation(windowKey);
    },

    forceRecalculation(key) {
      const keyForUpdate = `${key}DragKey`;
      this[keyForUpdate] += 1;
    },

    closeNavigationWindow() {
      this.SET_IS_FULLSCREEN_NAVIGATION_OPEN(false);
    },

    handleActivated(layerName) {
      this.SET_MAIN_LAYER(layerName);
    },

    closeChartModal() {
      this.SET_IS_FULLSCREEN_MULTICHART_OPEN(false);
    },

    closeAnimationModal() {
      this.SET_IS_ANIMATION_MODAL_OPEN(false);
      this.SET_CURRENT_TRACK_INDEX(this.currentTrackIndex + 1);
    },

    resetModalsPosition() {
      Object.keys(this.separateWindowPositions).forEach((key) => {
        this.updateWindowPosition({
          key: key,
          ...defaultSeparateWindowsPosition[key],
        });
      });
    },
  },
  mounted() {
    window.addEventListener("resize", this.resetModalsPosition);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resetModalsPosition);
  },
};
</script>

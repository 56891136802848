<template>
  <div
    class="w-full mx-auto p-4 flex justify-between flex-wrap bg-white shadow rounded-lg"
    ref="componentContainer"
  >
    <p class="w-full text-[#5A5A5F] text-[21px] font-semibold text-center mb-4">
      {{ $t("tracking.Expenditure energy") }}
    </p>
    <div class="w-full flex flex-wrap justify-center items-stretch">
      <div
        v-for="(item, index) of mappedExpenditureEnergyData"
        :key="index"
        :class="chartContainerClass"
        class="chart-container px-2 flex flex-col justify-start items-center"
      >
        <p
          class="w-full text-[#5A5A5F] text-[21px] font-semibold text-center mb-4"
        >
          {{ $t(item.title) }}
        </p>
        <div
          class="chart-area"
          :style="{ width: chartWidth + 'px', maxWidth: '100%' }"
        >
          <PolarArea :data="item.data" :options="options" />
        </div>
        <div class="text-[14px] mt-2">
          <span class="font-semibold">{{ $t("horse.BACK") }}</span>
          :
          {{ mappedExpenditureBackSensorEnergyData[index] }}
          {{ $t("training.kJ") }}
        </div>
        <div class="text-[14px]">
          <span class="font-semibold">{{ $t("horse.TOTAL") }}</span> :
          {{
            (
              +mappedExpenditureBackSensorEnergyData[index] +
              item.data.datasets[0].data.reduce(
                (partialSum, a) => partialSum + +a,
                0
              )
            ).toFixed(2)
          }}
          {{ $t("training.kJ") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart as ChartJS, RadialLinearScale, ArcElement } from "chart.js";
import { PolarArea } from "vue-chartjs";
import { getChartLabelPlugin } from "chart.js-plugin-labels-dv";

ChartJS.register(RadialLinearScale, ArcElement, getChartLabelPlugin());
export default {
  name: "ExpenditureEnergyComponent",
  props: {
    expenditureEnergy: Object,
  },
  components: {
    PolarArea,
  },
  data() {
    return {
      chartWidth: 300,
      screenWidth: window.innerWidth,
      resizeTimeout: null,
      options: {
        animation: false,
        spanGaps: true,
        decimation: true,
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: 20,
        },
        plugins: {
          crosshair: false,
          tooltip: {
            callbacks: {
              label: (el) => {
                return el.raw + " " + this.$t("training.kJ");
              },
            },
          },
          datalabels: false,
          labels: [
            {
              render: "label",
              position: "outside",
              fontSize: 19,
            },
            {
              render: (args) => {
                return args.value + " " + this.$t("training.kJ");
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    chartContainerClass() {
      // Responsive size based on screen width
      if (this.screenWidth >= 1600) {
        return "w-1/3"; // 3 per row on very large screens
      } else if (this.screenWidth >= 1200) {
        return "w-1/3"; // 3 per row on desktop
      } else if (this.screenWidth >= 768) {
        return "md:w-1/2"; // 2 per row on tablet
      } else {
        return "w-full"; // 1 per row on mobile
      }
    },

    mappedExpenditureEnergyData() {
      const labels = [
        this.$t("horse.FR"),
        this.$t("horse.HR"),
        this.$t("horse.HL"),
        this.$t("horse.FL"),
      ];

      const walk = this.setDataByType(
        "tracking.Walk",
        labels,
        this.expenditureEnergy.walk
      );
      const trot = this.setDataByType(
        "tracking.Trot",
        labels,
        this.expenditureEnergy.trot
      );
      const gallop = this.setDataByType(
        "tracking.Gallop",
        labels,
        this.expenditureEnergy.gallop
      );

      return [walk, trot, gallop];
    },
    mappedExpenditureBackSensorEnergyData() {
      if (this.expenditureEnergy.walk?.BACK) {
        const walk = (
          this.expenditureEnergy.walk.BACK.total_energy / 1000
        ).toFixed(2);
        const trot = (
          this.expenditureEnergy.trot.BACK.total_energy / 1000
        ).toFixed(2);
        const gallop = (
          this.expenditureEnergy.gallop.BACK.total_energy / 1000
        ).toFixed(2);
        return [walk, trot, gallop];
      } else {
        return [];
      }
    },
  },
  methods: {
    handleResize() {
      // Clear previous timeout to prevent multiple calls
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      // Debounce the resize event
      this.resizeTimeout = setTimeout(() => {
        this.screenWidth = window.innerWidth;
        this.calculateChartWidth();
      }, 250);
    },

    calculateChartWidth() {
      const containerWidth = this.$refs.componentContainer.clientWidth;

      // Calculate appropriate chart width based on container size and screen width
      if (this.screenWidth >= 1600) {
        this.chartWidth = Math.min(400, containerWidth / 3 - 40);
      } else if (this.screenWidth >= 1200) {
        this.chartWidth = Math.min(320, containerWidth / 3 - 40);
      } else if (this.screenWidth >= 768) {
        this.chartWidth = Math.min(300, containerWidth / 2 - 40);
      } else {
        this.chartWidth = Math.min(280, containerWidth - 40);
      }
    },

    setDataByType(title, labels, data) {
      return {
        title: title,
        data: {
          labels: labels,
          datasets: [
            {
              backgroundColor: ["#8664c4", "#f6c536", "#f88b30", "#488ff8"],
              data: data ? this.setData(data) : [],
              datalabels: {
                color: "#ffffff",
              },
            },
          ],
        },
      };
    },
    setData(data) {
      if (!data) return null;
      return [
        (+data.FR.total_energy / 1000).toFixed(2),
        (+data.BR.total_energy / 1000).toFixed(2),
        (+data.BL.total_energy / 1000).toFixed(2),
        (+data.FL.total_energy / 1000).toFixed(2),
      ];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calculateChartWidth();
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  transition: width 0.3s ease;
  margin-bottom: 20px;
}

.chart-area {
  margin: 0 auto;
}
</style>

<template>
  <div
    class="w-full self-start flex flex-col items-center justify-start font-monrope"
  >
    <div
      class="w-full min-h-[60px] flex flex-wrap justify-center items-center relative"
    >
      <BackButton />
    </div>

    <TablesList :cutValue="cutValue" />

    <div
      v-if="reports && reports.TRACKING"
      class="w-full mx-auto mb-4 px-8 py-4 pb-4 flex flex-col justify-between bg-white shadow rounded-lg"
      :style="{ paddingBottom: `${bgHeightValue}px` }"
    >
      <div class="flex items-center mb-4">
        <div class="text-[#5A5A5F] text-[21px] font-semibold text-center mr-4">
          {{ $t("tracking.Tracking") }}
        </div>
        <SelectControl
          :style="{
            flexDirection: 'row',
            marginBottom: '0',
            width: 'auto',
          }"
          dataKey="trackingMode"
          :data="trackingMode"
          :options="trackingModesOptions"
          @update="handleTrackingMode"
        />

        <template
          v-if="
            currentTabIdx === 0 && tabs[currentTabIdx].isEnabled && +jumpCount
          "
        >
          <div
            class="text-[#5A5A5F] text-[21px] font-semibold text-center mx-4"
          >
            {{ $t("tracking.Jumps") }}
          </div>
          <SelectControl
            :style="{
              flexDirection: 'row',
              marginBottom: '0',
              width: 'auto',
            }"
            dataKey="jumps"
            :options="jumpOptions.map((option) => option.label)"
            :data="selectedJump?.label || null"
            defaultOption="Select jump"
            @update="selectNewJump"
          />

          <button
            v-if="!isAnimationModalOpen"
            :disabled="!selectedJump"
            class="text-[12px] w-auto rounded p-2 bg-[#9A8053] text-white ml-2"
            :class="{ 'bg-gray-400': !selectedJump }"
            @click="openAnimationModal"
          >
            {{ $t("tracking.Open animation") }}
          </button>
        </template>
        <InformationCircleIcon
          class="w-6 h-6 ml-auto hover:text-[gray] cursor-pointer"
          @click="openShortcutModal"
        />
      </div>
      <div class="w-full flex flex-wrap">
        <div v-if="reports && reports.TRACKING" class="w-full mb-4">
          <div class="block">
            <div class="block">
              <nav
                class="isolate flex divide-x divide-gray-200 rounded-lg shadow overflow-x-auto"
                aria-label="Tabs"
              >
                <button
                  v-for="(tab, tabIdx) in tabs"
                  :key="tab.name"
                  :class="[
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tab.isEnabled ? '' : 'hidden',
                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative flex-1 whitespace-nowrap bg-[#FAF0E6] py-2 px-4 text-center hover:bg-gray-300 focus:z-10 text-[#5A5A5F] text-[18px] font-semibold flex items-center justify-center',
                  ]"
                  :aria-current="tab.current ? 'page' : undefined"
                  @click="handleSwitchTab(tabIdx)"
                >
                  <span>{{ $t(tab.name) }}</span>
                  <span
                    aria-hidden="true"
                    :class="[
                      tab.current ? 'bg-[#9A8053]' : 'bg-indigo-200',
                      'absolute inset-x-0 bottom-0 h-0.5',
                    ]"
                  />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <TrackingContent
        v-if="reports && reports.TRACKING"
        :currentMappedData="currentMappedData"
        :historyLength="historyLength"
        :fromToCutValue="fromToCutValue"
        :selectedGaits="selectedGaits"
        :trackingMarkersLength="trackingMarkersLength"
        :cutValue="cutValue"
        :currentTabIdx="currentTabIdx"
        :tabs="tabs"
        :reports="reports"
        :isFullscreenMapOpen="isFullscreenMapOpen"
        :isFullscreenMultichartOpen="isFullscreenMultichartOpen"
        :secondsByGait="secondsByGait"
        :multicharts="multicharts"
        :maxRadius="maxRadius"
        :indexColor="indexColor"
      />

      <div class="mt-4">
        <div
          v-if="!isFullscreenNavigationOpen"
          class="relative w-full flex justify-between items-center gap-8 bg-gray-50 border-2 rounded-md p-4"
        >
          <button
            @click.prevent="openFullscreenNavigation"
            class="absolute top-2 right-2 z-10 flex items-center"
          >
            <ArrowsPointingOutIcon class="h-6 w-6" aria-hidden="true" />
          </button>

          <div class="w-[70%]">
            <div class="mb-4 mx-8">
              <TrackingSlider
                :trackingMarkersLength="trackingMarkersLength"
                :cutValue="cutValue"
              />
            </div>
            <div class="mt-20 mb-4 mx-8">
              <CutTrainingSlider
                :trackingMarkersLength="reports.TRACKING.x.length"
                @cutTrackingValue="cutTrackingValue"
                @changeCutValue="changeCutValue"
              />
            </div>
          </div>

          <div class="w-[30%]">
            <TrackingControlButtons
              :trackingMarkersLength="trackingMarkersLength"
              :cutValue="cutValue"
            />

            <div class="w-full">
              <div class="w-200px flex flex-col mt-2 ml-2">
                <span class="text-[#5A5A5F] text-[18px] font-semibold"
                  >{{ $t("tracking.Horse gait history") }} =
                  {{ (historyLength / 5).toFixed(0) }}
                  {{ $t("tracking.seconds") }}</span
                >
                <Slider
                  class="w-[250px] h-1 mt-2 mb-4 cursor-pointer"
                  :tooltips="false"
                  v-model="historyLength"
                  :max="1500"
                  :min="50"
                />
              </div>
              <div class="flex flex-wrap mt-1">
                <div
                  v-for="item of movements"
                  :key="item.id"
                  class="flex items-center mx-1"
                >
                  <input
                    type="checkbox"
                    class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
                    :style="{ color: item.backgroundColor }"
                    :id="item.title"
                    v-model="item.checked"
                  />
                  <label
                    :for="item.title"
                    class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2 font-bold border-gray-700"
                  >
                    <span>{{ $t(item.title) }}</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex mt-4">
              <button
                class="p-2 rounded-md border-2 border-[#9A8053] flex items-center font-semibold text-[#9A8053]"
                @click="cutTrackingValue"
              >
                {{ $t("tracking.Crop") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="reports?.TREND && !reports?.TRACKING"
      class="mt-64 text-[#5A5A5F] text-[21px] font-semibold text-center"
    >
      {{ $t("tracking.No data available for this workout") }}
    </div>
  </div>

  <div class="self-start">
    <label class="mr-4" for="bgHeightValue">
      Additional height for tracking page
    </label>
    <input
      id="bgHeightValue"
      class="border-2 border-gray-400 rounded-lg overflow-hidden"
      type="number"
      :min="20"
      :max="1000"
      :step="20"
      placeholder="Container height"
      v-model="bgHeightValue"
      @input="validateInput"
    />
    <p v-if="errorMessage" class="text-red-500 mt-2">{{ errorMessage }}</p>
  </div>

  <SeparateModals
    v-if="reports"
    :mappedData="currentMappedData"
    :selectedGaits="selectedGaits"
    :cutValue="cutValue"
    :secondsByGait="secondsByGait"
    :trackingMarkersLength="trackingMarkersLength"
    :multicharts="multicharts"
    :selectedJump="selectedJump"
    :legendWithData="legendWithData"
    :jumpOptions="jumpOptions"
    @cutTrackingValue="cutTrackingValue"
    @changeCutValue="changeCutValue"
  />

  <ModalComponent
    medium
    confirmHidden
    xMark
    :is-open="isShortcutModalOpen"
    @handleCancel="isShortcutModalOpen = false"
  >
    <template v-slot:title>
      <span class="px-2">{{ $t("shortcuts.title") }}</span>
    </template>
    <template v-slot:content>
      <div
        v-for="item in keyboardShortcutsInfo"
        :key="item.id"
        class="text-[#5A5A5F] px-2"
      >
        <span class="font-bold">{{ $t(item.key) }}</span> -
        {{ $t(item.description) }}
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import TrackingSlider from "@/modules/training/submodules/tracking/components/TrackingSlider.vue";
import BackButton from "@/shared/components/BackButton.vue";
import Slider from "@vueform/slider";
import { mapActions, mapGetters, mapMutations } from "vuex";
import CutTrainingSlider from "@/modules/training/submodules/tracking/components/CutTrainingSlider.vue";
import TrackingControlButtons from "@/modules/training/submodules/tracking/components/TrackingControlButtons.vue";
import {
  ArrowsPointingOutIcon,
  InformationCircleIcon,
} from "@heroicons/vue/24/outline";

import {
  formatTimestamp,
  getClass,
  getSecond,
} from "@/modules/training/helpers";
import {
  keyboardShortcutsInfo,
  movements,
  trackingModeKeys,
  trackingModes,
} from "@/modules/training/constants";
import SelectControl from "@/shared/components/ui/SelectControl.vue";
import ModalComponent from "@/shared/components/ModalComponent.vue";
import {
  formatTimestampToSingleDecimal,
  getTrackIndexFromTimestamp,
} from "@/modules/training/submodules/tracking/components/multiChart/multiChartUtils";
import { computed } from "vue";
import TablesList from "@/modules/training/submodules/tracking/components/TablesList.vue";
import SeparateModals from "@/modules/training/submodules/tracking/components/SeparateModals.vue";
import TrackingContent from "@/modules/training/submodules/tracking/components/TrackingContent.vue";
import {
  convertToDeg,
  getGaitColor,
  setGaitColor,
} from "@/modules/training/submodules/tracking/helpers";

export default {
  name: "TrackingView",
  components: {
    TrackingContent,
    SeparateModals,
    TablesList,
    ArrowsPointingOutIcon,
    ModalComponent,
    SelectControl,
    TrackingControlButtons,
    BackButton,
    TrackingSlider,
    Slider,
    CutTrainingSlider,
    InformationCircleIcon,
  },

  data() {
    return {
      movements,
      trackingModes,
      keyboardShortcutsInfo,
      currentTabIdx: 0,
      historyLength: 50,
      cutValue: null,
      fromToCutValue: [],
      isShortcutModalOpen: false,
      selectedJump: null,
      legendWithData: [],
      multicharts: null,

      bgHeightValue: 20,
      errorMessage: "",
    };
  },

  provide() {
    return {
      legendWithData: computed(() => this.legendWithData),
      setLegendData: this.setLegendData,
      cutValue: computed(() => this.cutValue),
      selectNewJump: this.selectNewJump,
      jumpOptions: computed(() => this.jumpOptions),
    };
  },
  computed: {
    ...mapGetters([
      "currentTrackIndex",
      "reports",
      "GPSDatafiles",
      "selectedChartsList",
      "isTrackStarted",
      "isTrackPaused",
      "trackingMarkersLength",
      "trackingMode",
      "isAnimationModalOpen",
      "isFullscreenMultichartOpen",
      "isFullscreenMapOpen",
      "isFullscreenNavigationOpen",
    ]),
    jumpOptions() {
      if (this.reports?.JUMP?.length) {
        const allSelects = this.reports?.JUMP.map((jump, index) => ({
          id: index + 1,
          jump,
          start: this.getSecond(jump.render_start),
          end: this.getSecond(jump.render_end),
          label: `Jump ${index + 1} at ${this.formatTimestamp(jump.start)}`,
        }));

        if (this.cutValue) {
          const startSliceSecond = Math.round(this.cutValue[0] / 5);
          const endSliceSecond = Math.round(this.cutValue[1] / 5);
          return allSelects.filter((i) => {
            if (i.start > startSliceSecond && i.end < endSliceSecond) {
              return i;
            }
          });
        }

        return allSelects;
      }
      return [];
    },
    trackingModesOptions() {
      if (
        this.GPSDatafiles?.length &&
        this.reports.GPS?.resampled !== null &&
        this.reports.GPS?.filtered !== null
      ) {
        return trackingModes;
      }
      return [trackingModeKeys.ML];
    },
    tabs() {
      return [
        {
          name: "tracking.Multichart",
          href: "#",
          current: this.currentTabIdx === 0,
          isEnabled: this.selectedChartsList.includes("Multichart"),
        },
        {
          name: "tracking.Cumulative energy",
          href: "#",
          current: this.currentTabIdx === 1,
          isEnabled: this.selectedChartsList.includes("Cumulative energy"),
        },
        {
          name: "tracking.Expenditure energy",
          href: "#",
          current: this.currentTabIdx === 2,
          isEnabled: this.selectedChartsList.includes("Expenditure energy"),
        },
        {
          name: "tracking.Center of gravity",
          href: "#",
          current: this.currentTabIdx === 3,
          isEnabled: this.selectedChartsList.includes("Center of gravity"),
        },
      ];
    },
    mappedMLData() {
      if (!this.reports || !this.reports.TRACKING?.x) return [];
      return this.reports.TRACKING.x.map((i, index) => {
        return {
          x: this.reports.TRACKING.x[index],
          y: this.reports.TRACKING.y[index],
          phi: this.reports.TRACKING.phi
            ? convertToDeg(this.reports.TRACKING.phi[index])
            : 0,
          radius: this.reports.TRACKING.radius
            ? this.reports.TRACKING.radius[index]
            : 0,
          ts: this.reports.TRACKING.ts ? this.reports.TRACKING.ts[index] : 0,
          color: this.reports.TRACKING.gait
            ? setGaitColor(this.reports.TRACKING.gait[index])
            : "grey",
          id: index,
        };
      });
    },
    mappedGPSData() {
      if (
        !this.reports?.GPS?.filtered?.ts ||
        !this.reports?.GPS?.resampled?.ts ||
        !this.GPSDatafiles
      ) {
        return [];
      }

      const filteredData = this.reports.GPS.filtered;
      const resampledData = this.reports.GPS.resampled;

      // Get seconds from the timestamps
      const filteredSeconds = filteredData.ts.map((i) => this.getSecond(i));
      const resampledSeconds = resampledData.ts.map((i) => this.getSecond(i));

      const jumpIntervals = this.reports.JUMP.map((jump) => ({
        start: this.getSecond(jump.start),
        end: this.getSecond(jump.end),
      }));

      const result = resampledSeconds.map((second, index) => {
        // Find the closest match for the current second in filtered data
        const filteredIndex = filteredSeconds.findIndex(
          (filteredSecond) => filteredSecond === second
        );

        // Handle the case where the second is not found
        const isValidIndex = filteredIndex !== -1;

        const isInJumpInterval = jumpIntervals.some(
          (interval) => second >= interval.start && second <= interval.end
        );

        // Use raw GPSDatafiles for lat/lng if valid
        const gpsFile = isValidIndex ? this.GPSDatafiles[filteredIndex] : null;

        return {
          x: isValidIndex ? filteredData.x[filteredIndex] : null,
          y: isValidIndex ? filteredData.y[filteredIndex] : null,
          lat: gpsFile ? gpsFile.latitude : null,
          lng: gpsFile ? gpsFile.longitude : null,
          phi: this.reports.TRACKING?.phi
            ? convertToDeg(this.reports.TRACKING.phi[index])
            : 0,
          radius: this.reports.TRACKING?.radius
            ? this.reports.TRACKING.radius[index]
            : 0,
          ts: resampledData.ts[index], // Use the timestamp from the resampled data
          color: isInJumpInterval
            ? "black"
            : setGaitColor(resampledData.gait[index]),
          gaitType: resampledData.gait[index],
          id: index,
        };
      });

      return result;
    },

    currentMappedData() {
      if (!this.reports || !this.reports.TRACKING?.x) return [];
      else {
        if (this.trackingMode === trackingModeKeys.ML) {
          this.SET_TRACKING_MARKERS_LENGTH(this.reports.TRACKING.x.length);
          return this.mappedMLData;
        } else {
          this.SET_TRACKING_MARKERS_LENGTH(
            this.reports.GPS.resampled.ts.length
          );
          return this.mappedGPSData;
        }
      }
    },
    indexColor() {
      if (this.cutValue) {
        const newMappedDataArray = this.currentMappedData.slice(
          this.cutValue[0],
          this.cutValue[1]
        );
        const maxValue = newMappedDataArray.length - 1;

        return newMappedDataArray[
          this.currentTrackIndex > maxValue ? maxValue : this.currentTrackIndex
        ].color;
      }

      const maxValue = this.currentMappedData.length - 1;
      return this.currentMappedData[
        this.currentTrackIndex > maxValue ? maxValue : this.currentTrackIndex
      ]?.color;
    },
    selectedGaits() {
      return this.movements.filter((i) => (i.checked = true));
    },
    maxRadius() {
      return this.reports.TRACKING.radius
        ? +Math.max(...this.reports.TRACKING.radius).toFixed(2)
        : 0;
    },

    secondsByGait() {
      if (!Object.keys(this.reports.TRACKING || {}).length) return [];
      let result = this.reports.TRACKING.ts.map((i, idx) => {
        return {
          second: getTrackIndexFromTimestamp(i),
          ts: i,
          gait: this.reports.TRACKING.gait[idx],
          color: getGaitColor(this.reports.TRACKING.gait[idx]),
        };
      });

      if (this.cutValue) {
        result = result.slice(this.cutValue[0], this.cutValue[1]);
      }

      return result;
    },

    jumpCount() {
      if (!this.reports.JUMP_count) return "-";
      let result = 0;
      let startSliceSecond = 0;
      let endSliceSecond =
        this.getSecond(this.reports.SUMMARY_RESAMPLED.ts.at(-1)) + 1;

      if (this.cutValue) {
        startSliceSecond = Math.round(this.cutValue[0] / 5);
        endSliceSecond = Math.round(this.cutValue[1] / 5);
      }
      this.reports.JUMP.forEach((i) => {
        this.getSecond(i.start) >= startSliceSecond &&
          this.getSecond(i.end) <= endSliceSecond &&
          result++;
      });
      return result;
    },
  },

  watch: {
    multicharts: {
      handler: function (newVal) {
        localStorage.setItem("selected-multicharts", JSON.stringify(newVal));
      },
      deep: true,
    },

    async currentTrackIndex(newTrackIndex) {
      if (!this.isAnimationModalOpen) {
        this.findAndSelectJump(newTrackIndex);
      }
    },

    cutValue(val) {
      const newVal = val
        ? this.currentMappedData.slice(val[0], val[1]).length - 1
        : this.currentMappedData.length - 1;
      this.SET_TRACKING_MARKERS_LENGTH(newVal);

      // Reselect jump if current jump isn't in timeline range
      if (!this.jumpOptions.find((i) => i.id === this.selectedJump.id)) {
        this.jumpOptions?.length &&
          this.selectNewJump({ data: this.jumpOptions[0].label });

        this.closeAnimationModal();
      }
    },

    trackingMode() {
      this.movements.map((i) => (i.checked = true));
      this.cutTrackingValue();
      this.SET_IS_FULLSCREEN_MAP_OPEN(false);
      this.SET_IS_FULLSCREEN_MULTICHART_OPEN(false);
    },
  },
  methods: {
    ...mapMutations([
      "SET_REPORTS_DATA",
      "SET_HORSE_LAMENESS",
      "SET_GPS_DATAFILES",
      "SET_HR_DATAFILES",
      "SET_CURRENT_TRACK_INDEX",
      "SET_TRACKING_SPEED",
      "SET_IS_TRACK_STARTED",
      "SET_IS_TRACK_PAUSED",
      "SET_TRACKING_MARKERS_LENGTH",
      "SET_TRACKING_MODE",
      "SET_IS_ANIMATION_MODAL_OPEN",
      "SET_IS_FULLSCREEN_MULTICHART_OPEN",
      "SET_IS_FULLSCREEN_MAP_OPEN",
      "SET_IS_FULLSCREEN_NAVIGATION_OPEN",
      "SET_MAIN_LAYER",
    ]),
    ...mapActions([
      "playTrack",
      "pauseTrack",
      "handleTrackingMode",
      "updateWindowPosition",
      "closeAllFullscreenWindows",
    ]),
    getClass,
    getSecond,
    formatTimestamp,

    openShortcutModal() {
      this.isShortcutModalOpen = true;
      this.closeAllFullscreenWindows();
    },

    findAndSelectJump(newTrackIndex) {
      if (!this.jumpOptions || this.jumpOptions.length === 0) return;

      const closestJump = this.jumpOptions.reduce(
        (closest, jumpOption) => {
          const start = getTrackIndexFromTimestamp(
            formatTimestampToSingleDecimal(jumpOption.jump.start)
          );
          const end = getTrackIndexFromTimestamp(
            formatTimestampToSingleDecimal(jumpOption.jump.end)
          );

          const distanceToStart = Math.abs(newTrackIndex - start);
          const distanceToEnd = Math.abs(newTrackIndex - end);
          const minDistance = Math.min(distanceToStart, distanceToEnd);

          return minDistance < closest.distance
            ? { jump: jumpOption, distance: minDistance }
            : closest;
        },
        { jump: null, distance: Infinity }
      ).jump;

      if (closestJump && closestJump.id !== this.selectedJump?.id) {
        this.isUpdatingJump = true;
        this.selectedJump = {
          ...closestJump.jump,
          id: closestJump.id,
          label: closestJump.label,
        };
        this.isUpdatingJump = false;
      }
    },

    validateInput(event) {
      const value = Number(event.target.value);
      if (value < 20) {
        this.bgHeightValue = 20;
        this.errorMessage = "Minimum value is 20.";
      } else if (value > 1000) {
        this.bgHeightValue = 1000;
        this.errorMessage = "Maximum value is 1000.";
      } else {
        this.bgHeightValue = value;
        this.errorMessage = "";
      }
    },

    openFullscreenNavigation() {
      this.SET_IS_FULLSCREEN_NAVIGATION_OPEN(true);
    },

    openAnimationModal() {
      document.removeEventListener("keydown", this.handleKeyDown);
      this.SET_IS_ANIMATION_MODAL_OPEN(true);
    },

    closeAnimationModal() {
      this.SET_IS_ANIMATION_MODAL_OPEN(false);
      this.SET_CURRENT_TRACK_INDEX(this.currentTrackIndex + 1); // trigger this for track/map hard updating
      document.addEventListener("keydown", this.handleKeyDown);
    },

    selectNewJump({ data }) {
      const currentJump = this.jumpOptions.find(
        (option) => option.label === data
      );

      if (currentJump) {
        this.selectedJump = {
          ...this.reports.JUMP[currentJump.id - 1],
          id: currentJump.id,
          label: currentJump.label,
        };

        const totalSeconds = this.getSecond(this.selectedJump.start);
        let newTrackIndex; // need add 1 for going to next second
        if (this.cutValue) {
          newTrackIndex =
            (totalSeconds - Math.floor(this.cutValue[0] / 5)) * 5 - 1;
        } else {
          newTrackIndex = totalSeconds * 5 + 1;
        }

        this.SET_CURRENT_TRACK_INDEX(newTrackIndex);
      }
    },

    setLegendData(newVal) {
      this.legendWithData = newVal;
    },

    handleSwitchTab(index) {
      this.tabs.map((i) => (i.current = false));
      this.tabs[index].current = true;
      this.currentTabIdx = index;
    },

    cutTrackingValue() {
      this.SET_CURRENT_TRACK_INDEX(0);
      const cutValueArray = [];
      if (this.fromToCutValue.length) {
        cutValueArray.push(this.fromToCutValue[0] || 0);
        cutValueArray.push(
          this.fromToCutValue[1] || this.currentMappedData.length - 1
        );
      }
      this.cutValue = this.fromToCutValue.length ? cutValueArray : null;
    },
    changeCutValue(val) {
      this.fromToCutValue = [
        this.currentMappedData[val[0]].id ?? 0,
        this.currentMappedData[val[1] - 1]?.id ?? 0,
      ];
    },

    handleKeyDown(event) {
      const { key, shiftKey } = event;

      const setCurrentTrackIndex = (newValue) => {
        const maxValue = this.reports.TRACKING.x.length - 1;
        this.SET_CURRENT_TRACK_INDEX(Math.min(maxValue, Math.max(0, newValue)));
      };

      switch (key) {
        case "ArrowLeft":
          setCurrentTrackIndex(
            shiftKey ? this.currentTrackIndex - 20 : this.currentTrackIndex - 1
          );
          break;
        case "ArrowRight":
          setCurrentTrackIndex(
            shiftKey ? this.currentTrackIndex + 20 : this.currentTrackIndex + 1
          );
          break;
        case " ":
          event.preventDefault();
          if (this.isTrackStarted && !this.isTrackPaused) {
            this.pauseTrack();
          } else if (!this.isTrackStarted && this.isTrackPaused) {
            this.playTrack();
          }
          break;
        case "0":
          this.SET_CURRENT_TRACK_INDEX(0);
          break;
        case "1":
          this.SET_TRACKING_SPEED(1);
          break;
        case "2":
          this.SET_TRACKING_SPEED(2);
          break;
        case "3":
          this.SET_TRACKING_SPEED(4);
          break;
        case "4":
          this.SET_TRACKING_SPEED(10);
          break;
        case "5":
          this.SET_TRACKING_SPEED(20);
          break;
        default:
          break;
      }
    },
  },
  beforeMount() {
    let selectedTrackingMode = localStorage.getItem("tracking-mode");
    if (!selectedTrackingMode) {
      selectedTrackingMode = trackingModeKeys.ML;
      localStorage.setItem("tracking-mode", selectedTrackingMode);
    }
    this.handleTrackingMode({ data: selectedTrackingMode });

    let selectedMulticharts = localStorage.getItem("selected-multicharts");
    if (selectedMulticharts) {
      selectedMulticharts = JSON.parse(selectedMulticharts);
    } else {
      const defaultTrackingMulticharts = [
        {
          title: this.$t("tracking.Speed"),
          scaleId: "y",
          checked: true,
          color: "#F1C40F",
        },
        {
          title: this.$t("tracking.Impulse"),
          scaleId: "y1",
          checked: false,
          color: "#FF6B00FF",
        },
        {
          title: this.$t("tracking.Rhythm"),
          scaleId: "y2",
          checked: false,
          color: "#FF69B4",
        },
        {
          title: this.$t("tracking.Stride length"),
          scaleId: "y3",
          checked: false,
          color: "#6495ED",
        },
        {
          title: this.$t("tracking.Acceleration"),
          scaleId: "y4",
          checked: false,
          color: "#8A2BE2",
        },
        {
          title: this.$t("tracking.Step time"),
          scaleId: "y5",
          checked: false,
          color: "#8FBC8F",
        },
        {
          title: this.$t("tracking.Rhythm deviation"),
          scaleId: "y6",
          checked: false,
          color: "#898121",
        },
        {
          title: this.$t("tracking.Heart rate"),
          scaleId: "y7",
          checked: false,
          color: "#E74C3C",
        },
      ];

      selectedMulticharts = defaultTrackingMulticharts;
      localStorage.setItem(
        "selected-multicharts",
        JSON.stringify(selectedMulticharts)
      );
    }
    this.multicharts = selectedMulticharts;
  },
  async mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
    if (this.jumpOptions?.length) {
      this.selectNewJump({ data: this.jumpOptions[0].label });
      this.SET_CURRENT_TRACK_INDEX(0);
    }
  },
  beforeUnmount() {
    this.closeAllFullscreenWindows();
    document.removeEventListener("keydown", this.handleKeyDown);
    this.SET_REPORTS_DATA(null);
    this.SET_HORSE_LAMENESS(null);
    this.SET_GPS_DATAFILES(null);
    this.SET_HR_DATAFILES(null);
    this.SET_CURRENT_TRACK_INDEX(0);
  },
};
</script>

<style lang="scss" scoped>
:deep(#trackingMode) {
  height: 40px;
  width: auto;
  font-size: 14px;
}
:deep(#jumps) {
  height: 40px;
  width: auto;
  font-size: 14px;
}
</style>

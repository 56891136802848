const mutations = {
  SET_HORSE_DONT_HAVE_TRAININGS(state, data) {
    state.isHorseDontHaveTrainings = data;
  },

  SET_SELECTED_TRAINING_DATE(state, data) {
    state.selectedTrainingDate = data;
  },

  SET_SELECTED_TRAINING_TIME_DATA(state, data) {
    state.selectedTrainingTimeData = data;
  },

  SET_CURRENT_DAY_TRAININGS(state, data) {
    state.selectedDayTrainings = data;
  },

  SET_CURRENT_DAY_TRAININGS_METADATA(state, data) {
    state.selectedDayTrainingsMetadata = data;
  },

  SET_CURRENT_TRAINING_METADATA(state, data) {
    state.currentTrainingMetadata = data;
  },

  SET_REPORTS_DATA(state, data) {
    state.reports = data;
  },

  UPDATE_SELECTED_CHARTS_LIST(state, data) {
    state.selectedChartsList = data;
  },

  SET_TRAININGS_DATES(state, data) {
    state.trainingDates = data;
  },

  SET_HORSE_LAMENESS(state, data) {
    state.horseLameness = data;
  },

  SET_COMPARE_TRAINING(state, data) {
    if (data.type === "compareTrainingFrom")
      state.compareTrainingFrom = data.data;
    else state.compareTrainingTo = data.data;
  },

  SET_REPORTS_COMPARE_TRAINING(state, data) {
    state[data.type] = data.data;
  },

  SET_HORSE_LAMENESS_SETTINGS(state, data) {
    state.horseLamenessSettings = data;
  },
};

export default mutations;

<template>
  <div
    class="w-full self-start flex flex-col items-center justify-start font-monrope h-full"
  >
    <LegendComponent :options="multicharts" />

    <!-- Speed control & jumps selector -->
    <div class="w-full flex flex-wrap items-center justify-between mb-4">
      <div class="flex items-center" v-show="isJumpExist">
        <span class="text-[#5A5A5F] text-[18px] font-semibold mr-4">
          {{ $t("tracking.Animation speed") }}
        </span>
        <SelectControl
          :style="{
            flexDirection: 'row',
            marginBottom: '0',
            width: 'auto',
          }"
          dataKey="animationSpeed"
          :data="animationSpeed"
          :options="animationSpeedOptions.map((i) => i.label)"
          @update="onSpeedChange"
        />
      </div>

      <div class="flex items-center">
        <span class="text-[#5A5A5F] text-[18px] font-semibold mr-4">
          Playback mode
        </span>
        <SelectControl
          :style="{
            flexDirection: 'row',
            marginBottom: '0',
            width: 'auto',
            marginRight: '1rem',
          }"
          dataKey="playbackMode"
          :data="playbackMode"
          :options="['Static', 'Dynamic']"
          @update="onPlaybackModeChange"
        />
      </div>

      <div class="flex items-center">
        <SelectControl
          :style="{
            flexDirection: 'row',
            marginBottom: '0',
            width: 'auto',
          }"
          dataKey="jumps"
          :data="selectedJump?.label || null"
          :options="jumpOptions.map((option) => option.label)"
          defaultOption="Select jump"
          @update="selectNewJump"
        />
      </div>
    </div>

    <!-- Time Slider with Start, Current, and End Times -->
    <div class="w-full my-2 flex items-center justify-between">
      <!-- Play Controls -->
      <div class="flex items-center mr-4" v-show="isJumpExist">
        <button v-if="!isPlaying" @click="playAnimation" class="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-114 h-14 mr-2 cursor-pointer text-[#9A8053]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
            />
          </svg>
        </button>
        <button v-else @click="stopAnimation" class="mr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-14 h-14 mr-2 cursor-pointer text-[#9A8053]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
        <button
          @click="resetAnimation"
          class="p-2 h-fit rounded-md border-2 border-[#9A8053] flex items-center font-semibold text-[#9A8053] mr-2"
        >
          Reset
        </button>
      </div>

      <div class="flex items-center w-full">
        <span>{{ formatTime(renderStart * 1000) }}</span>

        <div class="flex flex-col items-center w-full mx-2 mb-4">
          <span class="text-xs mb-1" v-show="isJumpExist">
            {{ formattedTimestamp }}
          </span>
          <input
            type="range"
            :min="0"
            :max="totalFrames"
            v-model="currentFrame"
            @input="onSliderInput"
            class="w-full"
            ref="sliderInput"
          />
        </div>

        <span>{{ formatTime(renderEnd * 1000) }}</span>
      </div>
    </div>

    <!-- 3D Container -->
    <div
      v-show="isJumpExist"
      id="3d-container"
      class="w-full relative flex-grow"
      ref="threeContainer"
    >
      <div
        v-if="loading"
        class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75"
      >
        <span>Loading...</span>
      </div>
    </div>

    <div v-show="!isJumpExist">The jump is in another time range.</div>
  </div>
</template>

<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { mapGetters, mapMutations } from "vuex";
import { animationSpeedOptions } from "@/modules/training/constants";
import SelectControl from "@/shared/components/ui/SelectControl.vue";
import LegendComponent from "@/modules/training/submodules/tracking/components/multiChart/LegendComponent.vue";
import {
  formatTimestampToSingleDecimal,
  getTrackIndexFromTimestamp,
} from "@/modules/training/submodules/tracking/components/multiChart/multiChartUtils";

let scene, camera, renderer, controls, mixer, clock, model, pathLine;
let animationFrameId = null;

export default {
  name: "TrainingSimulator",
  components: { LegendComponent, SelectControl },

  props: {
    selectedJump: Object,
    legendWithData: Array,
    cutValue: Array,
    jumpOptions: Array,
    multicharts: Array,
  },

  inject: ["selectNewJump"],

  data() {
    return {
      animationSpeedOptions,

      containerHeight: 0,
      animationDuration: 0,
      currentTime: 0,
      renderStart: 0,
      renderEnd: 0,
      currentFrame: 0,
      totalFrames: 0,
      frameRate: 30,
      pausedTime: 0,
      animationSpeed: "1x",
      isPlaying: false,
      loading: false,
      isTrackIndexUpdateBlocked: false,
      isJumpExist: true,
      isUpdatingJump: false,

      waypoints: [],
      previousHorsePos: null,
      initialCameraPosition: null,
      initialControlsTarget: null,
      initialCameraPositionDynamic: null,
      initialControlsTargetDynamic: null,
      cameraOffsetXZ: null,

      playbackMode: "Static",
    };
  },

  computed: {
    ...mapGetters([
      "reports",
      "currentTrackIndex",
      "isFrameUpdatedOutsideAnimation",
    ]),

    formattedTimestamp() {
      let totalMilliseconds = this.currentTrackIndex * 200;
      if (this.cutValue) {
        totalMilliseconds += this.cutValue[0] * 200;
      }
      const totalSeconds = Math.floor(totalMilliseconds / 1000);
      const milliseconds = Math.floor((totalMilliseconds % 1000) / 100);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}.${milliseconds}`;
    },
  },

  watch: {
    cutValue() {
      let startTrackIndex = getTrackIndexFromTimestamp(
        formatTimestampToSingleDecimal(this.selectedJump.render_start)
      );
      if (this.cutValue) {
        startTrackIndex -= this.cutValue[0];
      }

      this.isTrackIndexUpdateBlocked = false;
      this.currentFrame = 0;
      this.SET_CURRENT_TRACK_INDEX(startTrackIndex);
      this.onSliderInput();
    },
    async currentTrackIndex(newTrackIndex) {
      await this.$nextTick();
      if (
        this.isPlaying ||
        this.isUpdatingJump ||
        !this.isFrameUpdatedOutsideAnimation
      )
        return;

      if (!this.action || !this.totalFrames || newTrackIndex == null) return;

      let startTrackIndex = getTrackIndexFromTimestamp(
        formatTimestampToSingleDecimal(this.selectedJump.render_start)
      );
      let endTrackIndex = getTrackIndexFromTimestamp(
        formatTimestampToSingleDecimal(this.selectedJump.render_end)
      );

      if (this.cutValue) {
        startTrackIndex -= this.cutValue[0];
        endTrackIndex -= this.cutValue[0];
      }

      const framePos = Math.floor(
        (newTrackIndex - startTrackIndex) * (this.frameRate / 5)
      );
      this.isJumpExist =
        newTrackIndex >= startTrackIndex && newTrackIndex <= endTrackIndex;

      if (!this.isJumpExist || framePos > this.totalFrames) return;

      this.isTrackIndexUpdateBlocked = true;
      this.currentFrame = framePos;
      this.onSliderInput();
      this.SET_IS_FRAME_UPDATED_OUTSIDE_ANIMATION(false);
    },

    currentFrame(newVal) {
      // If animation ends
      if (newVal >= this.totalFrames - 1) {
        this.stopAnimation();

        const newJump = this.jumpOptions.find(
          (i) => i.id === this.selectedJump.id + 1
        );

        if (newJump) {
          const newJumpOption = {
            key: "jumps",
            data: newJump.label,
          };
          this.selectNewJump(newJumpOption);
        }
      }

      let totalMilliseconds =
        this.renderStart * 1000 +
        (newVal / this.totalFrames) *
          (this.renderEnd - this.renderStart) *
          1000;

      let newTrackIndex = Math.floor(totalMilliseconds / 200);

      if (this.cutValue) {
        newTrackIndex -= this.cutValue[0];
      }

      if (newTrackIndex !== this.currentTrackIndex) {
        this.SET_CURRENT_TRACK_INDEX(newTrackIndex);
      }
      this.isTrackIndexUpdateBlocked = false;
    },

    // update animation file
    async selectedJump(newVal) {
      if (this.isUpdatingJump) return;
      // Reset this flag
      this.isUpdatingJump = true;

      this.currentFrame = 0;
      this.pausedTime = 0;
      this.stopAnimation();
      await this.loadSelectedJumpData();

      const defaultSpeed = this.animationSpeedOptions.find(
        (opt) => opt.label === this.animationSpeed
      );
      if (defaultSpeed) {
        this.onSpeedChange({ data: defaultSpeed.label });
      }

      let startTrackIndex = getTrackIndexFromTimestamp(
        formatTimestampToSingleDecimal(newVal.render_start)
      );
      if (this.cutValue) {
        startTrackIndex -= this.cutValue[0];
      }

      if (pathLine) {
        scene.remove(pathLine);
        pathLine = null;
      }
      this.updateWaypoints();
      this.drawPath();

      if (this.playbackMode === "Dynamic") {
        this.orientHorseToPath();
        this.positionCameraSideView();

        this.initialCameraPositionDynamic = camera.position.clone();
        this.initialControlsTargetDynamic = controls.target.clone();
      }

      this.SET_CURRENT_TRACK_INDEX(startTrackIndex);
      this.isUpdatingJump = false;
    },
  },

  methods: {
    ...mapMutations([
      "SET_CURRENT_TRACK_INDEX",
      "SET_IS_FRAME_UPDATED_OUTSIDE_ANIMATION",
    ]),

    onPlaybackModeChange({ data }) {
      this.playbackMode = data;
      this.resetAnimation();
      if (this.playbackMode === "Static" && pathLine) {
        model.position.set(0, 0, 0);
        model.rotation.set(0, 0, 0);
        camera.far = 10000;
        camera.fov = 50;

        scene.remove(pathLine);
        pathLine = null;
        this.initialCameraPosition = camera.position.clone();
        this.initialControlsTarget = controls.target.clone();
      } else if (this.playbackMode === "Dynamic") {
        if (!pathLine) {
          this.drawPath();
          this.orientHorseToPath();
          this.positionCameraSideView();

          this.initialCameraPositionDynamic = camera.position.clone();
          this.initialControlsTargetDynamic = controls.target.clone();
        }
      }
    },

    onSpeedChange({ data }) {
      if (this.action) {
        const newSpeed = this.animationSpeedOptions.find(
          (option) => option.label === data
        );

        if (newSpeed) {
          this.animationSpeed = newSpeed.label;
          this.action.timeScale = Number(newSpeed.speed);
        }
      }
    },

    timeToSeconds(timeString) {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    },

    calculateFreeHeight() {
      const animationWindow = document.getElementById("animationWindow");
      const modalHeader = animationWindow?.querySelector(".modal-header");
      const modalContent = animationWindow?.querySelector(".modal-content");

      if (animationWindow && modalContent && this.$refs.threeContainer) {
        const windowRect = animationWindow.getBoundingClientRect();
        const headerHeight = modalHeader ? modalHeader.offsetHeight : 0;
        const paddingBottom = 230;

        const availableHeight =
          windowRect.height - headerHeight - paddingBottom;

        this.containerHeight = Math.max(availableHeight, 400);

        // Updating the renderer dimensions
        const container = this.$refs.threeContainer;
        renderer.setSize(container.offsetWidth, this.containerHeight);
        camera.aspect = container.offsetWidth / this.containerHeight;
        camera.updateProjectionMatrix();
      }
    },

    formatTime(milliseconds) {
      const totalSeconds = Math.floor(milliseconds / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      const millis = Math.floor((milliseconds % 1000) / 100); // One decimal place for milliseconds

      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}.${millis}`;
    },

    async initScene() {
      const container = this.$refs.threeContainer;
      scene = new THREE.Scene();

      // Camera setup
      camera = new THREE.PerspectiveCamera(
        50,
        container.offsetWidth / this.containerHeight || 1,
        0.1,
        10000
      );
      camera.position.set(350, 200, 400);
      camera.lookAt(new THREE.Vector3(0, 75, 0));

      // Renderer setup
      renderer = new THREE.WebGLRenderer({ antialias: true });
      this.calculateFreeHeight();
      container.appendChild(renderer.domElement);

      // Controls setup
      controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 75, 0);
      controls.minDistance = 200;
      controls.maxDistance = 2000;
      controls.minPolarAngle = THREE.MathUtils.degToRad(10);
      controls.maxPolarAngle = THREE.MathUtils.degToRad(90);
      controls.update();

      clock = new THREE.Clock();

      // Adding lights
      this.addLights();
      this.setupEnvironment();
      this.loadTexturesAndGeometry(); // Add ground texture and helper
    },

    addLights() {
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 1.5);
      hemiLight.position.set(0, 200, 0);
      scene.add(hemiLight);

      const dirLight = new THREE.DirectionalLight(0xffffff, 1);
      dirLight.position.set(75, 75, 75);
      dirLight.castShadow = true;
      dirLight.shadow.mapSize.width = 2048;
      dirLight.shadow.mapSize.height = 2048;
      dirLight.shadow.camera.near = 0.1;
      dirLight.shadow.camera.far = 500;
      scene.add(dirLight);
    },

    setupEnvironment() {
      const rgbeLoader = new RGBELoader();
      rgbeLoader.load("/models/sky_2k.hdr", (texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.background = texture;
        scene.environment = texture;
      });
    },

    loadTexturesAndGeometry() {
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load("/models/grass.jpg", (texture) => {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(100, 100);

        const planeGeometry = new THREE.PlaneGeometry(10000, 10000);
        const planeMaterial = new THREE.MeshLambertMaterial({ map: texture });

        const plane = new THREE.Mesh(planeGeometry, planeMaterial);
        plane.rotation.x = -Math.PI / 2;
        plane.position.y = -8;
        plane.receiveShadow = true;

        scene.add(plane);
      });
    },

    async loadSelectedJumpData() {
      this.loading = true;

      try {
        if (model) {
          scene.remove(model);
          model.traverse((child) => {
            if (child.isMesh) {
              child.geometry.dispose();
              child.material.dispose();
            }
          });
          model = null;
          if (mixer) mixer.stopAllAction();
        }

        const jumpUrl = this.selectedJump.render_path;

        if (jumpUrl) {
          const dracoLoader = new DRACOLoader();
          dracoLoader.setDecoderPath("jsm/libs/draco/");
          const loader = new GLTFLoader();
          loader.setDRACOLoader(dracoLoader);

          const gltf = await loader.loadAsync(jumpUrl);
          model = gltf.scene;
          model.scale.set(100, 100, 100);
          model.position.set(0, 0, 0);
          scene.add(model);

          mixer = new THREE.AnimationMixer(model);
          const animation = gltf.animations[0];
          if (animation) {
            this.action = mixer.clipAction(animation);
            this.animationDuration = animation.duration * 1000;
            this.action.paused = true;
          }

          this.renderStart = this.timeToSeconds(this.selectedJump.render_start);
          this.renderEnd = this.timeToSeconds(this.selectedJump.render_end);

          // Update totalFrames based on the animation duration
          this.totalFrames = Math.floor(
            (this.animationDuration / 1000) * this.frameRate
          );
        }
      } catch (error) {
        console.error("Failed to load jump data:", error);
      } finally {
        this.loading = false;
      }
    },

    positionCameraSideView() {
      if (!this.normalizedWaypoints || !this.normalizedWaypoints.length) return;

      // Collecting all the waypoints
      const points = this.normalizedWaypoints.map(
        (p) => new THREE.Vector3(p.x, p.y, p.z)
      );
      if (!points.length) return;

      // Find the bounding box and the sphere
      const box = new THREE.Box3().setFromPoints(points);
      const center = box.getCenter(new THREE.Vector3());

      const sphere = new THREE.Sphere();
      box.getBoundingSphere(sphere);

      const radius = sphere.radius;
      const multiplier = 3.0;

      const offsetY = radius / 3; // height
      const offsetZ = radius * multiplier;

      camera.position.set(center.x, center.y + offsetY, center.z + offsetZ);
      camera.lookAt(center);

      camera.far = radius * 10;
      camera.fov = 60;
      camera.updateProjectionMatrix();

      controls.target.copy(center);
      controls.update();
    },

    render() {
      if (renderer) renderer.render(scene, camera);
    },

    drawPath() {
      if (this.playbackMode === "Static") return;
      if (!this.normalizedWaypoints || this.normalizedWaypoints.length === 0)
        return null;

      const points = this.normalizedWaypoints.map(
        (p) => new THREE.Vector3(p.x, p.y + 8, p.z)
      );
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
      const line = new THREE.Line(geometry, material);

      scene.add(line);
      pathLine = line;
    },

    orientHorseToPath() {
      if (
        !model ||
        !this.normalizedWaypoints ||
        this.normalizedWaypoints.length < 2
      )
        return;

      // Set horse position to the first normalized point
      model.position.set(
        this.normalizedWaypoints[0].x,
        this.normalizedWaypoints[0].y,
        this.normalizedWaypoints[0].z
      );

      // Vector from the first point to the second point
      const first = new THREE.Vector3(
        this.normalizedWaypoints[0].x,
        this.normalizedWaypoints[0].y,
        this.normalizedWaypoints[0].z
      );
      const second = new THREE.Vector3(
        this.normalizedWaypoints[1].x,
        this.normalizedWaypoints[1].y,
        this.normalizedWaypoints[1].z
      );

      const direction = second.clone().sub(first).normalize();

      // Turn the horse in the direction of the way
      const angle = Math.atan2(direction.x, direction.z);
      model.rotation.y = angle;
      if (!this.initialCameraPosition || !this.initialControlsTarget) {
        this.initialCameraPosition = camera.position.clone();
        this.initialControlsTarget = controls.target.clone();
      }
    },

    updateHorsePositionByFraction(fraction) {
      if (!this.normalizedWaypoints || !model) return;

      const pathIndexFloat = fraction * (this.normalizedWaypoints.length - 1);
      let idx = Math.floor(pathIndexFloat);

      if (idx >= this.normalizedWaypoints.length - 1) {
        idx = this.normalizedWaypoints.length - 2;
      }

      const alpha = pathIndexFloat - idx;
      const p1 = this.normalizedWaypoints[idx];
      const p2 = this.normalizedWaypoints[idx + 1];

      // Linear interpolation
      const x = THREE.MathUtils.lerp(p1.x, p2.x, alpha);
      const y = THREE.MathUtils.lerp(p1.y, p2.y, alpha);
      const z = THREE.MathUtils.lerp(p1.z, p2.z, alpha);

      model.position.set(x, y, z);

      // Turn of the horse
      const dirX = p2.x - p1.x;
      const dirZ = p2.z - p1.z;
      const angle = Math.atan2(dirX, dirZ);
      model.rotation.y = angle;
    },

    animate() {
      animationFrameId = requestAnimationFrame(this.animate);

      if (mixer && this.action) {
        const delta = clock.getDelta();

        if (this.isPlaying) {
          mixer.update(delta);

          const fraction = THREE.MathUtils.clamp(
            this.action.time / this.action.getClip().duration,
            0,
            1
          );
          const newFrame = Math.floor(fraction * this.totalFrames);
          if (newFrame !== this.currentFrame) {
            this.currentFrame = newFrame;
          }

          if (this.playbackMode === "Dynamic") {
            this.updateHorsePositionByFraction(fraction);
          }
        }
      }

      this.render();
    },

    playAnimation() {
      if (!this.isPlaying && mixer && this.action) {
        this.action.time = this.pausedTime;
        this.action.paused = false;
        this.isPlaying = true;
        this.action.play();
        clock.start();
      }
    },

    stopAnimation() {
      if (this.isPlaying && mixer && this.action) {
        this.action.paused = true;
        this.isPlaying = false;
        this.pausedTime = this.action.time;
        this.currentFrame = Math.floor(
          (this.pausedTime / this.action.getClip().duration) * this.totalFrames
        );
        clock.stop();
      }
    },

    async resetAnimation() {
      if (mixer && this.action) {
        this.onSpeedChange({ data: "1x" });
        this.action.stop();
        this.action.time = 0;
        this.action.paused = true;
        this.currentFrame = 0;
        this.pausedTime = 0;
        this.isPlaying = false;
        camera.position.set(350, 200, 400);
        camera.lookAt(new THREE.Vector3(0, 75, 0));
        controls.target.set(0, 75, 0);
        controls.update();

        this.previousHorsePos = null;

        if (this.initialCameraPosition && this.playbackMode === "Static") {
          camera.position.copy(this.initialCameraPosition);
          controls.target.copy(this.initialControlsTarget);
          controls.update();
        } else if (
          this.initialCameraPositionDynamic &&
          this.playbackMode === "Dynamic"
        ) {
          this.orientHorseToPath();
          camera.position.copy(this.initialCameraPositionDynamic);
          controls.target.copy(this.initialControlsTargetDynamic);
          controls.update();
        }
      }

      clock.stop();
      clock.elapsedTime = 0;
      renderer.render(scene, camera);
    },

    onSliderInput() {
      if (mixer && this.action) {
        // Calculate the target time based on sliders frame position
        const targetTime =
          (this.currentFrame / this.totalFrames) *
          this.action.getClip().duration;

        // Set the action time and update paused time for correct sync
        this.action.time = targetTime;
        this.pausedTime = targetTime;
        this.action.paused = true;
        this.isPlaying = false;

        this.action.play();
        clock.start();

        mixer.update(0);
      }

      if (this.playbackMode === "Dynamic") {
        const fraction = this.currentFrame / this.totalFrames;
        this.updateHorsePositionByFraction(fraction);
      }

      renderer.render(scene, camera);
    },

    onWindowResize() {
      if (renderer && camera && this.$refs.threeContainer) {
        this.calculateFreeHeight();
        this.render();
      }
    },

    disposeScene() {
      if (model) {
        model.traverse((child) => {
          if (child.isMesh) {
            child.geometry.dispose();
            if (Array.isArray(child.material)) {
              child.material.forEach((mat) => mat.dispose());
            } else if (child.material) {
              child.material.dispose();
            }
          }
        });
        scene.remove(model);
        model = null;
      }

      if (renderer) {
        renderer.dispose();
        renderer.forceContextLoss();
        renderer.domElement = null;
        renderer = null;
      }

      if (controls) {
        controls.dispose();
        controls = null;
      }

      scene = null;
      camera = null;
      mixer = null;
      clock = null;
      pathLine = null;
    },

    handleKeyDown(event) {
      const { key, shiftKey } = event;
      if (!this.isJumpExist) return;

      switch (key) {
        case "ArrowLeft":
          this.isTrackIndexUpdateBlocked = true;
          this.currentFrame = shiftKey
            ? Number(this.currentFrame) - 5
            : Number(this.currentFrame) - 1;
          if (this.currentFrame < 0) {
            const prevJump = this.jumpOptions.find(
              (i) => i.id === this.selectedJump.id - 1
            );
            if (prevJump) {
              this.selectNewJump({ key: "jumps", data: prevJump.label });
              return;
            }
            this.currentFrame = 0;
          }
          this.onSliderInput();
          break;

        case "ArrowRight":
          if (this.currentFrame <= this.totalFrames) {
            this.isTrackIndexUpdateBlocked = true;
            this.currentFrame = shiftKey
              ? Number(this.currentFrame) + 5
              : Number(this.currentFrame) + 1;
            this.onSliderInput();
          }
          break;

        case " ":
          event.preventDefault();
          if (!this.isPlaying) {
            this.playAnimation();
          } else {
            this.stopAnimation();
          }
          break;

        default:
          break;
      }
    },

    updateWaypoints() {
      let startTrackIndex = getTrackIndexFromTimestamp(
        formatTimestampToSingleDecimal(this.selectedJump.render_start)
      );
      let endTrackIndex = getTrackIndexFromTimestamp(
        formatTimestampToSingleDecimal(this.selectedJump.render_end)
      );

      const sliceX = this.reports.TRACKING.x.slice(
        startTrackIndex,
        endTrackIndex
      );
      const sliceY = this.reports.TRACKING.y.slice(
        startTrackIndex,
        endTrackIndex
      );
      const sliceZ = this.reports.TRACKING.z.slice(
        startTrackIndex,
        endTrackIndex
      );
      const sliceTS = this.reports.TRACKING.ts.slice(
        startTrackIndex,
        endTrackIndex
      );

      this.waypoints = sliceTS.map((ts, idx) => {
        return {
          x: sliceX[idx],
          y: sliceY[idx],
          z: sliceZ[idx],
          ts,
        };
      });

      const firstWp = this.waypoints[0];
      const offsetX = firstWp.x;
      const offsetY = firstWp.y;
      const offsetZ = firstWp.z;

      const scaleFactor = 100;

      this.normalizedWaypoints = this.waypoints.map((wp) => {
        return {
          x: (wp.x - offsetX) * scaleFactor,
          y: (wp.z - offsetZ) * scaleFactor, // Z from the Data is Y in Three.js
          z: (wp.y - offsetY) * scaleFactor, // Y from the Data is Z in Three.js
        };
      });
    },
  },

  async mounted() {
    this.updateWaypoints();

    this.initScene().then(async () => {
      await this.loadSelectedJumpData();
      this.onWindowResize();
      this.animate();

      this.drawPath();

      let startTrackIndex = getTrackIndexFromTimestamp(
        formatTimestampToSingleDecimal(this.selectedJump.render_start)
      );
      if (this.cutValue) {
        startTrackIndex -= this.cutValue[0];
      }
      this.SET_CURRENT_TRACK_INDEX(startTrackIndex);

      window.addEventListener("resize", this.onWindowResize);
      document.addEventListener("keydown", this.handleKeyDown);
    });
  },

  beforeUnmount() {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
      animationFrameId = null;
    }
    this.stopAnimation();
    this.disposeScene();
    window.removeEventListener("resize", this.onWindowResize);
    document.removeEventListener("keydown", this.handleKeyDown);
  },
};
</script>

<style scoped>
:deep(#animationSpeed) {
  height: 40px;
  font-size: 14px;
}
:deep(#playbackMode) {
  height: 40px;
  font-size: 14px;
}
#3d-container {
  width: 100%;
  height: 100%;
  min-height: 70vh;
  max-height: 100vh;
}
input[type="range"] {
  outline: none;
  border: none;
  box-shadow: none;
}
</style>

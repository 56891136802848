<template>
  <div class="flex flex-col mb-8 text-[#5A5A5F] w-full">
    <label :for="dataKey" class="mb-2 text-sm">{{ label }}</label>
    <select
      :id="dataKey"
      :name="dataKey"
      class="h-[56px] w-full rounded border border-[#B6B6BB] appearance-none focus:ring-0 focus:ring-offset-0 placeholder:text-[#D9D9DF] cursor-pointer text-md font-semibold"
      v-model="fieldData"
      @change="handleChange"
    >
      <!-- Default Disabled Option -->
      <option v-if="defaultOption" :value="null" disabled>
        {{ defaultOption }}
      </option>

      <!-- List of Options -->
      <option
        v-for="option of options"
        :key="option.value || option"
        :value="option.value || option"
        class="text-[#5A5A5F] font-semibold p-4"
      >
        {{ option.label || option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectControl",
  props: {
    label: String,
    dataKey: String,
    data: String,
    isDisabled: Boolean,
    options: Array,
    defaultOption: String,
  },
  data() {
    return {
      fieldData: this.data || null,
    };
  },
  watch: {
    data(newValue) {
      this.fieldData = newValue;
    },
  },
  mounted() {
    this.fieldData = this.data ? this.data : null;
    if (!this.data) {
      this.$emit("update", { key: this.dataKey, data: null });
    }
  },
  methods: {
    handleChange() {
      this.$emit("update", { key: this.dataKey, data: this.fieldData });
    },
  },
};
</script>

const state = {
  zoom: 17,
  heading: 90,
  center: {
    lat: 46.25812376,
    lng: 15.21977425,
  },
};

const getters = {
  googleMapsZoom: (state) => state.zoom,
  googleMapsHeading: (state) => state.heading,
  googleMapsCenter: (state) => state.center,
};

const mutations = {
  SET_GOOGLE_MAPS_ZOOM(state, data) {
    state.zoom = data;
  },
  SET_GOOGLE_MAPS_HEADING(state, data) {
    state.heading = data;
  },
  SET_GOOGLE_MAPS_CENTER(state, data) {
    state.center = data;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div
    class="bg-white w-[350px] max-h-[850px] drop-shadow-md rounded-lg p-4 flex justify-between flex-wrap relative"
  >
    <div class="absolute top-2 left-0"></div>
    <p class="w-full text-[#5A5A5F] text-[21px] font-semibold text-center my-4">
      {{ $t("Health analysis") }}
    </p>
    <div class="w-full flex justify-center items-center">
      <div class="h-[400px] md:h-[500px] flex flex-col justify-between">
        <!--    FL paw      -->
        <div class="flex justify-center">
          <PawSignatureComponent
            :overflowIndex="3"
            :type="$t('horse.W')"
            :level="setLamenessData(lamenessData?.walk?.FL)"
            :thresholds="walkThresholds"
          />
          <PawSignatureComponent
            :overflowIndex="2"
            :type="$t('horse.T')"
            :level="setLamenessData(lamenessData?.trot?.FL)"
            :thresholds="trotThresholds"
          />
          <PawSignatureComponent
            :overflowIndex="1"
            :type="$t('horse.G')"
            :level="setLamenessData(lamenessData?.gallop?.FL)"
            :thresholds="gallopThresholds"
          />
        </div>
        <!--    bl paw    -->
        <div class="flex justify-center mr-4">
          <PawSignatureComponent
            :overflowIndex="3"
            :type="$t('horse.W')"
            :level="setLamenessData(lamenessData?.walk?.BL)"
            :thresholds="walkThresholds"
          />
          <PawSignatureComponent
            :overflowIndex="2"
            :type="$t('horse.T')"
            :level="setLamenessData(lamenessData?.trot?.BL)"
            :thresholds="trotThresholds"
          />
          <PawSignatureComponent
            :overflowIndex="1"
            :type="$t('horse.G')"
            :level="setLamenessData(lamenessData?.gallop?.BL)"
            :thresholds="gallopThresholds"
          />
        </div>
      </div>
      <div class="h-auto max-w-[35%] lg-w-auto relative">
        <img
          :src="require('@/assets/images/horseBody.png')"
          class="max-h-[760px] min-w-[30px] md:h-auto"
          alt="horse"
        />
        <div
          class="absolute left-[18%] top-[38%] w-[30px] h-[30px] flex justify-center items-center rounded-full bg-white border text-[#5A5A5F] text-[14px] font-semibold"
        >
          {{ $t("horse.FL") }}
        </div>
        <div
          class="absolute left-[8%] bottom-[12%] w-[30px] h-[30px] flex justify-center items-center rounded-full bg-white border text-[#5A5A5F] text-[14px] font-semibold"
        >
          {{ $t("horse.HL") }}
        </div>

        <div
          class="absolute right-[19%] top-[38%] w-[30px] h-[30px] flex justify-center items-center rounded-full bg-white border text-[#5A5A5F] text-[14px] font-semibold"
        >
          {{ $t("horse.FR") }}
        </div>
        <div
          class="absolute right-[8%] bottom-[12%] w-[30px] h-[30px] flex justify-center items-center rounded-full bg-white border text-[#5A5A5F] text-[14px] font-semibold"
        >
          {{ $t("horse.HR") }}
        </div>
      </div>
      <div class="h-[400px] md:h-[500px] flex flex-col justify-between">
        <!--    FR paw    -->
        <div class="flex justify-center">
          <PawSignatureComponent
            :type="$t('horse.W')"
            :overflowIndex="3"
            :level="setLamenessData(lamenessData?.walk?.FR)"
            :thresholds="walkThresholds"
            front
          />
          <PawSignatureComponent
            :overflowIndex="2"
            :type="$t('horse.T')"
            :level="setLamenessData(lamenessData?.trot?.FR)"
            :thresholds="trotThresholds"
          />
          <PawSignatureComponent
            :overflowIndex="1"
            :type="$t('horse.G')"
            :level="setLamenessData(lamenessData?.gallop?.FR)"
            :thresholds="gallopThresholds"
          />
        </div>
        <!--   BR paw     -->
        <div class="flex justify-center ml-4">
          <PawSignatureComponent
            :overflowIndex="3"
            :type="$t('horse.W')"
            :level="setLamenessData(lamenessData?.walk?.BR)"
            :thresholds="walkThresholds"
          />
          <PawSignatureComponent
            :overflowIndex="2"
            :type="$t('horse.T')"
            :level="setLamenessData(lamenessData?.trot?.BR)"
            :thresholds="trotThresholds"
          />
          <PawSignatureComponent
            :overflowIndex="1"
            :type="$t('horse.G')"
            :level="setLamenessData(lamenessData?.gallop?.BR)"
            :thresholds="gallopThresholds"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PawSignatureComponent from "@/modules/training/components/PawSignatureComponent.vue";

export default {
  name: "HorseHealthComponent",
  components: {
    PawSignatureComponent,
  },
  props: {
    lamenessData: Object,
    reports: Object,
  },
  computed: {
    ...mapGetters(["selectedChartsList", "horseLamenessSettings"]),
    walkThresholds() {
      const thresholds = {
        info: 0,
        warning: 0,
        danger: 0,
      };

      if (this.horseLamenessSettings) {
        thresholds.info = Number(
          this.horseLamenessSettings["lameness.threshold_info.walk"].value
        );
        thresholds.warning = Number(
          this.horseLamenessSettings["lameness.threshold_warning.walk"].value
        );
        thresholds.danger = Number(
          this.horseLamenessSettings["lameness.threshold_danger.walk"].value
        );
      }

      return thresholds;
    },
    trotThresholds() {
      const thresholds = {
        info: 0,
        warning: 0,
        danger: 0,
      };

      if (this.horseLamenessSettings) {
        thresholds.info = Number(
          this.horseLamenessSettings["lameness.threshold_info.trot"].value
        );
        thresholds.warning = Number(
          this.horseLamenessSettings["lameness.threshold_warning.trot"].value
        );
        thresholds.danger = Number(
          this.horseLamenessSettings["lameness.threshold_danger.trot"].value
        );
      }

      return thresholds;
    },
    gallopThresholds() {
      const thresholds = {
        info: 0,
        warning: 0,
        danger: 0,
      };

      if (this.horseLamenessSettings) {
        thresholds.info = Number(
          this.horseLamenessSettings["lameness.threshold_info.gallop"].value
        );
        thresholds.warning = Number(
          this.horseLamenessSettings["lameness.threshold_warning.gallop"].value
        );
        thresholds.danger = Number(
          this.horseLamenessSettings["lameness.threshold_danger.gallop"].value
        );
      }

      return thresholds;
    },
  },
  methods: {
    setLamenessData(data) {
      return data ? data : 0;
    },
  },
};
</script>

<template>
  <div
    class="bg-[#9a80530d] mx-3 mb-2 p-4 flex flex-col items-center justify-center drop-shadow-md rounded-lg"
  >
    <div
      v-if="!loading"
      class="mx-auto flex items-center font-poppins text-[#5A5A5F] text-[18px] mb-2"
    >
      {{ $t("trainings-calendar.Select training date") }}
    </div>
    <VCalendar
      v-if="!loading"
      class="mx-2"
      color="brown"
      :locale="calendarLocale"
      transparent
      :initial-page="initialCalendarPage"
      :attributes="attributes"
      @dayclick="handleSetTrainingDay"
    />
    <div
      v-if="selectedDayTrainings && selectedDayTrainings.length"
      class="mx-auto flex items-center font-poppins text-[#5A5A5F] text-[18px] mb-2 mt-4"
    >
      {{ $t("trainings-calendar.Select training") }}
    </div>
    <select
      v-if="selectedDayTrainings && selectedDayTrainings.length"
      id="training"
      name="horses"
      class="h-[26px] w-full rounded border border-[#B6B6BB] appearance-none focus:ring-0 focus:ring-offset-0 accent-pink-500 placeholder:text-[#B6B6BB] text-sm px-1 py-0"
      v-model="training"
      @change="changeTrainingData"
    >
      <option
        class=""
        v-for="option of selectedDayTrainings"
        :key="option"
        :value="option"
      >
        {{ setFormattedToTimezoneTime(option.startTime) }} -
        {{ setFormattedToTimezoneTime(option.endTime) }}
      </option>
    </select>
  </div>
  <ModalComponent
    small
    :is-open="isModalOpen"
    @handleCancel="handleModalCancel"
    @handleAccept="handleModalAccept"
  >
    <template v-slot:title>
      <span class="px-2">{{
        $t("trainings-calendar.Confirm select tracking day")
      }}</span>
    </template>
    <template v-slot:content>
      <div class="text-[#5A5A5F] font-semibold px-2">
        {{
          $t(
            "trainings-calendar.Are you sure that you want to select this day"
          )
        }}?
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalComponent from "@/shared/components/ModalComponent.vue";

export default {
  name: "TrainingsCalendarComponent",
  components: { ModalComponent },
  props: {
    horse: Object,
    type: String,
  },
  emits: ["changeTraining"],
  data() {
    return {
      isModalOpen: false,
      dayTrainingsList: [],
      trainingDate: null,
      training: null,
    };
  },
  computed: {
    ...mapGetters([
      "trainingDates",
      "selectedDayTrainings",
      "selectedTrainingDate",
      "selectedTrainingTimeData",
      "isHorseDontHaveTrainings",
      "selectedDayTrainingsMetadata",
      "currentTrainingMetadata",
      "loading",
    ]),
    calendarLocale() {
      return this.$i18n.locale.toLowerCase();
    },
    attributes() {
      const currentDate = this.getDateFormatted();
      const trainingDatesArray = this.trainingDates.map((i) => {
        return {
          highlight: {
            style: {
              border: i === currentDate ? "2px solid #ff8080" : "none",
              background:
                i === this.selectedDayTrainingsMetadata.date
                  ? "#ff8080"
                  : "#9a8053",
            },
          },
          dates: [i],
        };
      });

      return [
        ...trainingDatesArray,
        {
          highlight: {
            style: {
              border: "2px solid #ff8080",
              background: "transparent !important",
            },
            contentStyle: {
              color: this.trainingDates.includes(currentDate)
                ? "white !important"
                : "black !important",
            },
          },
          dates: [currentDate],
        },
      ];
    },
    initialCalendarPage() {
      if (this.currentTrainingMetadata) {
        const date = new Date(this.currentTrainingMetadata.startTime);
        return {
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        };
      }

      const date = new Date();
      return {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
    },
  },
  methods: {
    ...mapMutations([
      "SET_SELECTED_TRAINING_TIME_DATA",
      "SET_SELECTED_TRAINING_DATE",
      "SET_LOADING",
      "SET_HORSE_DONT_HAVE_TRAININGS",
    ]),
    ...mapActions([
      "getTrainingsDates",
      "getSelectedDayTrainingsData",
      "getCurrentTrainingData",
      "closeAllFullscreenWindows",
    ]),
    async getSelectedDayTrainings(date) {
      const data = {
        id: this.$route.params.id,
        fromDate: date,
        toDate: date,
        type: this.type,
      };
      await this.getSelectedDayTrainingsData(data);
    },

    async setSelectedDayTrainings() {
      await this.getCurrentTrainingData(+this.$route.params.trainingId);
      const date = this.getDateFormatted(
        new Date(this.currentTrainingMetadata.startTime)
      );

      await this.getSelectedDayTrainings(date);
      if (this.selectedDayTrainings) {
        this.training =
          this.selectedDayTrainings.find(
            (el) => el.trainingId === +this.$route.params.trainingId
          ) || this.selectedDayTrainings[0];
        this.changeTrainingData();
      }
    },

    setFormattedToTimezoneTime(time) {
      const time_parts = time.split(":");
      const millisecond = time_parts[0] * (60000 * 60) + time_parts[1] * 60000;
      const userTz = new Date().getTimezoneOffset() * 60000;
      const milliseconds = millisecond + Math.abs(userTz);

      let minutes = Math.floor(milliseconds / 3600000);
      let seconds = ((milliseconds % 3600000) / 60000).toFixed(0);

      return (
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds
      );
    },
    async handleSetTrainingDay(val) {
      if (this.trainingDates.includes(val.id)) {
        this.trainingDate = val.id;
        this.isModalOpen = true;
        this.closeAllFullscreenWindows();
      }
    },
    async handleModalAccept() {
      await this.getSelectedDayTrainings(this.trainingDate);

      this.training = this.selectedDayTrainings[0];
      if (this.training) this.changeTrainingData();
      this.SET_SELECTED_TRAINING_DATE(this.trainingDate);

      this.isModalOpen = false;
    },
    handleModalCancel() {
      this.isModalOpen = false;
    },
    getDateFormatted(date = new Date()) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    changeTrainingData() {
      if (this.training?.results) {
        this.$router.push({
          params: {
            trainingId: this.training.trainingId,
          },
        });

        const data = {
          results: this.training.results,
          id: this.training.trainingId,
          type: this.type,
          date: this.trainingDate,
          time: this.training,
          isLoadForCalendar: true,
        };
        this.SET_SELECTED_TRAINING_TIME_DATA(this.training);

        this.$emit("changeTraining", data);
      }
    },
  },
  async mounted() {
    this.SET_LOADING(true);
    this.SET_HORSE_DONT_HAVE_TRAININGS(false);
    await this.getTrainingsDates(this.$route.params.id);
    await this.setSelectedDayTrainings();
    this.SET_HORSE_DONT_HAVE_TRAININGS(!this.trainingDates.length);
    if (this.isHorseDontHaveTrainings) {
      this.SET_LOADING(false);
    }
  },
};
</script>
<style>
.vc-brown {
  --vc-accent-50: #f0f9ff;
  --vc-accent-100: #e0f2fe;
  --vc-accent-200: #decbaf;
  --vc-accent-300: #decbaf;
  --vc-accent-400: #decbaf;
  --vc-accent-500: #9a8053;
  --vc-accent-600: #9a8053;
  --vc-accent-700: #9a8053;
  --vc-accent-800: #9a8053;
  --vc-accent-900: #9a8053;
}
</style>

<template>
  <div
    id="center-of-gravity-chart"
    class="mx-auto max-w-[300px] md:max-w-[400px] lg:max-w-[550px]"
  ></div>
</template>
<script>
import Plotly from "plotly.js-dist";
import { mapGetters } from "vuex";
export default {
  name: "CenterOfGravityChart",
  props: {
    tracking: Array,
    lengthPreviousValues: Number,
    maxRadius: Number,
  },
  watch: {
    currentTrackIndex() {
      Plotly.react("center-of-gravity-chart", this.data, this.layout, {
        displayModeBar: false,
      });
    },
  },
  computed: {
    ...mapGetters(["currentTrackIndex"]),
    previousValues() {
      const val =
        this.currentTrackIndex > this.lengthPreviousValues
          ? this.lengthPreviousValues
          : this.currentTrackIndex;
      const history = this.tracking.slice(
        this.currentTrackIndex - val,
        this.currentTrackIndex
      );

      let type = [];
      let res = [];

      history.map((i, index) => {
        if (history[index + 1] && i.color === history[index + 1].color) {
          type.push(i);
        } else if (
          !history[index + 1] ||
          i.color !== history[index + 1].color
        ) {
          type.push(i);
          res.push({
            type: "scatterpolar",
            mode: "line",
            hoverinfo: "none",
            r: type.map((i) => i.radius),
            theta: type.map((i) => i.phi),
            marker: {
              color: type[0].color,
              symbol: "line",
              size: 0.5,
              label: false,
            },
            subplot: "polar",
          });
          type = [];
        }
      });
      return res;
    },
    radius() {
      const maxValue = this.tracking.length - 1;
      return [
        0,
        this.tracking[
          this.currentTrackIndex > maxValue ? maxValue : this.currentTrackIndex
        ]?.radius,
      ];
    },
    theta() {
      const maxValue = this.tracking.length - 1;
      return [
        0,
        this.tracking[
          this.currentTrackIndex > maxValue ? maxValue : this.currentTrackIndex
        ]?.phi,
      ];
    },
    color() {
      const maxValue = this.tracking.length - 1;
      return this.tracking[
        this.currentTrackIndex > maxValue ? maxValue : this.currentTrackIndex
      ]?.color;
    },
    data() {
      return [
        ...this.previousValues,
        {
          type: "scatterpolar",
          mode: "line",
          r: this.radius,
          theta: this.theta,
          hoverinfo: "none",
          line: {
            color: "#A3A3A3",
            width: 4,
            label: false,
            tooltip: false,
          },
          marker: {
            color: this.color,
            symbol: "dot",
            size: 8,
            label: false,
            tooltip: false,
          },
          subplot: "polar",
        },
      ];
    },
    layout() {
      return {
        autosize: true,
        showlegend: false,
        polar: {
          radialaxis: {
            range: [0, this.maxRadius],
            rotation: 90,
            direction: "counterclockwise",
          },
        },
      };
    },
  },
  mounted() {
    Plotly.newPlot("center-of-gravity-chart", this.data, this.layout, {
      displayModeBar: false,
    });
  },
};
</script>

<template>
  <div
    class="w-full max-w-[95%] mb-4 p-4 flex justify-between flex-wrap bg-white shadow rounded-lg overflow-auto"
  >
    <p class="w-full text-[#5A5A5F] text-[21px] font-semibold text-center mb-4">
      {{ title }}
    </p>
    <div class="w-full flex justify-center">
      <Line :data="filteredData" :options="options" />
    </div>
    <div class="w-full flex flex-wrap justify-between mt-4">
      <div class="max-w-[450px] flex justify-between items-center">
        <div
          v-for="(item, index) of legs"
          :key="index"
          class="flex items-center ml-4"
        >
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            :id="item.title + title"
            v-model="item.checked"
          />
          <span
            class="w-[10px] h-[10px] mx-1 rounded-full"
            :class="getClass(item.backgroundColor)"
          ></span>
          <label
            :for="item.title + title"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer"
          >
            <span>{{ $t("horse." + item.title) }}</span>
          </label>
        </div>
      </div>
      <div class="max-w-[450px] flex justify-between items-center">
        <div
          v-for="(item, index) of movements"
          :key="index"
          class="flex items-center ml-4"
        >
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            :id="item.title + title"
            v-model="item.checked"
          />
          <!--                <span class="w-[10px] h-[10px] mx-1 rounded-full" :class="getClass(item.backgroundColor)"></span>-->
          <label
            :for="item.title + title"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
          >
            <span>{{ $t("tracking." + item.title) }}</span>
          </label>
        </div>
      </div>
    </div>

    <table
      class="w-full mt-8 mb-4 border-t border-l border-r divide-y divide-gray-300 table-auto overflow-scroll"
    >
      <thead>
        <tr>
          <th
            colspan="13"
            class="text-center text-[#5A5A5F] text-[21px] font-semibold p-4 border-b"
          >
            {{ $t("tracking.Signatures") }}
          </th>
        </tr>
        <tr>
          <th rowspan="2" class="p-2 border-b border-r">
            {{ $t("tracking.Date") }}
          </th>
          <th colspan="4" class="p-2 border-b bg-[#1AB0B0]">
            {{ $t("tracking.Walk") }}
          </th>
          <th colspan="4" class="p-2 border-b bg-[#FFCD4B]">
            {{ $t("tracking.Trot") }}
          </th>
          <th colspan="4" class="p-2 border-b bg-[#F85C7F]">
            {{ $t("tracking.Gallop") }}
          </th>
        </tr>
        <tr>
          <th class="p-2 border-b border-l">{{ $t("horse.FL") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.FR") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.HL") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.HR") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.FL") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.FR") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.HL") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.HR") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.FL") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.FR") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.HL") }}</th>
          <th class="p-2 border-b border-l">{{ $t("horse.HR") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(signature, index) of chartsData"
          :key="index"
          class="text-center border-b"
        >
          <td class="p-2 font-semibold text-sm">
            {{ formattedDate(signature.trainingDate) }},
            {{ setFormattedToTimezoneTime(signature.trainingDate) }}
          </td>
          <!--          walk-->
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.walk?.FL, 'walk')"
          >
            {{ signature.result.walk?.FL ? signature.result.walk.FL : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.walk?.FL
                  ? (100 * +signature.walksTypeResult.walk.FL).toFixed(3) + "%"
                  : ""
              }}
            </small>
          </td>
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.walk?.FR, 'walk')"
          >
            {{ signature.result.walk?.FR ? signature.result.walk.FR : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.walk?.FR
                  ? (100 * +signature.walksTypeResult.walk.FR).toFixed(3) + "%"
                  : ""
              }}
            </small>
          </td>
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.walk?.BL, 'walk')"
          >
            {{ signature.result.walk?.BL ? signature.result.walk.BL : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.walk?.BL
                  ? (100 * +signature.walksTypeResult.walk.BL).toFixed(3) + "%"
                  : ""
              }}
            </small>
          </td>
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.walk?.BR, 'walk')"
          >
            {{ signature.result.walk?.BR ? signature.result.walk.BR : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.walk?.BR
                  ? (100 * +signature.walksTypeResult.walk.BR).toFixed(3) + "%"
                  : ""
              }}
            </small>
          </td>
          <!--          trot-->
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.trot?.FL, 'trot')"
          >
            {{ signature.result.trot?.FL ? signature.result.trot.FL : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.trot?.FL
                  ? (100 * +signature.walksTypeResult.trot.FL).toFixed(3) + "%"
                  : ""
              }}
            </small>
          </td>
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.trot?.FR, 'trot')"
          >
            {{ signature.result.trot?.FR ? signature.result.trot.FR : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.trot?.FR
                  ? (100 * +signature.walksTypeResult.trot.FR).toFixed(3) + "%"
                  : ""
              }}
            </small>
          </td>
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.trot?.BL, 'trot')"
          >
            {{ signature.result.trot?.BL ? signature.result.trot.BL : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.trot?.BL
                  ? (100 * +signature.walksTypeResult.trot.BL).toFixed(3) + "%"
                  : ""
              }}
            </small>
          </td>
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.trot?.BR, 'trot')"
          >
            {{ signature.result.trot?.BR ? signature.result.trot.BR : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.trot?.BR
                  ? (100 * +signature.walksTypeResult.trot.BR).toFixed(3) + "%"
                  : ""
              }}
            </small>
          </td>
          <!--          gallop-->
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.gallop?.FL, 'gallop')"
          >
            {{ signature.result.gallop?.FL ? signature.result.gallop.FL : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.gallop?.FL
                  ? (100 * +signature.walksTypeResult.gallop.FL).toFixed(3) +
                    "%"
                  : ""
              }}
            </small>
          </td>
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.gallop?.FR, 'gallop')"
          >
            {{ signature.result.gallop?.FR ? signature.result.gallop.FR : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.gallop?.FR
                  ? (100 * +signature.walksTypeResult.gallop.FR).toFixed(3) +
                    "%"
                  : ""
              }}
            </small>
          </td>
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.gallop?.BL, 'gallop')"
          >
            {{ signature.result.gallop?.BL ? signature.result.gallop.BL : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.gallop?.BL
                  ? (100 * +signature.walksTypeResult.gallop.BL).toFixed(3) +
                    "%"
                  : ""
              }}
            </small>
          </td>
          <td
            class="p-2 border-l text-sm"
            :class="setClass(signature.walksTypeResult.gallop?.BR, 'gallop')"
          >
            {{ signature.result.gallop?.BR ? signature.result.gallop.BR : "" }}
            <br />
            <small class="font-bold text-xs">
              {{
                signature.walksTypeResult.gallop?.BR
                  ? (100 * +signature.walksTypeResult.gallop.BR).toFixed(3) +
                    "%"
                  : ""
              }}
            </small>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
import { Line } from "vue-chartjs";
import "chartjs-adapter-date-fns";
import { enUS, ru } from "date-fns/locale";
import { format } from "date-fns";
import { getClass } from "@/modules/training/helpers";
import { mapGetters } from "vuex";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
);

export default {
  name: "SignaturesComponent",
  components: {
    Line,
  },
  props: {
    title: String,
    date: String,
    description: String,
    chartsData: Array,
  },
  data() {
    return {
      trainingStartTS: Date.now(),
      options: {
        animation: false,
        spanGaps: true,
        decimation: true,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          crosshair: false,
          datalabels: false,
          colors: {
            forceOverride: true,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              title: (tooltipItem) => {
                const date = new Date(tooltipItem[0].raw.x);
                const locale = this.$i18n.locale === "Ru" ? ru : enUS;
                return format(date, "dd MMM yy", { locale: locale });
              },
              label: (tooltipItems) => {
                return `${this.$t("tracking.Leg")}: ${this.$t(
                  "horse." + tooltipItems.dataset.leg
                )}, ${this.$t("tracking.Type")}: ${this.$t(
                  "tracking." + tooltipItems.dataset.title
                )}, ${tooltipItems.parsed.y}`;
              },
            },
          },
        },
        scales: {
          x: {
            type: "time",
            adapters: {
              date: {
                locale: enUS,
                displayFormats: {
                  quarter: "minute",
                },
              },
            },
            min: this.trainingStartTS,
            time: {
              unit: "day",
              displayFormats: {
                quarter: "mm",
              },
            },
            barThickness: "1",
            maxBarThickness: 2,
          },
        },
      },
      movements: [
        { title: "Walk", checked: true },
        { title: "Trot", checked: true },
        { title: "Gallop", checked: true },
      ],
      legs: [
        { title: "FL", backgroundColor: "#6A9A53", checked: true },
        { title: "FR", backgroundColor: "#E0712F", checked: true },
        { title: "HL", backgroundColor: "#587DC0", checked: true },
        { title: "HR", backgroundColor: "#FFCD4B", checked: true },
      ],
      data: [],
    };
  },
  mounted() {
    this.setDataProperties();
  },
  computed: {
    ...mapGetters(["horseLamenessSettings"]),
    filteredData() {
      const filteredMovements = this.movements
        .filter((i) => i.checked)
        .map((i) => i.title);
      const filteredLegs = this.legs
        .filter((i) => i.checked)
        .map((i) => i.title);
      const t = this.data.filter(
        (i) =>
          filteredMovements.includes(i.title) && filteredLegs.includes(i.leg)
      );
      return {
        datasets: t,
      };
    },
  },
  methods: {
    getClass,
    formattedDate(date) {
      const locale = this.$i18n.locale === "Ru" ? ru : enUS;
      return format(new Date(date), "dd MMM yyyy", { locale: locale });
    },
    setFormattedToTimezoneTime(val) {
      const date = new Date(val);
      const time = `${date.getHours()}:${date.getMinutes()}`;

      const time_parts = time.split(":");
      const millisecond = time_parts[0] * (60000 * 60) + time_parts[1] * 60000;
      const userTz = new Date().getTimezoneOffset() * 60000;
      const milliseconds = millisecond + Math.abs(userTz);

      let minutes = Math.floor(milliseconds / 3600000);
      let seconds = ((milliseconds % 3600000) / 60000).toFixed(0);

      return (
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds
      );
    },
    setChartData(data, type, leg) {
      const res = [];
      data.map((i) => {
        res.push({
          x: i.trainingDate,
          y: i.result?.[type]?.[leg],
        });
      });
      return res;
    },
    setDataProperties() {
      const FL = [
        {
          title: "Walk",
          leg: "FL",
          checked: true,
          borderColor: "#6A9A53",
          backgroundColor: "#6A9A53",
          data: this.setChartData(this.chartsData, "walk", "FL"),
        },
        {
          title: "Trot",
          leg: "FL",
          checked: true,
          borderColor: "#6A9A53",
          backgroundColor: "#6A9A53",
          data: this.setChartData(this.chartsData, "trot", "FL"),
        },
        {
          title: "Gallop",
          leg: "FL",
          checked: true,
          borderColor: "#6A9A53",
          backgroundColor: "#6A9A53",
          data: this.setChartData(this.chartsData, "gallop", "FL"),
        },
      ];
      const FR = [
        {
          title: "Walk",
          leg: "FR",
          checked: true,
          borderColor: "#E0712F",
          backgroundColor: "#E0712F",
          data: this.setChartData(this.chartsData, "walk", "FR"),
        },
        {
          title: "Trot",
          leg: "FR",
          checked: true,
          borderColor: "#E0712F",
          backgroundColor: "#E0712F",
          data: this.setChartData(this.chartsData, "trot", "FR"),
        },
        {
          title: "Gallop",
          leg: "FR",
          checked: true,
          borderColor: "#E0712F",
          backgroundColor: "#E0712F",
          data: this.setChartData(this.chartsData, "gallop", "FR"),
        },
      ];
      const BL = [
        {
          title: "Walk",
          leg: "HL",
          checked: true,
          borderColor: "#587DC0",
          backgroundColor: "#587DC0",
          data: this.setChartData(this.chartsData, "walk", "BL"),
        },
        {
          title: "Trot",
          leg: "HL",
          checked: true,
          borderColor: "#587DC0",
          backgroundColor: "#587DC0",
          data: this.setChartData(this.chartsData, "trot", "BL"),
        },
        {
          title: "Gallop",
          leg: "HL",
          checked: true,
          borderColor: "#587DC0",
          backgroundColor: "#587DC0",
          data: this.setChartData(this.chartsData, "gallop", "BL"),
        },
      ];
      const BR = [
        {
          title: "Walk",
          leg: "HR",
          checked: true,
          borderColor: "#FFCD4B",
          backgroundColor: "#FFCD4B",
          data: this.setChartData(this.chartsData, "walk", "BR"),
        },
        {
          title: "Trot",
          leg: "HR",
          checked: true,
          borderColor: "#FFCD4B",
          backgroundColor: "#FFCD4B",
          data: this.setChartData(this.chartsData, "trot", "BR"),
        },
        {
          title: "Gallop",
          leg: "HR",
          checked: true,
          borderColor: "#FFCD4B",
          backgroundColor: "#FFCD4B",
          data: this.setChartData(this.chartsData, "gallop", "BR"),
        },
      ];
      this.data = [...FR, ...FL, ...BL, ...BR];
    },
    setClass(val, type) {
      if (!val) return "";
      const percent = +(100 * +val).toFixed(3);
      const thresholds = this.getLamenessThresholds(type);

      return this.getSignatureColor(percent, thresholds);
    },
    getLamenessThresholds(type) {
      const thresholds = {
        info: 0,
        warning: 0,
        danger: 0,
      };
      thresholds.info = Number(
        this.horseLamenessSettings[`lameness.threshold_info.${type}`].value
      );
      thresholds.warning = Number(
        this.horseLamenessSettings[`lameness.threshold_warning.${type}`].value
      );
      thresholds.danger = Number(
        this.horseLamenessSettings[`lameness.threshold_danger.${type}`].value
      );

      return thresholds;
    },
    getSignatureColor(percent, thresholds) {
      if (percent < thresholds.info) return "";
      else if (percent > thresholds.info && percent < thresholds.warning)
        return "bg-[#0dcaf040]";
      else if (percent > thresholds.warning && percent < thresholds.danger)
        return "bg-[#ffc10780]";
      else if (percent > thresholds.danger) return "bg-[#dc354580]";
    },
  },
};
</script>

<template>
  <HomeLayout>
    <div class="w-full min-h-[60px] flex justify-center items-center relative">
      <BackButton />
    </div>
    <TrainingsComparisonComponent />
  </HomeLayout>
</template>

<script>
import HomeLayout from "@/shared/layouts/HomeLayout.vue";
import TrainingsComparisonComponent from "@/components/training/comparison/TrainingsComparisonComponent.vue";
import BackButton from "@/shared/components/BackButton.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { showErrorAndGoToHomePage } from "@/store/storeUtils";

export default {
  name: "TrainingsComparisonView",
  components: {
    TrainingsComparisonComponent,
    HomeLayout,
    BackButton,
  },
  data() {
    return {
      commonsData: [],
    };
  },
  computed: {
    ...mapGetters(["horseTrackingCard", "selectedDayTrainings"]),
  },
  watch: {
    selectedDayTrainings(newVal) {
      this.commonsData = newVal.map((i) => {
        const data = i.results.find(
          (result) => result.type === "TRAINING_COMMONS"
        );
        return {
          ...data.rowResult,
          created: data.created,
          trainingId: data.trainingId,
        };
      });
    },
  },
  methods: {
    ...mapActions(["getHorsesList", "getTrainingsDates"]),
    ...mapMutations(["SET_LOADING", "SET_HORSE_TRACKING_CARD"]),
    async setCurrentHorse() {
      await this.$nextTick();
      if (this.horsesList) {
        const currentHorse = this.horsesList.find(
          (h) => h.id === Number(this.$route.params.id)
        );
        if (!currentHorse) {
          return showErrorAndGoToHomePage("Horse not found");
        }
        this.SET_HORSE_TRACKING_CARD(currentHorse);
      }
    },
  },

  async mounted() {
    if (!this.horsesList) await this.getHorsesList(this.$route.params.page);
    if (!this.horseTrackingCard) await this.setCurrentHorse();
    await this.getTrainingsDates(this.$route.params.id);
  },
};
</script>

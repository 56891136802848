<template>
  <div class="w-full flex flex-wrap justify-between border-2 p-4 min-h-[600px]">
    <!-- Left Column: Horse Tracking -->
    <HorseTrackingComponent
      :tracking="trackingData"
      :lengthPreviousValues="historyLength"
      :fromToCutValue="fromToCutValue"
      :selectedGaits="selectedGaits"
      :trackingMarkersLength="trackingMarkersLength"
      :cutValue="cutValue"
      :currentTabIdx="currentTabIdx"
    />

    <!-- Right Column: Charts -->
    <ChartsColumn
      :currentTab="currentTabIdx"
      :tabs="tabs"
      :reports="reports"
      :isFullscreenMapOpen="isFullscreenMapOpen"
      :isFullscreenMultichartOpen="isFullscreenMultichartOpen"
      :secondsByGait="secondsByGait"
      :chartSettings="chartSettings"
      :indexColor="indexColor"
    />
  </div>
</template>

<script>
import ChartsColumn from "./ChartsColumn.vue";
import HorseTrackingComponent from "@/modules/training/submodules/tracking/components/HorseTrackingComponent.vue";

export default {
  name: "TrackingContent",
  components: {
    HorseTrackingComponent,
    ChartsColumn,
  },
  props: {
    currentMappedData: {
      type: Array,
      required: true,
    },
    historyLength: {
      type: Number,
      required: true,
    },
    fromToCutValue: {
      type: Array,
      required: true,
    },
    selectedGaits: {
      type: Array,
      required: true,
    },
    trackingMarkersLength: {
      type: Number,
      required: true,
    },
    cutValue: {
      type: Array,
      default: null,
    },
    currentTabIdx: {
      type: Number,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    reports: {
      type: Object,
      required: true,
    },
    isFullscreenMapOpen: {
      type: Boolean,
      default: false,
    },
    isFullscreenMultichartOpen: {
      type: Boolean,
      default: false,
    },
    secondsByGait: {
      type: Array,
      required: true,
    },
    multicharts: {
      type: Array,
      required: true,
    },
    maxRadius: {
      type: Number,
      required: true,
    },
    indexColor: {
      type: String,
      required: true,
    },
  },
  computed: {
    trackingData() {
      return this.cutValue
        ? this.currentMappedData.slice(this.cutValue[0], this.cutValue[1])
        : this.currentMappedData;
    },
    chartSettings() {
      return {
        currentMappedData: this.currentMappedData,
        selectedGaits: this.selectedGaits,
        cutValue: this.cutValue,
        secondsByGait: this.secondsByGait,
        trackingMarkersLength: this.trackingMarkersLength,
        multicharts: this.multicharts,
        maxRadius: this.maxRadius,
        historyLength: this.historyLength,
      };
    },
  },
};
</script>

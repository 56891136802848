<template>
  <div
    class="flex flex-col items-start w-full lg:max-w-[52%] h-full"
    :class="{
      'lg:max-w-none': isFullscreenMapOpen,
      'h-fit': !isFullscreenMapOpen,
    }"
  >
    <component :is="currentChartComponent" v-bind="currentChartProps" />
  </div>
</template>

<script>
import MultichartWrapper from "@/modules/training/submodules/tracking/components/multiChart/MultichartWrapper.vue";
import EnergyChart from "@/modules/training/submodules/tracking/components//charts/EnergyChart.vue";
import EnergyByAllurChart from "@/modules/training/submodules/tracking/components//charts/EnergyByAllurChart.vue";
import CenterOfGravityChart from "@/modules/training/submodules/tracking/components//charts/CenterOfGravityChart.vue";

export default {
  name: "ChartsColumn",
  components: {
    MultichartWrapper,
    EnergyChart,
    EnergyByAllurChart,
    CenterOfGravityChart,
  },
  props: {
    currentTab: {
      type: Number,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    reports: {
      type: Object,
      required: true,
    },
    isFullscreenMapOpen: {
      type: Boolean,
      default: false,
    },
    isFullscreenMultichartOpen: {
      type: Boolean,
      default: false,
    },
    secondsByGait: {
      type: Array,
      required: true,
    },
    chartSettings: {
      type: Object,
      required: true,
    },
    indexColor: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentChartComponent() {
      if (!this.tabs[this.currentTab]?.isEnabled) return null;

      switch (this.currentTab) {
        case 0:
          return this.isFullscreenMultichartOpen ? null : "MultichartWrapper";
        case 1:
          return "EnergyChart";
        case 2:
          return "EnergyByAllurChart";
        case 3:
          return "CenterOfGravityChart";
        default:
          return null;
      }
    },

    currentChartProps() {
      if (!this.currentChartComponent) return {};

      const energyData = this.reports.EXPENDITURE_ENERGY_METRICS.raw
        ? this.reports.EXPENDITURE_ENERGY_METRICS.raw
        : this.reports.EXPENDITURE_ENERGY_METRICS;

      switch (this.currentTab) {
        case 0:
          return {
            mappedData: this.chartSettings.currentMappedData,
            selectedGaits: this.chartSettings.selectedGaits,
            cutValue: this.chartSettings.cutValue,
            secondsByGait: this.secondsByGait,
            trackingMarkersLength: this.chartSettings.trackingMarkersLength,
            multicharts: this.chartSettings.multicharts,
          };
        case 1:
        case 2:
          return {
            datasets: energyData,
            indexColor: this.indexColor,
            cutValue: this.chartSettings.cutValue,
          };
        case 3:
          return {
            tracking: this.chartSettings.cutValue
              ? this.chartSettings.currentMappedData.slice(
                  this.chartSettings.cutValue[0],
                  this.chartSettings.cutValue[1]
                )
              : this.chartSettings.currentMappedData,
            maxRadius: this.chartSettings.maxRadius,
            lengthPreviousValues: this.chartSettings.historyLength,
          };
        default:
          return {};
      }
    },
  },
};
</script>

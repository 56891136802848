<template>
  <Disclosure as="nav" class="w-full fixed z-50">
    <div
      class="w-full flex flex-wrap justify-between bg-white sm:px-4 relative l-0 r-0 z-50 shadow-md"
    >
      <div class="flex h-16 md:mx-20 justify-start">
        <div class="flex px-2 lg:px-0">
          <div class="flex items-center">
            <svg
              v-if="isShowSidebar"
              @click="$emit('emitSwitchSidebar')"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
              class="mr-4 h-[40px] w-[40px] text-[5A5A5F] cursor-pointer"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            <div v-else class="w-[40px] mr-4"></div>
            <Button @click="$router.push({ name: routeNames.home })">
              <img
                class="block h-[60px] w-auto my-2"
                :src="require('@/assets/hcLogo.svg')"
                alt="Company logo"
              />
            </Button>
          </div>
        </div>
      </div>
      <ul
        role="list"
        class="mx-auto lg:mx-4 mb-4 md:mb-0 flex flex-wrap justify-center items-center"
      >
        <NotificationsComponent />

        <li
          v-for="item in userNavigation"
          :key="item.name"
          class="font-poppins text-[#5A5A5F]"
        >
          <button
            @click.prevent="handleClick(item)"
            class="flex justify-start items-center h-[44px] w-full px-2"
            :class="{ 'text-gray-500, cursor-default': item.disabled }"
          >
            <img :src="item.img" alt="icon" class="w-[24px] h-[24px] mr-1" />
            <span class="text-[18px]">{{ $t(item.title) }}</span>
          </button>
        </li>
        <div class="ml-4 mr-2">
          <LangSwitcherComponent />
        </div>
      </ul>
    </div>
  </Disclosure>
  <ModalComponent
    small
    :is-open="logoutModal"
    @handleCancel="handleCancel"
    @handleAccept="handleAccept"
  >
    <template v-slot:title>
      <span class="px-2">{{ $t("Confirm logout") }}</span>
    </template>
    <template v-slot:content>
      <div class="text-[#5A5A5F] font-semibold px-2">
        {{ $t("Are you sure you want to logout from") }}
        <span class="font-bold">{{ $t("Logistic Dashboard") }}</span
        >?
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import { Disclosure } from "@headlessui/vue";
import ModalComponent from "@/shared/components/ModalComponent.vue";
import { mapActions } from "vuex";
import LangSwitcherComponent from "@/shared/components/header/LangSwitcherComponent.vue";
import NotificationsComponent from "@/shared/components/header/NotificationsComponent.vue";
import routeNames from "@/router/routes";

export default {
  name: "HeaderComponent",
  components: {
    NotificationsComponent,
    Disclosure,
    ModalComponent,
    LangSwitcherComponent,
  },
  data() {
    return {
      routeNames,
      logoutModal: false,
      userNavigation: [
        {
          title: "Shopping",
          href: "https://www.horsecare.si/shop/",
          disabled: false,
          img: require("@/assets/icons/shopping.svg"),
        },
        {
          title: "Horses",
          name: "horses",
          disabled: false,
          img: require("@/assets/icons/horses.svg"),
        },
        {
          title: "Logout",
          action: true,
          img: require("@/assets/icons/logout.svg"),
        },
      ],
    };
  },
  computed: {
    isShowSidebar() {
      return (
        this.$route.name === routeNames.training ||
        this.$route.name === routeNames.centerOfGravityTracking ||
        this.$route.name === routeNames.tracking ||
        this.$route.name === routeNames.comparison
      );
    },
  },
  methods: {
    ...mapActions(["logout", "closeAllFullscreenWindows"]),
    handleClick(item) {
      if (item.disabled) return;
      if (item.name) {
        item.name !== routeNames.horses
          ? this.$router.push({ name: item.name })
          : this.$router.push({ name: item.name, params: { page: 0 } });
      } else if (item.action) {
        this.logoutModal = true;
        this.closeAllFullscreenWindows();
      } else if (item.href) {
        window.open(item.href, "_blank");
      }
    },
    handleCancel() {
      this.logoutModal = false;
    },
    handleAccept() {
      this.logoutModal = false;
      this.logout();
    },
  },
};
</script>

<template>
  <vue-draggable-resizable
    :active="true"
    :x="position.x"
    :y="position.y"
    :w="position.width"
    :h="position.height"
    :style="{ zIndex }"
    :drag-handle="'.modal-header'"
    :drag-cancel="'.drag-cancel'"
    :resizable="true"
    :minWidth="minWidth"
    :minHeight="minHeight"
    :handles="['tr', 'mr', 'br', 'bl', 'ml']"
    @dragStop="onDragStop"
    @resizeStop="onResizeStop"
    @activated="$emit('activated')"
    :onDrag="onDrag"
    :onResize="onResize"
  >
    <div class="modal h-full">
      <div
        class="modal-header bg-gray-800 text-white p-2 cursor-move flex justify-between items-center"
      >
        <div>
          <span>{{ title }}</span>
          <button
            @click="$emit('reset-position')"
            class="drag-cancel ml-4 p-2 cursor-pointer bg-white text-gray-800 rounded-md hover:bg-gray-100"
          >
            Reset position
          </button>
        </div>
        <button
          class="drag-cancel text-red-500 hover:text-red-700 font-bold"
          @click="$emit('close')"
        >
          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="modal-content" :class="[contentClass, 'h-full-available']">
        <slot></slot>
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import { XMarkIcon } from "@heroicons/vue/24/outline";

export default {
  name: "BaseModal",
  components: {
    VueDraggableResizable,
    XMarkIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    position: {
      type: Object,
      required: true,
    },
    zIndex: {
      type: Number,
      default: 50,
    },
    minWidth: {
      type: Number,
      default: 600,
    },
    minHeight: {
      type: Number,
      default: 350,
    },
    contentClass: {
      type: String,
      default: "p-4",
    },
  },
  emits: ["activated", "close", "save-position", "save-size", "reset-position"],
  data() {
    return {
      safeMargin: 8,
      isUserInteractionBlocked: false,
      currentCoordinates: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    async checkInitialSize() {
      const root = document.getElementById("app");
      if (!root) return;

      const { width: appWidth, height: appHeight } =
        root.getBoundingClientRect();
      const modalWidth = this.position.width;
      const modalHeight = this.position.height;
      const modalX = this.position.x;
      const modalY = this.position.y;

      // Check if modal exceeds viewport borders
      const exceedsWidth =
        modalX + modalWidth > appWidth - this.safeMargin ||
        modalX < this.safeMargin;
      const exceedsHeight =
        modalY + modalHeight > appHeight - this.safeMargin ||
        modalY < this.safeMargin;

      // If modal size or position exceeds the viewport, reset position
      if (
        exceedsWidth ||
        exceedsHeight ||
        modalWidth > appWidth ||
        modalHeight > appHeight
      ) {
        await this.$nextTick();
        this.$emit("reset-position");
      }
    },

    onDragStop() {
      this.unblockUserActions();
      this.$emit("save-position", {
        x: this.currentCoordinates.x,
        y: this.currentCoordinates.y,
      });
    },

    onResizeStop(left, top, width, height) {
      this.unblockUserActions();
      this.$emit("save-size", { left, top, width, height });
    },

    onDrag(left, top) {
      const root = document.getElementById("app");
      if (!root) return;

      const modal = this.$el;
      if (!modal) return;

      if (!this.isUserInteractionBlocked) this.blockUserActions();

      const rect = modal.getBoundingClientRect();
      const { width: appWidth, height: appHeight } =
        root.getBoundingClientRect();
      const modalWidth = rect.width;
      const modalHeight = rect.height;

      const safe = this.safeMargin;
      const offset = 10;

      const minLeft = safe + offset;
      const maxLeft = appWidth - modalWidth - safe - offset;
      const minTop = safe + offset;
      const maxTop = appHeight - modalHeight - safe - offset;

      let newLeft = left;
      let newTop = top;

      if (left < minLeft) {
        newLeft = minLeft;
      } else if (left > maxLeft) {
        newLeft = maxLeft;
      }

      if (top < minTop) {
        newTop = minTop;
      } else if (top > maxTop) {
        newTop = maxTop;
      }

      this.currentCoordinates = {
        x: newLeft,
        y: newTop,
      };

      if (newLeft !== left || newTop !== top) {
        modal.style.transform = `translate(${newLeft}px, ${newTop}px)`;
        return false;
      }
    },

    onResize(handle, x, y, width, height) {
      const root = document.getElementById("app");
      if (!root) return;

      const modal = this.$el;
      if (!modal) return;

      const { width: appWidth, height: appHeight } =
        root.getBoundingClientRect();

      const safe = this.safeMargin;
      const offset = 10;

      const minLeft = safe + offset;
      const maxLeft = appWidth - width - safe - offset;
      const minTop = safe + offset;
      const maxTop = appHeight - height - safe - offset;

      if (x < minLeft || x > maxLeft || y < minTop || y > maxTop) {
        return false;
      }
    },

    blockUserActions() {
      document.body.style.userSelect = "none";
      this.isUserInteractionBlocked = true;
    },

    unblockUserActions() {
      document.body.style.userSelect = "";
      this.isUserInteractionBlocked = false;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.checkInitialSize();
    });
  },
};
</script>

<style lang="scss" scoped>
.modal {
  background-color: #f6f6f6;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vdr {
  border: none;
}
</style>

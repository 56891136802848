<template>
  <BaseModal
    title="Multichart"
    :position="position"
    :z-index="zIndex"
    :min-width="800"
    :min-height="650"
    content-class="p-4 flex flex-col"
    @activated="$emit('activated')"
    @close="$emit('close')"
    @save-position="$emit('save-position', $event)"
    @save-size="$emit('save-size', $event)"
    @reset-position="$emit('reset-position')"
  >
    <MultichartWrapper
      v-if="reports"
      :mappedData="mappedData"
      :selectedGaits="selectedGaits"
      :cutValue="cutValue"
      :secondsByGait="secondsByGait"
      :trackingMarkersLength="trackingMarkersLength"
      :multicharts="multicharts"
    />
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import MultichartWrapper from "@/modules/training/submodules/tracking/components/multiChart/MultichartWrapper.vue";

export default {
  name: "MultichartModal",
  components: {
    BaseModal,
    MultichartWrapper,
  },
  props: {
    position: {
      type: Object,
      required: true,
    },
    zIndex: {
      type: Number,
      required: true,
    },
    mappedData: {
      type: Array,
      required: true,
    },
    selectedGaits: {
      type: Array,
      required: true,
    },
    cutValue: {
      type: Array,
      required: true,
    },
    secondsByGait: {
      type: Array,
      required: true,
    },
    trackingMarkersLength: {
      type: Number,
      required: true,
    },
    multicharts: {
      type: Array,
      required: true,
    },
    reports: {
      type: Object,
      required: true,
    },
  },
  emits: ["activated", "close", "save-position", "save-size", "reset-position"],
};
</script>

<style lang="scss" scoped>
:deep(.modal-content) {
  height: calc(100% - 56px);
}
</style>

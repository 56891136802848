<template>
  <div class="flex gap-2 w-full ml-2 mb-4">
    <TableComponent
      v-if="reports && reports.TRACKING"
      :tableData="tableData1"
    />
    <TableComponent
      v-if="reports && reports.TRACKING"
      :tableData="tableData2"
    />
    <TableComponent
      v-if="reports && reports.TRACKING"
      :tableData="tableData3"
    />
  </div>
</template>

<script>
import TableComponent from "@/shared/components/ui/TableComponent.vue";
import { trackingModeKeys } from "@/modules/training/constants";
import { mapGetters } from "vuex";
import { getTrackIndexFromTimestamp } from "@/modules/training/submodules/tracking/components/multiChart/multiChartUtils";
import { findLastNonStayElementIndex } from "@/modules/training/submodules/tracking/helpers";
import { getSecond } from "@/modules/training/helpers";

export default {
  name: "TablesList",
  components: { TableComponent },
  props: {
    cutValue: Array,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["reports", "trackingMode", "GPSDatafiles", "HRDatafiles"]),

    tableData1() {
      return [
        {
          name: this.$t("tracking.Segment distance"),
          value: this.segmentDistance,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment time"),
          value: this.segmentTime,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment energy"),
          value: this.segmentEnergy,
          disabled: false,
        },
      ];
    },
    tableData2() {
      return [
        {
          name: this.$t("tracking.Segment average speed"),
          value: this.segmentAvgSpeed,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment Max speed"),
          value: this.segmentMaxSpeed,
          disabled: false,
        },
        {
          name: this.$t("tracking.Segment Max acceleration"),
          value: this.segmentMaxAcceleration,
          disabled: false,
        },
      ];
    },
    tableData3() {
      return [
        {
          name: this.$t("tracking.Segment Average HR"),
          value: this.segmentAvgHeartRate,
          disabled: Boolean(!this.HRDatafiles),
        },
        {
          name: this.$t("tracking.Segment Max HR"),
          value: this.segmentMaxHeartRate,
          disabled: Boolean(!this.HRDatafiles),
        },
        {
          name: this.$t("tracking.Jump count"),
          value: this.jumpCount,
          disabled: false,
        },
      ];
    },

    segmentDistance() {
      if (this.reports && this.GPSDatafiles && this.reports.TRACKING.ts) {
        if (this.trackingMode === trackingModeKeys.GPS) {
          return this.generateGPSSegmentDistance();
        } else if (this.trackingMode === trackingModeKeys.ML) {
          return this.generateMLSegmentDistance();
        }
      } else if (
        this.reports &&
        this.reports.TRACKING.ts &&
        this.reports.SUMMARY
      ) {
        return this.generateMLSegmentDistance();
      }
      return 0;
    },

    segmentTime() {
      if (this.reports && this.reports.TRACKING.ts) {
        const mainTimestamps = this.reports.TRACKING.ts;

        const startTimestamp = this.cutValue
          ? mainTimestamps[this.cutValue[0]]
          : mainTimestamps[0];

        const endTimestamp = this.cutValue
          ? mainTimestamps[this.cutValue[1] - 1]
          : mainTimestamps[mainTimestamps.length - 1];

        const [startHour, startMinute, startSecond] = startTimestamp
          .split(":")
          .map(Number);
        const [endHour, endMinute, endSecond] = endTimestamp
          .split(":")
          .map(Number);

        // Round the milliseconds part to 3 decimal places
        const startMs = Math.round(
          parseFloat(startTimestamp.split(".")[1]) / 1000
        );
        const endMs = Math.round(parseFloat(endTimestamp.split(".")[1]) / 1000);

        const startTimeInSeconds =
          startHour * 3600 + startMinute * 60 + startSecond + startMs / 1000;
        const endTimeInSeconds =
          endHour * 3600 + endMinute * 60 + endSecond + endMs / 1000;

        const timeDifferenceInSeconds = endTimeInSeconds - startTimeInSeconds;

        const hours = Math.floor(timeDifferenceInSeconds / 3600);
        const minutes = Math.floor((timeDifferenceInSeconds % 3600) / 60);
        const seconds = Math.floor(timeDifferenceInSeconds % 60);

        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`;
      }
      return 0;
    },

    segmentEnergy() {
      if (!this.reports || !this.reports.TRACKING?.gait) return [];
      else {
        let lastGaitIndex = this.reports.TRACKING.gait.length - 1;
        if (this.cutValue) {
          lastGaitIndex = this.cutValue[1];
        }

        const gaitsObject = this.reports.EXPENDITURE_ENERGY_METRICS.raw
          ? this.reports.EXPENDITURE_ENERGY_METRICS.raw
          : this.reports.EXPENDITURE_ENERGY_METRICS;

        const newIndex = findLastNonStayElementIndex(
          this.reports.TRACKING.gait,
          lastGaitIndex
        );
        const lastGait = this.reports.TRACKING.gait[newIndex];
        const lastGaitSecond = Math.floor(newIndex / 5);

        const cumulativeEnergy = lastGait
          ? this.sumOfLastElementsForCumulativeEnergy(
              gaitsObject[lastGait],
              lastGaitSecond
            )
          : 0;
        return (cumulativeEnergy / 1000).toFixed(2);
      }
    },

    segmentAvgSpeed() {
      if (this.reports.TRACKING.ts) {
        const [startHour, startMinute, startSecond] = this.segmentTime
          .split(":")
          .map(Number);
        const timeInSeconds = startHour * 3600 + startMinute * 60 + startSecond;

        const avgSpeed = this.segmentDistance / timeInSeconds;
        return Math.round(avgSpeed * 100) / 100;
      }
      return 0;
    },

    segmentMaxSpeed() {
      if (this.reports.TRACKING.ts) {
        return this.generateMLMaxSpeed();
      }
      return 0;
    },

    segmentMaxAcceleration() {
      if (
        this.reports &&
        this.reports.SUMMARY &&
        this.reports.SUMMARY_RESAMPLED
      ) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.reports.SUMMARY_RESAMPLED.ts.at(-1)
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const accelerationArray = this.reports.SUMMARY_RESAMPLED.avg_acc.slice(
          startSliceSecond,
          endSliceSecond
        );
        const maxAccValue = Math.max(...accelerationArray);

        return maxAccValue.toFixed(2);
      }
      return 0;
    },

    segmentAvgHeartRate() {
      if (this.reports && this.HRDatafiles && this.HRDatafiles.length) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.HRDatafiles.at(-1).ts
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const arrayOfHR = this.HRDatafiles.slice(
          startSliceSecond,
          endSliceSecond
        );
        const sumOfHR = arrayOfHR.map((i) => i.hr).reduce((a, b) => a + b, 0);

        return Math.round(sumOfHR / arrayOfHR.length);
      }
      return "-";
    },

    segmentMaxHeartRate() {
      if (this.reports && this.HRDatafiles && this.HRDatafiles.length) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.HRDatafiles.at(-1).ts
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const HRArray = this.HRDatafiles.map((i) => i.hr).slice(
          startSliceSecond,
          endSliceSecond
        );

        return Math.max(...HRArray);
      }
      return "-";
    },

    jumpCount() {
      if (!this.reports.JUMP_count) return "-";
      let result = 0;
      let startSliceSecond = 0;
      let endSliceSecond =
        getSecond(this.reports.SUMMARY_RESAMPLED.ts.at(-1)) + 1;

      if (this.cutValue) {
        startSliceSecond = Math.round(this.cutValue[0] / 5);
        endSliceSecond = Math.round(this.cutValue[1] / 5);
      }
      this.reports.JUMP.forEach((i) => {
        getSecond(i.start) >= startSliceSecond &&
          getSecond(i.end) <= endSliceSecond &&
          result++;
      });
      return result;
    },
  },

  methods: {
    generateGPSSegmentDistance() {
      if (this.reports.GPS?.resampled) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.reports.GPS.resampled.ts.at(-1)
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const totalDistance = this.reports.GPS.resampled.distance
          .slice(startSliceSecond, endSliceSecond)
          .reduce((a, b) => a + b, 0);

        return Math.ceil(totalDistance);
      }
      return 0;
    },
    generateMLSegmentDistance() {
      if (this.reports.SUMMARY_RESAMPLED) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.reports.SUMMARY_RESAMPLED.ts.at(-1)
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const totalDistance = this.reports.SUMMARY_RESAMPLED.stride_length
          .slice(startSliceSecond, endSliceSecond)
          .reduce((a, b) => a + b, 0);

        return Math.round(totalDistance);
      }
      return 0;
    },
    sumOfLastElementsForCumulativeEnergy(obj, lastGaitSecond) {
      return Object.values(obj).reduce((sum, nestedObj) => {
        if (
          nestedObj.cumulative_energy &&
          Array.isArray(nestedObj.cumulative_energy) &&
          nestedObj.cumulative_energy.length > 0
        ) {
          const { cumulative_energy, ts } = nestedObj;
          for (let i = ts.length - 1; i >= 0; i--) {
            if (getSecond(ts[i]) < lastGaitSecond) {
              return sum + cumulative_energy[i];
            }
          }
        }
        return sum;
      }, 0);
    },

    generateGPSMaxSpeed() {
      if (this.reports.GPS?.resampled) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.reports.GPS.resampled.ts.at(-1)
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const speedArray = this.reports.GPS.resampled.speed.slice(
          startSliceSecond,
          endSliceSecond
        );
        const maxSpeedValue = Math.max(...speedArray);

        return maxSpeedValue.toFixed(2);
      }
      return 0;
    },
    generateMLMaxSpeed() {
      if (this.reports.SUMMARY_RESAMPLED) {
        let startSliceSecond = 0;
        let endSliceSecond = getTrackIndexFromTimestamp(
          this.reports.SUMMARY_RESAMPLED.ts.at(-1)
        );

        if (this.cutValue) {
          startSliceSecond = Math.round(this.cutValue[0]);
          endSliceSecond = Math.round(this.cutValue[1]);
        }

        const speedArray = this.reports.SUMMARY_RESAMPLED.avg_vel.slice(
          startSliceSecond,
          endSliceSecond
        );
        const maxSpeedValue = Math.max(...speedArray);

        return maxSpeedValue.toFixed(2);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <HomeLayout>
    <LoaderComponent v-if="loading" />
    <MainTrackingContainer v-else />
  </HomeLayout>
</template>
<script>
import LoaderComponent from "@/shared/components/LoaderComponent.vue";
import HomeLayout from "@/shared/layouts/HomeLayout.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import MainTrackingContainer from "@/modules/training/submodules/tracking/views/MainTrackingContainer.vue";
import { showErrorAndGoToHomePage } from "@/store/storeUtils";

export default {
  name: "TrackingView",
  components: {
    HomeLayout,
    LoaderComponent,
    MainTrackingContainer,
  },
  computed: {
    ...mapGetters(["loading", "horsesList", "horseTrackingCard"]),
  },
  methods: {
    ...mapActions(["getHorsesList"]),
    ...mapMutations(["SET_LOADING", "SET_HORSE_TRACKING_CARD"]),
    setCurrentHorse() {
      const currentHorse = this.horsesList.find(
        (h) => h.id === Number(this.$route.params.id)
      );
      if (!currentHorse) {
        return showErrorAndGoToHomePage("Horse not found");
      }
      this.SET_HORSE_TRACKING_CARD(currentHorse);
    },
  },
  async mounted() {
    this.SET_LOADING(true);
    if (!this.horsesList) await this.getHorsesList(this.$route.params.page);
    this.setCurrentHorse();
  },
};
</script>

<template>
  <aside
    class="fixed top-0 z-40 flex mt-0 flex-col shadow-lg transition-width transition-slowest ease-linear bg-white"
    :class="{
      'w-full sm:w-[280px]': isSidebarOpen && isShowSidebar,
      'w-0 p-0 hidden': !isSidebarOpen || !isShowSidebar,
    }"
  >
    <div
      class="h-[100vh] w-[280px] flex flex-col border-r border-gray-200 pt-[180px] lg:pt-[80px] bg-white transition-all transition-slowest ease-linear box-border overflow-y-auto"
    >
      <div v-if="horseTrackingCard && isShowSidebar">
        <HorseInfoComponent
          v-if="showHorseInfo"
          :horseTrackingCard="horseTrackingCard"
        />
        <TrainingsCalendarComponent
          v-if="!isComparison"
          @changeTraining="changeTraining"
        />
        <ComparisonCalendar v-else />
        <ChartsSelectorComponent
          v-if="isShowChartSelector"
          :availableChartsList="availableChartsList"
        />
      </div>
    </div>
  </aside>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ChartsSelectorComponent from "@/shared/components/sidebar/ChartsSelectorComponent.vue";
import TrainingsCalendarComponent from "@/shared/components/sidebar/TrainingsCalendarComponent.vue";
import HorseInfoComponent from "@/modules/training/components/HorseInfoComponent.vue";
import routeNames from "@/router/routes";
import { trackingModeKeys } from "@/modules/training/constants";
import ComparisonCalendar from "@/components/training/comparison/ComparisonCalendar.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "SidebarComponent",
  props: {
    isSidebarOpen: Boolean,
  },
  components: {
    HorseInfoComponent,
    ChartsSelectorComponent,
    TrainingsCalendarComponent,
    ComparisonCalendar,
  },
  data() {
    return {
      routeNames,
      trackingModeKeys,
      availableChartsList: [
        {
          isEnabled: false,
          title: "charts-selector.Stiffness power",
          data: "Stiffness power",
          type: "training",
        },
        {
          isEnabled: false,
          title: "charts-selector.Activity",
          data: "Activity",
          type: "training",
        },
        {
          isEnabled: true,
          title: "tracking.Multichart",
          data: "Multichart",
          type: "tracking",
        },
        {
          isEnabled: true,
          title: "tracking.Cumulative energy",
          data: "Cumulative energy",
          type: "tracking",
        },
        {
          isEnabled: true,
          title: "tracking.Expenditure energy",
          data: "Expenditure energy",
          type: "tracking",
        },
        {
          isEnabled: true,
          title: "tracking.Center of gravity",
          data: "Center of gravity",
          type: "tracking",
        },
        {
          isEnabled: true,
          title: "comparison.Multichart",
          data: "Comparison Multichart",
          type: "comparison",
        },
        {
          isEnabled: true,
          title: "comparison.Skewness and hist history",
          data: "Skewness and hist history",
          type: "comparison",
        },
        {
          isEnabled: true,
          title: "comparison.Cumulative energy costs history",
          data: "Cumulative energy costs history",
          type: "comparison",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "horseTrackingCard",
      "selectedDayTrainings",
      "reports",
      "initialPositionGPS",
    ]),
    showHorseInfo() {
      return (
        (this.$route.name === routeNames.tracking &&
          this.horseTrackingCard &&
          this.reports) ||
        this.$route.name === routeNames.comparison
      );
    },
    isComparison() {
      return this.$route.name === routeNames.comparison;
    },
    isShowSidebar() {
      return (
        this.$route.name === routeNames.training ||
        this.$route.name === routeNames.centerOfGravityTracking ||
        this.$route.name === routeNames.tracking ||
        this.$route.name === routeNames.comparison
      );
    },
    isShowChartSelector() {
      return !!(
        this.$route.name === routeNames.training ||
        this.$route.name === routeNames.comparison ||
        (this.$route.name === routeNames.tracking &&
          this.selectedDayTrainings.length)
      );
    },
  },
  methods: {
    ...mapMutations([
      "SET_LOADING",
      "SET_TRACKING_MODE",
      "SET_GOOGLE_MAPS_CENTER",
      "SET_MULTICHART_LOCAL_ZOOM",
      "SET_GOOGLE_MAPS_ZOOM",
      "SET_GOOGLE_MAPS_HEADING",
    ]),
    ...mapActions([
      "getTrainingReports",
      "getCurrentTrainingData",
      "getHorseLameness",
      "getGPSDatafileList",
      "getGPSDatafile",
      "getHRDatafileList",
      "getHRDatafile",
      "handleTrackingMode",
    ]),

    async changeTraining(data) {
      try {
        this.SET_LOADING(true);

        await Promise.all([
          this.getTrainingReports({ reports: data.results, id: data.id }),
          data.id !== +this.$route.params.trainingId &&
            this.getCurrentTrainingData(data.id),
          this.getHorseLameness(this.$route.params.id),
          this.getGPSDatafileList(data.id).then(async (res) => {
            if (res.length) {
              const selectedTrackingMode =
                localStorage.getItem("tracking-mode");
              this.SET_TRACKING_MODE(
                selectedTrackingMode
                  ? selectedTrackingMode
                  : this.trackingModeKeys.GPS
              );
              await this.getGPSDatafile(data.id);
              if (this.initialPositionGPS) {
                this.SET_GOOGLE_MAPS_CENTER({
                  lat: this.initialPositionGPS.latitude,
                  lng: this.initialPositionGPS.longitude,
                });
              }
            } else {
              this.SET_TRACKING_MODE(this.trackingModeKeys.ML);
            }
          }),
          this.getHRDatafileList(data.id).then(async (res) => {
            if (res.length) {
              await this.getHRDatafile(data.id);
            }
          }),
        ]);

        // TODO fix for the missing GPS data
        if (
          this.reports.GPS?.resampled === null ||
          this.reports.GPS?.filtered === null
        ) {
          toast.info(
            "Your data does not contain information about your location. Only tracking based on internal project mechanisms is available to you. Check if you have GPS positioning enabled"
          );
          this.handleTrackingMode({ data: this.trackingModeKeys.ML });
        }

        this.SET_MULTICHART_LOCAL_ZOOM({
          x: { min: 0, max: 0 },
        });
        this.SET_GOOGLE_MAPS_ZOOM(17);
        this.SET_GOOGLE_MAPS_HEADING(90);

        this.SET_LOADING(false);
      } catch (error) {
        console.error("Error changing training:", error);
        this.SET_LOADING(false);
      }
    },
  },
};
</script>

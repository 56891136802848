<template>
  <div class="w-full">
    <Line
      :data="chartData"
      :options="options"
      :plugins="plugins"
      ref="multiChart"
      id="chart"
      class="w-full min-h-[400px] h-full"
    />
  </div>
</template>

<script>
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { Line } from "vue-chartjs";
import annotationPlugin from "chartjs-plugin-annotation";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import zoomPlugin from "chartjs-plugin-zoom";
import { mapGetters, mapMutations } from "vuex";
import { debounce } from "lodash";
import {
  extractValueInBrackets,
  formatTimestampToSingleDecimal,
  generateZeroLine,
  getTrackIndexFromTimestamp,
  setScalesMinMax,
} from "@/modules/training/submodules/tracking/components/multiChart/multiChartUtils";

const patchedCrosshairPlugin = {
  ...CrosshairPlugin,
  afterDraw: function (chart) {
    if (!chart.crosshair || !chart.crosshair.enabled) {
      return;
    }
    CrosshairPlugin.afterDraw.apply(this, arguments);
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  annotationPlugin,
  zoomPlugin,
  patchedCrosshairPlugin
);

export default {
  name: "MultichartComponent",
  components: { Line },
  emits: ["updateDecimationData", "setLocalZoom", "updateZoomPercent"],
  props: {
    chartData: {
      labels: Array,
      datasets: Array,
    },
    secondsByGait: {
      type: Array,
      required: true,
    },
    getMultichartColor: {
      type: Function,
      required: true,
    },
    visibleChartWasUpdatedFlag: Boolean,
  },
  inject: ["cutValue", "jumpOptions", "selectNewJump"],

  watch: {
    currentTrackIndex(newIndex, oldIndex) {
      if (newIndex === oldIndex) return;

      if (!this.animationFrame) {
        this.animationFrame = requestAnimationFrame(() => {
          this.updateChartPosition();
          this.animationFrame = null;
        });
      }
    },

    localZoom: {
      handler: async function (newVal) {
        await this.$nextTick();
        const chart = this.$refs.multiChart.chart;
        setScalesMinMax(chart, newVal);
        this.updateYScales(chart);

        chart.update("none");
      },
      deep: true,
    },

    async visibleChartWasUpdatedFlag() {
      await this.$nextTick();
      const chart = this.$refs.multiChart.chart;
      setScalesMinMax(chart, this.localZoom);

      this.handleZoomAndPanComplete(
        chart,
        this.localZoom.x.min,
        this.localZoom.x.max
      );
      this.setLocalZoom(chart, this.localZoom);
      this.updateYScales(chart);

      await this.$nextTick();
    },
  },

  computed: {
    ...mapGetters([
      "reports",
      "currentTrackIndex",
      "isAnimationModalOpen",
      "localZoom",
    ]),

    plugins() {
      return [
        {
          id: "customBackground",
          beforeDraw: (chart) => {
            const {
              ctx,
              chartArea: { top, height, left, right },
              scales: { x },
            } = chart;

            ctx.save();

            if (!x.ticks.length || !this.secondsByGait) return;
            const intervals = this.secondsByGait;

            // Limiting position within the chart
            const clampPosition = (value) =>
              Math.max(Math.min(value, right), left);

            // Main intervals
            intervals.forEach((interval) => {
              if (!interval.isLine) {
                const startX = x.getPixelForValue(interval.start);
                const endX = x.getPixelForValue(interval.end);

                const clampedStart = clampPosition(startX);
                const clampedEnd = clampPosition(endX);

                if (clampedStart < clampedEnd) {
                  ctx.fillStyle = interval.color;
                  ctx.fillRect(
                    clampedStart,
                    top,
                    clampedEnd - clampedStart,
                    height
                  );
                }
              }
            });

            // Jumps intervals
            intervals.forEach((interval) => {
              if (interval.isLine) {
                const startX = x.getPixelForValue(interval.start);
                const clampedStart = clampPosition(startX);
                ctx.strokeStyle = interval.color;
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.moveTo(clampedStart, top);
                ctx.lineTo(clampedStart, top + height);
                ctx.stroke();
              }
            });

            ctx.restore();
          },
        },

        {
          id: "customAnnotation",
          afterDraw: (chart) => {
            const {
              ctx,
              chartArea: { top, height },
              scales: { x },
            } = chart;

            if (this.currentTrackIndex) {
              const xPos = x.getPixelForValue(this.currentTrackIndex);

              ctx.save();
              ctx.strokeStyle = "red";
              ctx.lineWidth = 2;
              ctx.beginPath();
              ctx.moveTo(xPos, top);
              ctx.lineTo(xPos, top + height);
              ctx.stroke();
              ctx.restore();
            }
          },
        },

        {
          id: "customCrosshair",
          beforeEvent: function (chart, args) {
            if (args.event.type === "mousemove") {
              chart._mouseX = args.event.x;
              chart._mouseY = args.event.y;
            }
          },
          afterDraw: function (chart) {
            if (!chart.crosshair || !chart.crosshair.enabled) {
              return;
            }

            // Get saved cursor coordinates
            const mouseX = chart._mouseX;
            const mouseY = chart._mouseY;

            if (mouseX !== undefined && mouseY !== undefined) {
              const { left, right } = chart.chartArea;
              const ctx = chart.ctx;
              ctx.save();
              const lineColor = "gray";
              const dashPattern = [5, 5];
              ctx.strokeStyle = lineColor;
              ctx.setLineDash(dashPattern);
              ctx.lineWidth = 1;
              ctx.beginPath();
              ctx.moveTo(left, mouseY);
              ctx.lineTo(right, mouseY);
              ctx.stroke();
              ctx.restore();
            }
          },
        },
      ];
    },

    options() {
      const yAxes = this.chartData?.datasets?.reduce((acc, dataset, index) => {
        const axisId = dataset.yAxisID || `y${index}`;
        acc[axisId] = this.generateAxisOptions(axisId, dataset.label);
        return acc;
      }, {});

      const zeroLineAnnotations = Object.keys(yAxes).reduce(
        (annotations, axisId) => {
          annotations[`zeroLine_${axisId}`] = this.generateZeroLine(axisId);
          return annotations;
        },
        {}
      );

      return {
        animation: false,
        onClick: (event, item, chart) => {
          const xScale = chart.scales.x;
          const xValue = xScale.getValueForPixel(event.x);
          const label = chart.data.labels[Math.round(xValue)];
          const trackIndex = this.cutValue.value
            ? getTrackIndexFromTimestamp(label) - this.cutValue.value[0]
            : getTrackIndexFromTimestamp(label);

          if (event.native.metaKey || event.native.ctrlKey) {
            const foundJump = this.jumpOptions.value.find((jumpOption) => {
              let startTrackIndex = getTrackIndexFromTimestamp(
                formatTimestampToSingleDecimal(jumpOption.jump.start)
              );
              let endTrackIndex = getTrackIndexFromTimestamp(
                formatTimestampToSingleDecimal(jumpOption.jump.end)
              );
              if (this.cutValue.value) {
                startTrackIndex -= this.cutValue.value[0];
                endTrackIndex -= this.cutValue.value[0];
              }
              return (
                trackIndex >= startTrackIndex && trackIndex <= endTrackIndex
              );
            });

            if (foundJump) {
              this.selectNewJump({
                key: "jumps",
                data: foundJump.label,
              });
            }
          }

          if (this.isAnimationModalOpen) {
            this.SET_IS_FRAME_UPDATED_OUTSIDE_ANIMATION(true);
          }

          this.SET_CURRENT_TRACK_INDEX(trackIndex);
        },
        plugins: {
          crosshair: {
            sync: {
              enabled: false,
            },
            zoom: {
              enabled: false,
            },
            line: {
              color: "gray",
              dashPattern: [5, 5],
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "xy",
              overScaleMode: "y",
              onPanComplete: debounce(({ chart }) => {
                this.setLocalZoom(chart);
                this.handleZoomAndPanComplete(chart);
                this.updateYScales(chart);
              }, 100),
            },
            zoom: {
              wheel: { enabled: true, modifierKey: "ctrl" },
              pinch: { enabled: true },
              mode: "xy",
              overScaleMode: "y",
              onZoomComplete: debounce(({ chart }) => {
                this.setLocalZoom(chart);
                this.handleZoomAndPanComplete(chart);
                this.updateZoomPercent(chart);
                this.updateYScales(chart);
              }, 100),
            },
          },
          datalabels: false,
          decimation: true,
          colors: { forceOverride: true },
          annotation: {
            annotations: {
              annotation: {
                type: "line",
                borderWidth: 2,
                scaleID: "x",
                value: 0,
                adjustScaleRange: false,
              },
              ...zeroLineAnnotations,
            },
          },
          tooltip: {
            intersect: false,
            enabled: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 400,
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
          display: false,
        },
        scales: {
          x: {
            type: "category",
            ticks: {
              callback: function (value) {
                const currentValue = this.getLabelForValue(value);
                const [hours, minutes, seconds] = currentValue.split(":");
                const totalMinutes =
                  parseInt(hours, 10) * 60 + parseInt(minutes, 10);
                return `${totalMinutes}:${seconds}`;
              },
              maxRotation: 30,
              autoSkip: true,
              color: (context) => {
                const timeValue = context.tick.value;

                const currentGait =
                  this.secondsByGait?.find((i) => i.second === timeValue) ||
                  this.defaultGaitData;

                switch (currentGait?.gait) {
                  case "walk":
                    return "#1AB0B0";
                  case "trot":
                    return "#FFCD4B";
                  case "gallop":
                    return "#F85C7F";
                  default:
                    return "gray";
                }
              },
            },
          },
          ...yAxes,
        },
      };
    },
  },

  methods: {
    ...mapMutations([
      "SET_CURRENT_TRACK_INDEX",
      "SET_IS_FRAME_UPDATED_OUTSIDE_ANIMATION",
    ]),
    generateZeroLine,

    updateYScales(chart) {
      if (!chart) return;
      const minValue = chart.scales.x.min;
      const maxValue = chart.scales.x.max;

      const yAxisDataRanges = {};
      chart.data.datasets.forEach((dataset) => {
        const yValuesInRange = [];
        dataset.data.forEach((point) => {
          const xVal = this.cutValue?.value
            ? getTrackIndexFromTimestamp(point.x) - this.cutValue.value[0]
            : getTrackIndexFromTimestamp(point.x);

          if (xVal >= minValue && xVal <= maxValue) {
            if (point.y !== null && point.y !== undefined) {
              yValuesInRange.push(Number(point.y));
            }
          }
        });

        if (yValuesInRange.length > 0) {
          if (!yAxisDataRanges[dataset.yAxisID]) {
            yAxisDataRanges[dataset.yAxisID] = [];
          }
          yAxisDataRanges[dataset.yAxisID].push(...yValuesInRange);
        }
      });

      // Update the Y scale for each axis
      Object.keys(yAxisDataRanges).forEach((yAxisID) => {
        const yValues = yAxisDataRanges[yAxisID];
        if (yValues.length > 0) {
          const minY = Math.min(...yValues);
          const maxY = Math.max(...yValues);

          // 10% padding
          const buffer = (maxY - minY) * 0.1 || 0.1; // min padding
          const adjustedMinY = minY - buffer;
          const adjustedMaxY = maxY + buffer;

          // New borders for scale
          chart.options.scales[yAxisID].min = adjustedMinY;
          chart.options.scales[yAxisID].max = adjustedMaxY;
        } else {
          // Default scale borders
          chart.options.scales[yAxisID].min = 0;
          chart.options.scales[yAxisID].max = 1;
        }
      });

      chart.update("none");
    },

    updateZoomPercent(chart) {
      const totalRange = chart.data.labels.length;
      const visibleRange = chart.scales.x.max - chart.scales.x.min; // Visible range
      const zoomPercent = (visibleRange / totalRange) * 100; // Percentage of total range
      this.$emit("updateZoomPercent", zoomPercent);
    },

    handleZoomAndPanComplete(chart, newMin, newMax) {
      this.$emit("updateDecimationData", chart, newMin, newMax);
    },

    setLocalZoom(chart) {
      this.$emit("setLocalZoom", chart);
    },

    generateAxisOptions(id, label) {
      return {
        id: id,
        type: "linear",
        position: "left",
        ticks: {
          color: this.getMultichartColor(id),
          padding: 15,
        },
        border: {
          color: this.getMultichartColor(id),
        },
        grid: {
          color: this.getMultichartColor(id),
          drawOnChartArea: false,
        },
        title: {
          display: false,
          text: extractValueInBrackets(this.$t(label)),
          color: this.getMultichartColor(id),
          font: {
            weight: "bold",
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
      };
    },

    drawAnnotationDirectly() {
      const chart = this.$refs.multiChart.chart;
      if (!chart) return;

      const ctx = chart.ctx;
      const { top, height } = chart.chartArea;
      const x = chart.scales.x.getPixelForValue(this.currentTrackIndex);

      // Clear previous annotation
      chart.draw();
      ctx.save();
      ctx.strokeStyle = "red";
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(x, top);
      ctx.lineTo(x, top + height);
      ctx.stroke();
      ctx.restore();
    },

    updateChartPosition() {
      const chart = this.$refs.multiChart?.chart;
      if (!chart) return;

      const totalRange = chart.data.labels.length - 1;
      const currentSecond = this.currentTrackIndex || 0;
      const currentMin = chart.options.scales.x.min ?? 0;
      const currentMax = chart.options.scales.x.max ?? totalRange;

      // Draw annotation without chart updating
      if (currentSecond >= currentMin && currentSecond <= currentMax) {
        this.drawAnnotationDirectly();
        return;
      }

      // Move annotation
      const step = currentMax - currentMin;
      let newMin = Math.max(0, currentSecond - Math.floor(step / 2));
      let newMax = Math.min(totalRange, currentSecond + Math.floor(step / 2));

      if (
        chart.options.scales.x.min !== newMin ||
        chart.options.scales.x.max !== newMax
      ) {
        chart.options.scales.x.min = newMin;
        chart.options.scales.x.max = newMax;
        chart.update("none");
      }

      this.drawAnnotationDirectly();
      this.handleZoomAndPanComplete(
        chart,
        chart.options.scales.x.min,
        chart.options.scales.x.max
      );
      this.updateYScales(chart);
    },

    handleKeyDown(event) {
      const { key } = event;
      if (key === "Alt") {
        const chart = this.$refs.multiChart.chart;
        chart.options.plugins.tooltip.enabled = true;
        chart.update();
      }
    },
    handleKeyUp(event) {
      const { key } = event;
      if (key === "Alt") {
        const chart = this.$refs.multiChart.chart;
        chart.options.plugins.tooltip.enabled = false;
        chart.update();
      }
    },
  },

  async mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
    await this.$nextTick();
    const chart = this.$refs.multiChart.chart;
    if (chart) {
      this.updateYScales(chart);
    }
  },

  beforeUnmount() {
    if (this.$refs.multiChart) {
      document.removeEventListener("keydown", this.handleKeyDown);
      document.removeEventListener("keyup", this.handleKeyUp);
    }
  },
};
</script>

<style lang="scss" scoped></style>

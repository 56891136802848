import { createStore } from "vuex";
import auth from "@/modules/auth/store";
import horses from "@/modules/horses/store";
import trainings from "@/modules/training/store";

import track from "@/store/track";
import trainingsHistory from "@/store/trainingsHistory";
import googleMaps from "@/store/googleMaps";
import multichart from "@/store/multichart";

export default createStore({
  state: {
    loading: false,
  },
  getters: {
    loading: (state) => state.loading,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  modules: {
    auth,
    horses,
    trainings,
    track,
    trainingsHistory,
    googleMaps,
    multichart,
  },
});

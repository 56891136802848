import { auth } from "@/firebaseConfig";
import router from "@/router";
import routeNames from "@/router/routes";
import i18n from "@/translations";

import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
} from "firebase/auth";

import { useToast } from "vue-toastification";
const toast = useToast();

const actions = {
  async loginWithEmail({ commit }, { email, password }) {
    await signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        commit("SET_USER_DATA", res.user);
        commit("SET_TOKEN", res);
        commit("SET_LOADING", false);
        router.push({ name: routeNames.horses, params: { page: 0 } });
      })
      .catch((err) => {
        if (err.message === "Firebase: Error (auth/user-not-found).") {
          toast.error(i18n.global.t("auth.User with this login not found"));
        } else if (err.message === "Firebase: Error (auth/wrong-password).") {
          toast.error(i18n.global.t("auth.Wrong password"));
        } else {
          toast.error(err.message);
        }
        commit("SET_LOADING", false);
        throw err;
      });
  },

  async registrationWithEmail({ commit }, { email, password }) {
    await createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        const auth = getAuth();
        const actionCodeSettings = {
          url: "https://www.horsecare.si",
          handleCodeInApp: true,
          android: {
            packageName: "com.horsehealthdatareceiver",
            dynamicLinkDomain: "horsehealthdatareceiver.page.link",
          },
        };
        sendEmailVerification(auth.currentUser, actionCodeSettings);
        toast.success(
          i18n.global.t("auth.A verification letter was sent to your email")
        );
        localStorage.setItem(
          "horsecare-userTokens",
          JSON.stringify({
            token: res._tokenResponse.idToken,
            refreshToken: res._tokenResponse.refreshToken,
          })
        );
        commit("SET_USER_DATA", res.user);
        commit("SET_TOKEN", res);
        commit("SET_LOADING", false);
        router.push({ name: routeNames.horses, params: { page: 0 } });
      })
      .catch((err) => {
        if (err.message === "Firebase: Error (auth/email-already-in-use).") {
          toast.error(
            i18n.global.t("auth.An account with this email already exists")
          );
        } else {
          toast.error(err.message);
        }
        commit("SET_LOADING", false);

        throw err;
      });
  },

  async loginWithSocial({ commit }, social) {
    let provider;
    if (social === "twitter") provider = new TwitterAuthProvider();
    else if (social === "google") provider = new GoogleAuthProvider();
    else if (social === "facebook") provider = new FacebookAuthProvider();

    await signInWithPopup(auth, provider)
      .then((res) => {
        localStorage.setItem(
          "horsecare-userTokens",
          JSON.stringify({
            token: res._tokenResponse.idToken,
            refreshToken: res._tokenResponse.refreshToken,
          })
        );
        commit("SET_USER_DATA", res.user);
        commit("SET_TOKEN", res);
        router.push({ name: routeNames.horses, params: { page: 0 } });
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },

  async forgotPassword({ commit }, email) {
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Password reset email sent, check your inbox");
        commit("SET_LOADING", false);
        router.push({ name: routeNames.login });
      })
      .catch((err) => {
        commit("SET_LOADING", false);
        toast.error(err.message);
        throw err;
      });
  },

  async logout() {
    await signOut(auth)
      .then(() => {
        localStorage.removeItem("horsecare-userTokens");
        router.push({ name: routeNames.login });
      })
      .catch((err) => {
        toast.error(err.message);
        throw err;
      });
  },
};

export default actions;

<template>
  <div
    class="w-full mx-auto mt-6 flex flex-col md:flex-row flex-wrap md:flex-nowrap justify-between items-center"
  >
    <div class="w-full h-8">
      <Slider
        class="w-full h-full cursor-pointer"
        showTooltip="always"
        tooltipPosition="left"
        v-model="currentTrackIndex"
        :format="format"
        :max="trackingMarkersLength"
        @change="changeTrackingLine"
        @slide="changeTrackingLine"
        @mouseover="setTimeline(true)"
        @mouseleave="setTimeline(false)"
      />
      <div class="w-full mt-[1px] mb-4 flex justify-start">
        <div class="w-[100%] h-[42px] relative">
          <div
            class="block absolute left-[calc(0%_+_1px)] top-[-3px] bg-black w-[1px] h-[10px]"
          ></div>
          <div
            class="block absolute left-[calc(0%_-_19px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(0) }}
          </div>
          <div
            class="hidden lg:block absolute left-[2.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[5%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[7.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(10%_-_20px)] top-[11px] md:top-[11px] text-[11px]"
          >
            {{ setTimelineTime(10) }}
          </div>
          <div
            class="hidden md:block absolute left-[10%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[12.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[15%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[17.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(20%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(20) }}
          </div>
          <div
            class="hidden lg:block absolute left-[20%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[22.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[25%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[27.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(30%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(30) }}
          </div>
          <div
            class="hidden lg:block absolute left-[30%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[32.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[35%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[37.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(40%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(40) }}
          </div>
          <div
            class="hidden lg:block absolute left-[40%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[42.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[45%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[47.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="absolute left-[calc(50%_-_20px)] top-[8px] md:top-[11px] text-[11px]"
          >
            {{ setTimelineTime(50) }}
          </div>
          <div
            class="absolute left-[50%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[52.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[55%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[57.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(60%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(60) }}
          </div>
          <div
            class="hidden lg:block absolute left-[60%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[62.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[65%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[67.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(70%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(70) }}
          </div>
          <div
            class="hidden lg:block absolute left-[70%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[72.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[75%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[77.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(80%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(80) }}
          </div>
          <div
            class="hidden lg:block absolute left-[80%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[82.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[85%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[87.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(90%_-_20px)] top-[11px] md:top-[11px] text-[11px]"
          >
            {{ setTimelineTime(90) }}
          </div>
          <div
            class="hidden lg:block absolute left-[90%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[92.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[95%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[97.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="block absolute left-[calc(100%_-_1px)] top-[-3px] bg-black w-[1px] h-[10px]"
          ></div>
          <div
            class="block absolute left-[calc(100%_-_19px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(100) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TrackingSlider",
  components: { Slider },
  props: {
    trackingMarkersLength: Number,
    cutValue: Array,
  },
  computed: {
    ...mapGetters([
      "currentTrackIndex",
      "isTrackStarted",
      "isTrackPaused",
      "isAnimationModalOpen",
    ]),
  },
  watch: {
    trackingMarkersLength() {
      this.SET_CURRENT_TRACK_INDEX(0);
    },
  },
  methods: {
    ...mapMutations([
      "SET_CURRENT_TRACK_INDEX",
      "SET_IS_TRACK_STARTED",
      "SET_IS_TRACK_PAUSED",
      "SET_IS_FRAME_UPDATED_OUTSIDE_ANIMATION",
    ]),
    setTimeline(val) {
      if (!this.isTrackStarted) return;
      this.SET_IS_TRACK_PAUSED(val);
    },
    setTimelineTime(percent) {
      let index = 0;

      if (this.cutValue) {
        // Adjust for cutValue and step size (0.2 seconds = 200ms)
        index =
          this.cutValue[0] * 200 +
          (this.trackingMarkersLength / 100) * percent * 200;
      } else {
        index = (this.trackingMarkersLength / 100) * percent * 200;
      }

      // Format time with tenths of a second
      const timeValue = new Date(index);
      const hours = String(timeValue.getUTCHours()).padStart(2, "0");
      const minutes = String(timeValue.getUTCMinutes()).padStart(2, "0");
      const seconds = String(timeValue.getUTCSeconds()).padStart(2, "0");
      const milliseconds = String(
        Math.floor(timeValue.getUTCMilliseconds() / 100)
      );

      return `${hours}:${minutes}:${seconds}.${milliseconds}`;
    },
    format() {
      let offset = 0;
      if (this.cutValue) {
        offset = this.cutValue[0] * 200; // Convert start time to milliseconds
      }
      const timeValue = new Date(this.currentTrackIndex * 200 + offset); // Apply offset

      // Extract hours, minutes, seconds, and milliseconds
      const hours = String(timeValue.getUTCHours()).padStart(2, "0");
      const minutes = String(timeValue.getUTCMinutes()).padStart(2, "0");
      const seconds = String(timeValue.getUTCSeconds()).padStart(2, "0");
      const milliseconds = String(
        Math.floor(timeValue.getUTCMilliseconds() / 100) // Divide by 100 to get tenths
      );

      // Return formatted time in HH:MM:SS.S
      return `${hours}:${minutes}:${seconds}.${milliseconds}`;
    },

    changeTrackingLine(val) {
      if (this.isAnimationModalOpen) {
        this.SET_IS_FRAME_UPDATED_OUTSIDE_ANIMATION(true);
      }
      if (this.trackingMarkersLength > val) {
        this.SET_CURRENT_TRACK_INDEX(val);
      } else {
        this.SET_IS_TRACK_STARTED(false);
        this.SET_IS_TRACK_PAUSED(true);
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../../../../../node_modules/@vueform/slider/themes/tailwind";
</style>

<template>
  <button
    class="absolute top-[calc(50%_-_24px)] left-0 md:left-4 rounded-md px-3 py-2 lg:mt-0 text-lg font-semibold text-[#9A8053] flex items-center"
    @click="handleGoBack"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6 md:w-8 md:h-8"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
      />
    </svg>

    <span class="ml-1">{{ $t("back") }}</span>
  </button>
</template>
<script>
import { mapMutations } from "vuex";

export default {
  name: "BackButton",
  props: {
    routeToBack: String,
  },
  methods: {
    ...mapMutations(["SET_REPORTS_DATA", "SET_HORSE_LAMENESS"]),
    async handleGoBack() {
      await this.SET_REPORTS_DATA(null);
      await this.SET_HORSE_LAMENESS(null);
      this.routeToBack
        ? this.$router.push(this.routeToBack)
        : this.$router.go(-1);
    },
  },
};
</script>

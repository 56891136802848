<template>
  <div class="tabs w-full mt-2 ml-2">
    <div class="block">
      <nav
        class="isolate flex flex-col md:flex-row flex-wrap divide-x divide-gray-200 rounded-lg shadow"
        aria-label="Tabs"
      >
        <button
          v-for="(tab, tabIdx) in tabs"
          :key="tab.name"
          :class="[
            tabIdx === 0 ? 'rounded-l-lg' : '',
            tab.isEnabled ? '' : 'hidden',
            tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
            'group relative w-[300px] flex-1 overflow-hidden bg-white py-4 px-4 text-center hover:bg-gray-50 focus:z-10 text-[#5A5A5F] text-[18px] font-semibold',
          ]"
          :aria-current="tab.current ? 'page' : undefined"
          @click="handleSwitchTab(tabIdx)"
        >
          <span>{{ $t(tab.name) }}</span>
          <span
            aria-hidden="true"
            :class="[
              tab.current ? 'bg-[#9A8053]' : 'bg-indigo-200',
              'absolute inset-x-0 bottom-0 h-0.5',
            ]"
          />
        </button>
      </nav>
    </div>

    <div v-show="currentTabIdx === 0">
      <ExpenditureEnergyComponent
        v-if="reports && reports.EXPENDITURE_ENERGY_METRICS"
        :expenditureEnergy="
          reports.EXPENDITURE_ENERGY_METRICS.raw
            ? reports.EXPENDITURE_ENERGY_METRICS.raw
            : reports.EXPENDITURE_ENERGY_METRICS
        "
      />
    </div>

    <div v-show="currentTabIdx === 1">
      <StiffnessPowerComponent
        v-if="
          selectedChartsList.includes('Stiffness power') &&
          reports.STIFFNESS_POWER_DISTRIBUTION
        "
        :datasets="reports.STIFFNESS_POWER_DISTRIBUTION"
        :title="$t('charts-selector.Stiffness power')"
      />
    </div>

    <div v-show="currentTabIdx === 2">
      <BarChart
        v-if="
          selectedChartsList.includes('Activity') &&
          reports.STIFFNESS_METRICS.raw
            ? reports.STIFFNESS_METRICS.raw
            : reports.STIFFNESS_METRICS
        "
        :title="$t('charts-selector.Activity')"
        :description="selectedDayTrainingsMetadata.STIFFNESS_METRICS"
        type="table"
        :date="currentDate"
        :datasets="
          reports.STIFFNESS_METRICS.raw
            ? reports.STIFFNESS_METRICS.raw
            : reports.STIFFNESS_METRICS
        "
      />
    </div>
  </div>
</template>

<script>
import BarChart from "@/modules/training/components/charts/BarChart.vue";
import ExpenditureEnergyComponent from "@/modules/training/components/ExpenditureEnergyComponent.vue";
import StiffnessPowerComponent from "@/modules/training/components/StiffnessPowerComponent.vue";
import { enUS, ru } from "date-fns/locale";
import { format } from "date-fns";
import { mapGetters } from "vuex";

export default {
  name: "TabCharts",
  components: {
    StiffnessPowerComponent,
    ExpenditureEnergyComponent,
    BarChart,
  },
  data() {
    return {
      currentTabIdx: 0,
      index: 0,
    };
  },
  computed: {
    ...mapGetters([
      "reports",
      "selectedChartsList",
      "selectedDayTrainingsMetadata",
    ]),
    tabs() {
      return [
        {
          name: "tracking.Expenditure energy",
          href: "#",
          current: this.currentTabIdx === 0,
          isEnabled: true,
        },
        {
          name: "charts-selector.Stiffness power",
          href: "#",
          current: this.currentTabIdx === 1,
          isEnabled: this.selectedChartsList.includes("Stiffness power"),
        },
        {
          name: "charts-selector.Activity",
          href: "#",
          current: this.currentTabIdx === 2,
          isEnabled: this.selectedChartsList.includes("Activity"),
        },
      ];
    },
    currentDate() {
      const locale = this.$i18n.locale === "Ru" ? ru : enUS;
      const date = this.selectedDayTrainingsMetadata.date
        ? new Date(this.selectedDayTrainingsMetadata.date)
        : new Date();
      return format(date, "dd MMM yyyy", { locale: locale });
    },
  },
  methods: {
    handleSwitchTab(index) {
      this.tabs.map((i) => (i.current = false));
      this.tabs[index].current = true;
      this.currentTabIdx = index;
    },
  },
};
</script>

<style lang="scss" scoped></style>

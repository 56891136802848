<template>
  <HomeLayout />
</template>
<script>
import HomeLayout from "@/shared/layouts/HomeLayout.vue";

export default {
  name: "HomePage",
  components: {
    HomeLayout,
  },
};
</script>

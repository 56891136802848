import routeNames from "@/router/routes";

import TrainingView from "@/modules/training/views/TrainingView.vue";
import TrackingView from "@/views/training/TrackingView.vue";
import TrainingsComparisonView from "@/views/training/TrainingsComparisonView.vue";

const trainingRoutes = [
  {
    path: "/:page/horse/:id/training/:trainingId?",
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: routeNames.training,
        component: TrainingView,
      },
      {
        name: "tracking",
        component: TrackingView,
        path: routeNames.tracking,
      },
      {
        name: "comparison",
        component: TrainingsComparisonView,
        path: routeNames.comparison,
      },
    ],
  },
];

export default trainingRoutes;

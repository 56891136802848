<template>
  <div
    class="w-full mx-auto p-4 flex justify-between flex-wrap bg-white shadow rounded-lg"
  >
    <p class="w-full text-[#5A5A5F] text-[21px] font-semibold text-center mb-4">
      {{ title }}
    </p>
    <div class="w-full flex flex-wrap justify-center items-center">
      <div
        v-for="(i, index) of filteredData"
        :key="i + index"
        class="w-full flex flex-wrap md:flex-nowrap justify-center items-center"
      >
        <div v-for="(i, index) of i.data" :key="i + index" class="m-4 w-[29%]">
          <p
            class="w-full text-[#5A5A5F] text-[16px] font-poppins font-semibold text-center"
          >
            {{ $t("horse." + i.leg) + "/" + $t("tracking." + i.movement) }}
          </p>
          <p
            class="w-full text-[#5A5A5F] text-[16px] font-poppins font-semibold text-center"
          >
            {{
              $t("tracking." + i.skewnessTitle) + ":" + " " + i.skewnessValue
            }}
          </p>
          <p
            class="w-full text-[#5A5A5F] text-[16px] font-poppins font-semibold text-center mb-4"
          >
            {{
              $t("tracking." + i.maxValueTitle) + ":" + " " + i.maxValueValue
            }}
          </p>
          <Bar :data="i.chartData" :options="options" />
          <div
            v-if="comparisonDatasets"
            class="flex justify-center items-center"
          >
            <div
              class="flex justify-center items-center text-[#5A5A5F] text-[16px font-poppins] font-semibold mr-1"
            >
              First:
              <div
                class="w-[10px] h-[10px] mx-1 rounded-full"
                :class="getClass(i.fromColor)"
              ></div>
            </div>
            <div
              class="flex justify-center items-center text-[#5A5A5F] text-[16px] font-poppins font-semibold ml-1"
            >
              Second:
              <div
                class="w-[10px] h-[10px] mx-1 rounded-full"
                :class="getClass(i.toColor)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full flex flex-wrap justify-between mt-4">
      <div class="max-w-[450px] flex justify-between items-center">
        <div
          v-for="(item, index) of legs"
          :key="index"
          class="flex items-center ml-4"
        >
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            :id="item.title + title"
            v-model="item.checked"
          />
          <span
            class="w-[10px] h-[10px] mx-1 rounded-full"
            :class="getClass(item.backgroundColor)"
          ></span>
          <label
            :for="item.title + title"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer"
          >
            <span>{{ $t("horse." + item.title) }}</span>
          </label>
        </div>
      </div>
      <div class="max-w-[450px] flex justify-between items-center">
        <div
          v-for="(item, index) of movements"
          :key="index"
          class="flex items-center ml-4"
        >
          <input
            type="checkbox"
            class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
            :id="item.title + title"
            v-model="item.checked"
          />
          <label
            :for="item.title + title"
            class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
          >
            <span>{{ $t("tracking." + item.title) }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale,
} from "chart.js";

import { getClass } from "@/modules/training/helpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  TimeScale
);
export default {
  name: "StiffnessPowerComponent",
  props: {
    title: String,
    datasets: Array,
    comparisonDatasets: Array,
  },
  components: {
    Bar,
  },
  data() {
    return {
      data: [],
      movements: [
        { title: "Walk", checked: true },
        { title: "Trot", checked: true },
        { title: "Gallop", checked: true },
      ],
      legs: [
        { title: "FL", backgroundColor: "#6A9A53", checked: true },
        { title: "FR", backgroundColor: "#E0712F", checked: true },
        { title: "HL", backgroundColor: "#587DC0", checked: true },
        { title: "HR", backgroundColor: "#FFCD4B", checked: true },
      ],
    };
  },
  mounted() {
    if (this.datasets) this.setDataProperties();
  },
  computed: {
    options() {
      return {
        animation: false,
        spanGaps: true,
        decimation: true,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          crosshair: false,
          datalabels: false,
        },
        scales: {
          x: {
            ticks: {
              callback: function (value, index) {
                return index;
              },
            },
          },
          y: {
            max: this.maxStiffnessValue,
          },
        },
      };
    },
    maxStiffnessValue() {
      const mappedStiffnessValues = [];
      this.data.map((l) => {
        l.data.map((t) =>
          mappedStiffnessValues.push(...t.chartData.datasets[0].data)
        );
      });

      return mappedStiffnessValues.length
        ? Math.max(...mappedStiffnessValues)
        : 0;
    },
    filteredData() {
      const filteredMovements = this.movements
        .filter((i) => i.checked)
        .map((i) => i.title);
      const filteredLegs = this.legs
        .filter((i) => i.checked)
        .map((i) => i.title);

      const c = this.data.map((i) => {
        return {
          title: i.title,
          data: i.data.filter((t) => filteredMovements.includes(t.movement)),
        };
      });

      return c.filter((i) => filteredLegs.includes(i.title));
    },
  },
  methods: {
    getClass,
    setDataProperty(datasets, leg, movement, title, color) {
      return {
        title: title,
        skewnessTitle: "skewness",
        skewnessValue: this.setSkewnessValue(datasets),
        maxValueTitle: "Max value",
        maxValueValue: datasets.hist.length ? Math.max(...datasets.hist) : 0,
        movement: movement,
        leg: leg,
        chartData: {
          labels: datasets.bin_edges.length
            ? [...datasets.bin_edges.map((i) => i.toFixed(0))]
            : [],
          datasets: [
            {
              backgroundColor: color,
              barThickness: 3,
              data: [0, ...datasets.hist],
            },
          ],
        },
      };
    },
    setComparisonDataProperty(
      datasets,
      comparisonDatasets,
      leg,
      movement,
      title,
      color,
      comparisonColor
    ) {
      return {
        title: title,
        fromColor: color,
        toColor: comparisonColor,
        skewness: `skewness: ${this.setSkewnessValue(
          datasets
        )} - ${this.setSkewnessValue(comparisonDatasets)}`,
        maxValue: `max: ${Math.max(...datasets.hist)} - ${Math.max(
          ...comparisonDatasets.hist
        )}`,
        movement: movement,
        leg: leg,
        chartData: {
          labels:
            datasets.bin_edges.length && comparisonDatasets.bin_edges
              ? [
                  ...datasets.bin_edges.map((i) => i.toFixed(0)),
                  ...comparisonDatasets.bin_edges.map((i) => i.toFixed(0)),
                ]
              : [],
          datasets: [
            {
              backgroundColor: color,
              barThickness: 3,
              data: [0, ...datasets.hist],
            },
            {
              backgroundColor: comparisonColor,
              barThickness: 3,
              data: [0, ...comparisonDatasets.hist],
            },
          ],
        },
      };
    },
    setSkewnessValue(val) {
      if (!val.skewness) return "0";
      return isNaN(val.skewness) ? "0" : val.skewness.toFixed(2);
    },
    setDataProperties() {
      this.data = [
        {
          title: "FL",
          data: this.comparisonDatasets
            ? [
                this.setComparisonDataProperty(
                  this.datasets?.walk.FL,
                  this.comparisonDatasets?.walk.FL,
                  "FL",
                  "Walk",
                  `FL/Walk`,
                  "#6A9A53",
                  "#7CFC00"
                ),
                this.setComparisonDataProperty(
                  this.datasets?.trot.FL,
                  this.comparisonDatasets?.trot.FL,
                  "FL",
                  "Trot",
                  `FL/Trot`,
                  "#6A9A53",
                  "#7CFC00"
                ),
                this.setComparisonDataProperty(
                  this.datasets?.gallop.FL,
                  this.comparisonDatasets?.gallop.FL,
                  "FL",
                  "Gallop",
                  `FL/Gallop`,
                  "#6A9A53",
                  "#7CFC00"
                ),
              ]
            : [
                this.setDataProperty(
                  this.datasets?.walk.FL,
                  "FL",
                  "Walk",
                  `FL/Walk`,
                  "#6A9A53"
                ),
                this.setDataProperty(
                  this.datasets?.trot.FL,
                  "FL",
                  "Trot",
                  `FL/Trot`,
                  "#6A9A53"
                ),
                this.setDataProperty(
                  this.datasets?.gallop.FL,
                  "FL",
                  "Gallop",
                  `FL/Gallop`,
                  "#6A9A53"
                ),
              ],
        },
        {
          title: "FR",
          data: this.comparisonDatasets
            ? [
                this.setComparisonDataProperty(
                  this.datasets?.walk.FR,
                  this.comparisonDatasets?.walk.FR,
                  "FR",
                  "Walk",
                  `FR/Walk`,
                  "#E0712F",
                  "#880808"
                ),
                this.setComparisonDataProperty(
                  this.datasets?.trot.FR,
                  this.comparisonDatasets?.trot.FR,
                  "FR",
                  "Trot",
                  `FR/Trot`,
                  "#E0712F",
                  "#880808"
                ),
                this.setComparisonDataProperty(
                  this.datasets?.gallop.FR,
                  this.comparisonDatasets?.gallop.FR,
                  "FR",
                  "Gallop",
                  `FR/Gallop`,
                  "#E0712F",
                  "#880808"
                ),
              ]
            : [
                this.setDataProperty(
                  this.datasets?.walk.FR,
                  "FR",
                  "Walk",
                  `FR/Walk`,
                  "#E0712F"
                ),
                this.setDataProperty(
                  this.datasets?.trot.FR,
                  "FR",
                  "Trot",
                  `FR/Trot`,
                  "#E0712F"
                ),
                this.setDataProperty(
                  this.datasets?.gallop.FR,
                  "FR",
                  "Gallop",
                  `FR/Gallop`,
                  "#E0712F"
                ),
              ],
        },
        {
          title: "HL",
          data: this.comparisonDatasets
            ? [
                this.setComparisonDataProperty(
                  this.datasets?.walk.BL,
                  this.comparisonDatasets?.walk.BL,
                  "HL",
                  "Walk",
                  `HL/Walk`,
                  "#587DC0",
                  "#0000CD"
                ),
                this.setComparisonDataProperty(
                  this.datasets?.trot.BL,
                  this.comparisonDatasets?.trot.BL,
                  "HL",
                  "Trot",
                  `HL/Trot`,
                  "#587DC0",
                  "#0000CD"
                ),
                this.setComparisonDataProperty(
                  this.datasets?.gallop.BL,
                  this.comparisonDatasets?.gallop.BL,
                  "HL",
                  "Gallop",
                  `HL/Gallop`,
                  "#587DC0",
                  "#0000CD"
                ),
              ]
            : [
                this.setDataProperty(
                  this.datasets?.walk.BL,
                  "HL",
                  "Walk",
                  `HL/Walk`,
                  "#587DC0"
                ),
                this.setDataProperty(
                  this.datasets?.trot.BL,
                  "HL",
                  "Trot",
                  `HL/Trot`,
                  "#587DC0"
                ),
                this.setDataProperty(
                  this.datasets?.gallop.BL,
                  "HL",
                  "Gallop",
                  `HL/Gallop`,
                  "#587DC0"
                ),
              ],
        },
        {
          title: "HR",
          data: this.comparisonDatasets
            ? [
                this.setComparisonDataProperty(
                  this.datasets?.walk.BR,
                  this.comparisonDatasets?.walk.BR,
                  "HR",
                  "Walk",
                  `HR/Walk`,
                  "#FFCD4B",
                  "#d58800"
                ),
                this.setComparisonDataProperty(
                  this.datasets?.trot.BR,
                  this.comparisonDatasets?.trot.BR,
                  "HR",
                  "Trot",
                  `HR/Trot`,
                  "#FFCD4B",
                  "#d58800"
                ),
                this.setComparisonDataProperty(
                  this.datasets?.gallop.BR,
                  this.comparisonDatasets?.gallop.BR,
                  "HR",
                  "Gallop",
                  `HR/Gallop`,
                  "#FFCD4B",
                  "#d58800"
                ),
              ]
            : [
                this.setDataProperty(
                  this.datasets?.walk.BR,
                  "HR",
                  "Walk",
                  `HR/Walk`,
                  "#FFCD4B"
                ),
                this.setDataProperty(
                  this.datasets?.trot.BR,
                  "HR",
                  "Trot",
                  `HR/Trot`,
                  "#FFCD4B"
                ),
                this.setDataProperty(
                  this.datasets?.gallop.BR,
                  "HR",
                  "Gallop",
                  `HR/Gallop`,
                  "#FFCD4B"
                ),
              ],
        },
      ];
    },
  },
};
</script>

<template>
  <div class="flex justify-center items-center mt-6">
    <div class="w-full h-8">
      <Slider
        class="w-full h-full cursor-pointer"
        :merge="merge"
        showTooltip="drag"
        :format="format"
        v-model="cutValue"
        :max="trackingLength"
        @slide="changeCutValue"
        @change="changeCutValue"
      />
      <div class="w-full mt-[1px] mb-4 flex justify-start">
        <div class="w-[100%] h-[42px] relative">
          <div
            class="block absolute left-[calc(0%_+_1px)] top-[-3px] bg-black w-[1px] h-[10px]"
          ></div>
          <div
            class="block absolute left-[calc(0%_-_19px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(0) }}
          </div>
          <div
            class="hidden lg:block absolute left-[2.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[5%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[7.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(10%_-_20px)] top-[11px] md:top-[11px] text-[11px]"
          >
            {{ setTimelineTime(10) }}
          </div>
          <div
            class="hidden md:block absolute left-[10%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[12.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[15%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[17.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(20%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(20) }}
          </div>
          <div
            class="hidden lg:block absolute left-[20%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[22.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[25%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[27.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(30%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(30) }}
          </div>
          <div
            class="hidden lg:block absolute left-[30%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[32.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[35%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[37.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(40%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(40) }}
          </div>
          <div
            class="hidden lg:block absolute left-[40%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[42.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[45%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[47.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="absolute left-[calc(50%_-_20px)] top-[8px] md:top-[11px] text-[11px]"
          >
            {{ setTimelineTime(50) }}
          </div>
          <div
            class="absolute left-[50%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[52.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[55%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[57.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(60%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(60) }}
          </div>
          <div
            class="hidden lg:block absolute left-[60%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[62.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[65%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[67.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(70%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(70) }}
          </div>
          <div
            class="hidden lg:block absolute left-[70%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[72.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[75%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[77.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(80%_-_20px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(80) }}
          </div>
          <div
            class="hidden lg:block absolute left-[80%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[82.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[85%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[87.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[calc(90%_-_20px)] top-[11px] md:top-[11px] text-[11px]"
          >
            {{ setTimelineTime(90) }}
          </div>
          <div
            class="hidden lg:block absolute left-[90%] top-0 bg-black w-[1px] h-[10px]"
          ></div>

          <div
            class="hidden lg:block absolute left-[92.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[95%] top-0 bg-black w-[1px] h-[5px]"
          ></div>
          <div
            class="hidden lg:block absolute left-[97.5%] top-0 bg-black w-[1px] h-[2px]"
          ></div>
          <div
            class="block absolute left-[calc(100%_-_1px)] top-[-3px] bg-black w-[1px] h-[10px]"
          ></div>
          <div
            class="block absolute left-[calc(100%_-_19px)] top-[11px] text-[11px]"
          >
            {{ setTimelineTime(100) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";

export default {
  name: "CutTrainingSlider",
  components: {
    Slider,
  },
  props: {
    trackingMarkersLength: Number,
  },
  data() {
    return {
      merge: 1000,
      cutValue: [0, 0],
      cutIndexes: [0, 0],
    };
  },
  mounted() {
    this.cutValue[1] = this.trackingMarkersLength;
  },
  computed: {
    trackingLength() {
      return this.trackingMarkersLength ? this.trackingMarkersLength : 0;
    },
  },
  methods: {
    format(value) {
      return new Date(value * 200).toISOString().slice(11, 19);
    },
    setTimelineTime(percent) {
      const index = Math.ceil((this.trackingMarkersLength / 100) * percent);
      return this.format(index);
    },
    changeCutValue(val) {
      this.cutIndexes = val;
      this.$emit("changeCutValue", this.cutIndexes);
    },
  },
};
</script>

import routeNames from "@/router/routes";

const authRoutes = [
  {
    path: "/registration",
    name: routeNames.registration,
    component: () => import("../views/RegistrationView.vue"),
  },
  {
    path: "/login",
    name: routeNames.login,
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/forgot-password",
    name: routeNames.forgotPassword,
    component: () => import("../views/ForgotPasswordView.vue"),
  },
  {
    path: "/account-blocked",
    name: routeNames.blockedAccount,
    component: () => import("../views/BlockedAccountView.vue"),
  },
];

export default authRoutes;

const state = {
  selectedTrainingDate: null,
  selectedTrainingTimeData: null,

  isHorseDontHaveTrainings: false,
  selectedDayTrainings: [],
  currentTrainingMetadata: null,
  selectedDayTrainingsMetadata: {},
  reports: null,
  trackingPaused: true,
  selectedChartsList: [],
  trainingDates: [],
  horseLameness: null,
  horseLamenessSettings: null,

  compareTrainingFrom: null,
  compareTrainingTo: null,

  selectedCompareTrainingFrom: null,
  selectedCompareTrainingTo: null,

  reportsCompareTrainingFrom: null,
  reportsCompareTrainingTo: null,
};

export default state;

const getters = {
  selectedTrainingTimeData: (state) => state.selectedTrainingTimeData,
  selectedTrainingDate: (state) => state.selectedTrainingDate,

  isHorseDontHaveTrainings: (state) => state.isHorseDontHaveTrainings,
  selectedDayTrainings: (state) => state.selectedDayTrainings,
  currentTrainingMetadata: (state) => state.currentTrainingMetadata,
  selectedDayTrainingsMetadata: (state) => state.selectedDayTrainingsMetadata,
  reports: (state) => state.reports,
  chartsData: (state) => state.chartsData,
  selectedChartsList: (state) => state.selectedChartsList,
  trainingDates: (state) => state.trainingDates,
  horseLameness: (state) => state.horseLameness,
  horseLamenessSettings: (state) => state.horseLamenessSettings,

  compareTrainingFrom: (state) => state.compareTrainingFrom,
  compareTrainingTo: (state) => state.compareTrainingTo,

  selectedCompareTrainingFrom: (state) => state.selectedCompareTrainingFrom,
  selectedCompareTrainingTo: (state) => state.selectedCompareTrainingTo,

  reportsCompareTrainingFrom: (state) => state.reportsCompareTrainingFrom,
  reportsCompareTrainingTo: (state) => state.reportsCompareTrainingTo,
};

export default getters;

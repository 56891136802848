<template>
  <HomeLayout>
    <div class="h-full flex justify-center items-center">
      Something went wrong...
    </div>
  </HomeLayout>
</template>

<script>
import HomeLayout from "@/shared/layouts/HomeLayout.vue";

export default {
  name: "ErrorPageView",
  components: {
    HomeLayout,
  },
};
</script>

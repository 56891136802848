<template>
  <div class="doughnuts-container">
    <DoughnutChart
      v-for="chart in charts"
      v-bind="chart.props"
      :key="`chart-${chart.props.id}`"
      :class="getChartClass(chart)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DoughnutChart from "@/modules/training/components/charts/DoughnutChart.vue";
import { convertSecondsToTs } from "@/modules/training/helpers";
import { allures, trackingModeKeys } from "@/modules/training/constants";

export default {
  name: "DoughnutsList",
  components: {
    DoughnutChart,
  },
  data() {
    return {
      chartIds: {
        distance: "distance-chart",
        energy: "energy-chart",
        duration: "duration-chart",
        power: "power-chart",
        rhythm: "rhythm-chart",
      },
    };
  },
  computed: {
    ...mapGetters(["reports", "GPSDatafiles", "HRDatafiles", "trackingMode"]),

    // Select distance metric (GPS or ML)
    activeDistanceMetricKey() {
      if (
        this.trackingMode === trackingModeKeys.GPS &&
        this.GPSDatafiles &&
        this.reports?.by_allures_training_distance_GPS?.walk
      ) {
        return "by_allures_training_distance_GPS";
      } else if (this.reports?.by_allures_training_distance_ML?.walk) {
        return "by_allures_training_distance_ML";
      }
      return null;
    },

    distanceChartData() {
      const key = this.activeDistanceMetricKey;
      return this.activeDistanceMetricKey
        ? this.generateDistanceChartData(key)
        : [];
    },

    // Total distance based on mode
    totalDistance() {
      if (this.GPSDatafiles && this.reports?.total_distance_gps_meters) {
        return this.trackingMode === trackingModeKeys.GPS
          ? Math.ceil(this.reports.total_distance_gps_meters)
          : Math.ceil(this.reports.total_distance_ml_meters);
      } else if (this.reports?.TRACKING?.ts) {
        return this.reports.total_distance_ml_meters;
      }
      return "-";
    },

    // Energy Expended chart data
    energyChartData() {
      const { by_allures_energy_expended } = this.reports || {};
      if (!by_allures_energy_expended) return [];

      const totalEnergy =
        parseFloat((this.reports.total_energy_expended / 1000).toFixed(2)) || 0;

      return allures.map((allure) => {
        const energy =
          parseFloat((by_allures_energy_expended[allure] / 1000).toFixed(2)) ||
          0;
        return {
          title: this.capitalizeFirstLetter(allure),
          percent: this.calculatePercent(energy, totalEnergy),
          data: energy,
        };
      });
    },

    // Training Duration chart data
    durationChartData() {
      if (!this.reports?.by_allures_steps_count) return [];

      const trainingDurationKey = `by_allures_training_duration_${this.trackingMode}`;
      const totalDurationKey = `total_training_duration_${this.trackingMode}`;
      const durationTypes = [...allures, "stay"];

      return durationTypes.map((allure) => {
        const duration = this.reports[trainingDurationKey]?.[allure] || 0;
        const totalDuration = this.reports[totalDurationKey] || 0;
        return {
          title: this.capitalizeFirstLetter(allure),
          percent: this.calculatePercent(duration, totalDuration),
          data: convertSecondsToTs(duration),
        };
      });
    },

    // Power Expended chart data
    powerChartData() {
      if (!this.reports?.total_sum_stiffness_power) return [];

      const powerByAllure = this.reports.by_allures_sum_stiffness_power || {};
      const totalPower = this.reports.total_sum_stiffness_power / 1000 || 0;

      return allures.map((allure) => {
        const power = (powerByAllure[allure] || 0) / 1000;
        return {
          title: this.capitalizeFirstLetter(allure),
          percent: this.calculatePercent(power, totalPower),
          data: Math.round(power),
        };
      });
    },

    // Rhythm Deviation chart data
    rhythmDeviationChartData() {
      if (!this.reports?.total_average_rhythm_deviation) return [];

      const deviationByAllure =
        this.reports.by_allures_average_rhythm_deviation || {};
      const sum =
        Object.values(deviationByAllure).reduce((a, b) => a + b, 0) || 1;

      return allures.map((allure) => {
        const deviation = deviationByAllure[allure] || 0;
        return {
          title: this.capitalizeFirstLetter(allure),
          percent: this.calculatePercent(deviation, sum),
          data: deviation.toFixed(2),
        };
      });
    },

    // Comparative data for charts
    trainingDuration() {
      if (!this.reports?.training_duration_seconds) return "00:00:00";

      const duration =
        this.reports[`total_training_duration_${this.trackingMode}`] || 0;
      return convertSecondsToTs(duration);
    },

    allPowerCost() {
      return Math.round((this.reports?.total_sum_stiffness_power || 0) / 1000);
    },

    allEnergyCost() {
      return this.reports?.total_energy_expended
        ? (this.reports.total_energy_expended / 1000).toFixed(2)
        : 0;
    },

    rhythmDeviation() {
      return this.reports?.total_average_rhythm_deviation
        ? this.reports.total_average_rhythm_deviation.toFixed(2)
        : "-";
    },

    // Chart configurations array
    charts() {
      return [
        {
          class: "chart",
          props: {
            id: this.chartIds.distance,
            title: this.$t("training.Training distance"),
            type: this.$t("training.Meters"),
            data: this.distanceChartData,
            isEmpty: !this.distanceChartData.length,
            comparedData: this.totalDistance,
          },
        },
        {
          class: "chart",
          props: {
            id: this.chartIds.energy,
            title: this.$t("training.Energy expended"),
            type: this.$t("training.kJ"),
            data: this.energyChartData,
            isEmpty: !this.energyChartData.length,
            comparedData: this.allEnergyCost,
          },
        },
        {
          class: "chart",
          props: {
            id: this.chartIds.duration,
            title: this.$t("training.Training duration"),
            type: this.$t("training.min"),
            data: this.durationChartData,
            isEmpty: !this.durationChartData.length,
            comparedData: this.trainingDuration,
          },
        },
        {
          class: "chart",
          props: {
            id: this.chartIds.power,
            title: this.$t("training.Power expended"),
            type: this.$t("training.kWatts"),
            data: this.powerChartData,
            isEmpty: !this.powerChartData.length,
            comparedData: this.allPowerCost,
          },
        },
        {
          class: "chart",
          props: {
            id: this.chartIds.rhythm,
            title: this.$t("training.Rhythm deviation"),
            type: this.$t("training.Milliseconds"),
            data: this.rhythmDeviationChartData,
            isEmpty: !this.rhythmDeviationChartData.length,
            comparedData: this.rhythmDeviation,
          },
        },
      ];
    },
  },
  methods: {
    // Get chart class based on chart type and screen size
    getChartClass(chart) {
      return `chart-wrapper ${chart.class}`;
    },

    // Generates distance chart data based on selected key (GPS or ML)
    generateDistanceChartData(metricKey) {
      const distanceData = this.reports[metricKey] || {};
      const totalDistance = this.totalDistance === "-" ? 1 : this.totalDistance;

      return allures.map((allure) => {
        const distance = Math.round(distanceData[allure] || 0);
        return {
          title: this.capitalizeFirstLetter(allure),
          percent: this.calculatePercent(distance, totalDistance),
          data: distance,
        };
      });
    },

    // Calculates percentage
    calculatePercent(value, total) {
      if (!total || !value) return 0;
      return Math.round((value / total) * 100);
    },

    // Capitalizes first letter of string
    capitalizeFirstLetter(string) {
      if (!string) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.doughnuts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  margin-left: 8px;
}

.chart-wrapper {
  margin-bottom: 8px;

  &.chart {
    flex: 1 1 320px;
    min-width: 280px;
    max-width: 100%;
  }

  @media (max-width: 640px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>

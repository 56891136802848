const state = {
  isFrameUpdatedOutsideAnimation: false,
  localZoom: {
    x: { min: 0, max: 0 },
  },
};

const getters = {
  localZoom: (state) => state.localZoom,
  isFrameUpdatedOutsideAnimation: (state) =>
    state.isFrameUpdatedOutsideAnimation,
};

const mutations = {
  SET_MULTICHART_LOCAL_ZOOM(state, data) {
    state.localZoom = data;
  },

  SET_IS_FRAME_UPDATED_OUTSIDE_ANIMATION(state, data) {
    state.isFrameUpdatedOutsideAnimation = data;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div class="w-full">
    <div class="w-200px flex flex-col mt-2 ml-2">
      <span class="text-[#5A5A5F] text-[18px] font-semibold">
        {{ $t("tracking.Horse gait history") }} =
        {{ (historyLength / 5).toFixed(0) }} {{ $t("tracking.seconds") }}
      </span>
      <Slider
        class="w-[250px] h-1 mt-2 mb-4 cursor-pointer"
        :tooltips="false"
        v-model="localHistoryLength"
        :max="1500"
        :min="50"
      />
    </div>

    <div class="flex flex-wrap mt-1">
      <div
        v-for="item of movements"
        :key="item.id"
        class="flex items-center mx-1"
      >
        <input
          type="checkbox"
          class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
          :style="{ color: item.backgroundColor }"
          :id="item.title"
          v-model="item.checked"
        />
        <label
          :for="item.title"
          class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2 font-bold border-gray-700"
        >
          <span>{{ $t(item.title) }}</span>
        </label>
      </div>
    </div>

    <div class="flex mt-4">
      <button
        class="p-2 rounded-md border-2 border-[#9A8053] flex items-center font-semibold text-[#9A8053]"
        @click="$emit('cutTrackingValue')"
      >
        {{ $t("tracking.Crop") }}
      </button>
    </div>
  </div>
</template>

<script>
import Slider from "@vueform/slider";

export default {
  name: "NavigationControls",
  components: {
    Slider,
  },
  props: {
    movements: {
      type: Array,
      required: true,
    },
    historyLength: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:historyLength", "cutTrackingValue"],
  computed: {
    localHistoryLength: {
      get() {
        return this.historyLength;
      },
      set(value) {
        this.$emit("update:historyLength", value);
      },
    },
  },
};
</script>

<template>
  <div class="layout-container">
    <HeaderComponent @emitSwitchSidebar="toggleSidebar" />
    <SidebarComponent :isSidebarOpen="isSidebarOpen" />

    <main
      class="main-content transition-all transition-slowest ease-linear"
      :class="{ 'sidebar-open': isSidebarOpen && isSidebarVisible }"
    >
      <div class="content-wrapper">
        <div class="content-container">
          <slot></slot>
        </div>
      </div>
    </main>

    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "@/shared/components/header/HeaderComponent.vue";
import SidebarComponent from "@/shared/components/sidebar/SidebarComponent.vue";
import FooterComponent from "@/shared/components/FooterComponent.vue";
import routeNames from "@/router/routes";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "HomeLayout",
  components: {
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
  },
  data() {
    return {
      isSidebarOpen: true,
    };
  },
  computed: {
    isSidebarVisible() {
      return [
        routeNames.training,
        routeNames.centerOfGravityTracking,
        routeNames.tracking,
        routeNames.comparison,
      ].includes(this.$route.name);
    },
  },
  methods: {
    ...mapActions(["getSettings"]),
    ...mapMutations(["SET_RANGE_HISTORY"]),

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
  async mounted() {
    await this.getSettings();
    // reset comparing dates
    if (this.$route.name === routeNames.horses) {
      this.SET_RANGE_HISTORY(null);
    }
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}

.main-content {
  max-width: 100%;
  min-height: calc(100vh - 60px);
  padding-top: 60px; /* fixed header */

  &.sidebar-open {
    margin-left: 280px;
    width: calc(100% - 280px);

    @media (max-width: 640px) {
      margin-left: 0;
      width: 100%;
    }
  }

  @media (min-width: 2560px) {
    &.sidebar-open {
      margin-left: 280px;
      width: calc(100% - 280px);
    }
  }
}

.content-wrapper {
  width: 100%;
  min-height: calc(100vh - 120px);
  background-color: #f2f2f8;
  padding: 16px;

  @media (min-width: 768px) {
    padding: 24px;
  }

  @media (min-width: 2560px) {
    padding: 32px;
  }
}

.content-container {
  width: 100%;
  min-height: calc(100vh - 170px);
  border-radius: 8px;
}
</style>

<template>
  <div
    class="w-full px-2 text-[#5A5A5F] text-[18px] font-semibold text-center mb-6 mt-4"
  >
    {{ $t("comparison.Select dates to check history") }}
  </div>
  <div class="mx-2">
    <VDatePicker
      :locale="calendarLocale"
      expanded
      color="brown"
      :attributes="attributes"
      :initial-page="initialCalendarPage"
      v-model.range="range"
      @drag="dragValue = $event"
    />
    <div
      class="w-full h-[24px] mt-2 flex justify-center items-center text-[#5A5A5F] text-[16px]"
    >
      <span v-if="formattedEndTo" class="font-semibold">
        <span class="font-bold mx-1">{{ formattedStartFrom }}</span> -
        <span class="ml-2 font-bold">{{ formattedEndTo }}</span>
      </span>
    </div>
    <div v-if="formattedEndTo">
      <Button @click="handleAccept">{{ $t("comparison.Show history") }}</Button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { format } from "date-fns";
import Button from "@/shared/components/ui/Button.vue";

export default {
  name: "ComparisonCalendar",
  components: { Button },
  data() {
    return {
      range: {
        start: null,
        end: null,
      },
      dragValue: null,
      isDatepickerModalShow: false,
    };
  },
  computed: {
    ...mapGetters(["trainingDates", "rangeHistory"]),
    calendarLocale() {
      return this.$i18n.locale.toLowerCase();
    },
    initialCalendarPage() {
      if (this.range.start) {
        const year = this.range.start.getFullYear();
        const month = this.range.start.getMonth() + 1;
        return {
          year,
          month,
        };
      }

      if (this.trainingDates.length) {
        const year = this.trainingDates[this.trainingDates.length - 1].slice(
          0,
          4
        );
        const month = this.trainingDates[this.trainingDates.length - 1].slice(
          5,
          7
        );
        return {
          month: month,
          year: year,
        };
      } else {
        const date = new Date();
        return {
          month: date.getMonth() + 1,
          year: date.getFullYear(),
        };
      }
    },
    attributes() {
      return [
        {
          highlight: true,
          dates: this.trainingDates,
        },
      ];
    },
    formattedStartFrom() {
      return this.range.start ? format(this.range.start, "dd MMM yyyy") : null;
    },
    formattedEndTo() {
      return this.range.end ? format(this.range.end, "dd MMM yyyy") : null;
    },
  },
  methods: {
    ...mapActions([
      "getSkewnessHistory",
      "getEnergyCoastHistory",
      "getSelectedDayTrainingsData",
    ]),
    ...mapMutations(["SET_LOADING", "SET_RANGE_HISTORY"]),
    async handleAccept() {
      this.mappedHistData = [];
      this.SET_LOADING(true);
      this.isDatepickerModalShow = false;
      if (this.range.start) {
        const props = {
          fromDate: format(this.range.start, "yyyy-MM-dd"),
          toDate: format(this.range.end, "yyyy-MM-dd"),
          horseId: this.$route.params.id,
          id: this.$route.params.id,
        };
        await this.getSkewnessHistory(props);
        await this.getEnergyCoastHistory(props);
        await this.getSelectedDayTrainingsData(props);
        this.SET_RANGE_HISTORY(this.range);
      }
      this.SET_LOADING(false);
    },
  },

  async mounted() {
    if (this.rangeHistory) {
      this.range = this.rangeHistory;
      await this.handleAccept();
    }
  },
};
</script>

<template>
  <BaseModal
    title="Navigation"
    :position="position"
    :z-index="zIndex"
    :min-width="600"
    :min-height="350"
    content-class="relative h-[calc(100%_-_56px)]"
    @activated="$emit('activated')"
    @close="$emit('close')"
    @save-position="$emit('save-position', $event)"
    @save-size="$emit('save-size', $event)"
    @reset-position="$emit('reset-position')"
  >
    <div
      class="relative w-full h-full flex justify-between items-center gap-8 bg-gray-50 border-2 rounded-md p-4"
    >
      <div class="w-[70%]">
        <div class="mb-4 mx-8">
          <TrackingSlider
            :trackingMarkersLength="trackingMarkersLength"
            :cutValue="cutValue"
          />
        </div>
        <div class="mt-20 mb-4 mx-8">
          <CutTrainingSlider
            :trackingMarkersLength="reports.TRACKING.x.length"
            @cutTrackingValue="onCutTrackingValue"
            @changeCutValue="onChangeCutValue"
          />
        </div>
      </div>

      <div class="w-[30%]">
        <TrackingControlButtons
          :trackingMarkersLength="trackingMarkersLength"
          :cutValue="cutValue"
        />

        <NavigationControls
          :movements="movements"
          :historyLength="historyLength"
          @update:historyLength="$emit('update:historyLength', $event)"
          @cutTrackingValue="onCutTrackingValue"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import TrackingSlider from "@/modules/training/submodules/tracking/components/TrackingSlider.vue";
import CutTrainingSlider from "@/modules/training/submodules/tracking/components/CutTrainingSlider.vue";
import TrackingControlButtons from "@/modules/training/submodules/tracking/components/TrackingControlButtons.vue";
import NavigationControls from "../NavigationControls.vue";

export default {
  name: "NavigationModal",
  components: {
    BaseModal,
    TrackingSlider,
    CutTrainingSlider,
    TrackingControlButtons,
    NavigationControls,
  },
  props: {
    position: {
      type: Object,
      required: true,
    },
    zIndex: {
      type: Number,
      required: true,
    },
    trackingMarkersLength: {
      type: Number,
      required: true,
    },
    cutValue: {
      type: Array,
      required: true,
    },
    reports: {
      type: Object,
      required: true,
    },
    movements: {
      type: Array,
      required: true,
    },
    historyLength: {
      type: Number,
      required: true,
    },
  },
  emits: [
    "activated",
    "close",
    "save-position",
    "save-size",
    "reset-position",
    "cut-tracking-value",
    "change-cut-value",
    "update:historyLength",
  ],
  methods: {
    onCutTrackingValue(val) {
      this.$emit("cut-tracking-value", val);
    },
    onChangeCutValue(val) {
      this.$emit("change-cut-value", val);
    },
  },
};
</script>

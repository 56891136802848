<template>
  <div
    class="w-full p-4 flex justify-between flex-wrap bg-white shadow rounded-lg"
  >
    <p class="w-full text-[#5A5A5F] text-[21px] font-semibold text-center mb-4">
      {{ title }}
    </p>
    <div class="w-full flex flex-wrap justify-between">
      <div class="p-2">
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-2">
            <p
              class="text-[#5A5A5F] text-[18px] font-semibold text-center sm:text-start"
            >
              {{ date }}
            </p>
            <p
              class="text-xs text-[#9A8053] tracking-wider text-center sm:text-start"
            >
              {{ description }}
            </p>
          </div>
          <div class="w-full flex justify-center">
            <Bar class="w-full" :data="filteredData" :options="options" />
          </div>
          <div class="w-full flex flex-wrap justify-between mt-4">
            <div class="max-w-[450px] flex justify-between items-center">
              <div
                v-for="(item, index) of legs"
                :key="index"
                class="flex items-center ml-4"
              >
                <input
                  type="checkbox"
                  class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
                  :id="item.title + title"
                  v-model="item.checked"
                />
                <span
                  class="w-[10px] h-[10px] mx-1 rounded-full"
                  :class="getClass(item.backgroundColor)"
                ></span>
                <label
                  :for="item.title + title"
                  class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer"
                >
                  <span>{{ $t("horse." + item.title) }}</span>
                </label>
              </div>
            </div>
            <div class="max-w-[450px] flex justify-between items-center">
              <div
                v-for="(item, index) of movements"
                :key="index"
                class="flex items-center ml-4"
              >
                <input
                  type="checkbox"
                  class="w-4 h-4 focus:ring-0 focus:ring-offset-0 checked:text-[#5A5A5F] cursor-pointer"
                  :id="item.title + title"
                  v-model="item.checked"
                />
                <label
                  :for="item.title + title"
                  class="text-[14px] font-poppins text-[#5A5A5F] cursor-pointer ml-2"
                >
                  <span>{{ $t("tracking." + item.title) }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale,
} from "chart.js";
import { Bar } from "vue-chartjs";
import "chartjs-adapter-date-fns";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { getClass } from "@/modules/training/helpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  TimeScale
);
ChartJS.defaults.datasets.bar.barThickness = 0.5;
export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    type: String,
    title: String,
    date: String,
    description: String,
    trainingTime: String,
    datasets: Array,
  },
  data() {
    return {
      movements: [
        { title: "Walk", checked: true },
        { title: "Trot", checked: true },
        { title: "Gallop", checked: true },
      ],
      legs: [
        { title: "FL", backgroundColor: "#6A9A53", checked: true },
        { title: "FR", backgroundColor: "#E0712F", checked: true },
        { title: "HL", backgroundColor: "#587DC0", checked: true },
        { title: "HR", backgroundColor: "#FFCD4B", checked: true },
      ],
      options: {
        animation: false,
        responsive: true,
        plugins: {
          crosshair: false,
          colors: {
            forceOverride: true,
          },
          datalabels: false,
          tooltip: {
            callbacks: {
              title: (tooltipItem) => {
                return format(tooltipItem[0].parsed.x, "HH:mm");
              },
              label: (el) => {
                return el.raw.y + " " + "N";
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              callback: function (val) {
                return format(val, "HH:mm");
              },
            },
            type: "time",
            time: {
              displayFormats: {
                quarter: "MMM YYYY",
              },
            },
            barThickness: "1",
            maxBarThickness: 1,
          },
        },
      },
      horseGait: "FL",
      DoughnutColors: ["#1AB0B0", "#FFCD4B", "#F85C7F"],
      colors: ["#6A9A53", "#E0712F", "#587DC0", "#71589F"],
      data: [],
    };
  },
  beforeMount() {
    this.setDataProperties();
  },
  computed: {
    ...mapGetters(["currentTrainingMetadata"]),
    trainingStartTS() {
      const date = new Date(2011, 0, 1, 0, 0, 0, 0);
      return Date.parse(date);
    },
    filteredData() {
      const filteredMovements = this.movements
        .filter((i) => i.checked)
        .map((i) => i.title);
      const filteredLegs = this.legs
        .filter((i) => i.checked)
        .map((i) => i.title);
      const t = this.data.filter(
        (i) =>
          filteredMovements.includes(i.title) && filteredLegs.includes(i.leg)
      );
      return {
        datasets: t,
      };
    },
  },
  methods: {
    getClass,
    convertTimeToMs(str) {
      let times = str.split(":");
      times.reverse();
      let x = times.length,
        y = 0,
        z;
      for (let i = 0; i < x; i++) {
        z = times[i] * Math.pow(60, i);
        y += z;
      }
      return y;
    },
    setChartData(data) {
      const res = [];
      data.ts.map((i, index) => {
        res.push({
          x: this.trainingStartTS + this.convertTimeToMs(i) * 1000,
          y: +data.power[index].toFixed(1),
        });
      });
      return res;
    },
    setDataProperties() {
      const FL = [
        {
          title: "Walk",
          leg: "FL",
          checked: true,
          backgroundColor: "#6A9A53",
          data: this.setChartData(this.datasets.walk.FL),
        },
        {
          title: "Trot",
          leg: "FL",
          checked: true,
          backgroundColor: "#6A9A53",
          data: this.setChartData(this.datasets.trot.FL),
        },
        {
          title: "Gallop",
          leg: "FL",
          checked: true,
          backgroundColor: "#6A9A53",
          data: this.setChartData(this.datasets.gallop.FL),
        },
      ];
      const FR = [
        {
          title: "Walk",
          leg: "FR",
          checked: true,
          backgroundColor: "#E0712F",
          data: this.setChartData(this.datasets.walk.FR),
        },
        {
          title: "Trot",
          leg: "FR",
          checked: true,
          backgroundColor: "#E0712F",
          data: this.setChartData(this.datasets.trot.FR),
        },
        {
          title: "Gallop",
          leg: "FR",
          checked: true,
          backgroundColor: "#E0712F",
          data: this.setChartData(this.datasets.gallop.FR),
        },
      ];
      const BL = [
        {
          title: "Walk",
          leg: "HL",
          checked: true,
          backgroundColor: "#587DC0",
          data: this.setChartData(this.datasets.walk.BL),
        },
        {
          title: "Trot",
          leg: "HL",
          checked: true,
          backgroundColor: "#587DC0",
          data: this.setChartData(this.datasets.trot.BL),
        },
        {
          title: "Gallop",
          leg: "HL",
          checked: true,
          backgroundColor: "#587DC0",
          data: this.setChartData(this.datasets.gallop.BL),
        },
      ];
      const BR = [
        {
          title: "Walk",
          leg: "HR",
          checked: true,
          backgroundColor: "#FFCD4B",
          data: this.setChartData(this.datasets.walk.BL),
        },
        {
          title: "Trot",
          leg: "HR",
          checked: true,
          backgroundColor: "#FFCD4B",
          data: this.setChartData(this.datasets.trot.BL),
        },
        {
          title: "Gallop",
          leg: "HR",
          checked: true,
          backgroundColor: "#FFCD4B",
          data: this.setChartData(this.datasets.gallop.BL),
        },
      ];
      this.data = [...FR, ...FL, ...BL, ...BR];
    },
  },
};
</script>

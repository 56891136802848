const mutations = {
  SET_TOKEN(state, data) {
    localStorage.setItem(
      "horsecare-userTokens",
      JSON.stringify({
        token: data._tokenResponse.idToken,
        refreshToken: data._tokenResponse.refreshToken,
      })
    );
    state.token = data;
  },

  SET_USER_DATA(state, data) {
    state.user = data;
  },
};

export default mutations;

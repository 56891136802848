export const findLastNonStayElementIndex = (arr, endIndex) => {
  for (let i = endIndex; i >= 0; i--) {
    if (arr[i] !== "stay") {
      return i;
    }
  }
  return -1;
};

export const getGaitColor = (gaitType) => {
  switch (gaitType) {
    case "walk":
      return "#1AB0B0";
    case "trot":
      return "#FFCD4B";
    case "gallop":
      return "#F85C7F";
    default:
      return "silver";
  }
};

export const convertToDeg = (rad) => {
  const pi = Math.PI;
  return rad * (180 / pi);
};

export const setGaitColor = (gaitType) => {
  if (gaitType === "stay") return "grey";
  else if (gaitType === "walk") return "#1AB0B0";
  else if (gaitType === "trot") return "#FFCD4B";
  else if (gaitType === "gallop") return "#F85C7F";
};

const getters = {
  horsesList: (state) => state.horsesList,
  totalElements: (state) => state.totalElements,
  horseTrackingCard: (state) => state.horseTrackingCard,

  sharingInList: (state) => state.sharingInList,
  sharingOutList: (state) => state.sharingOutList,
};

export default getters;

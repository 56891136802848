<template>
  <div
    class="separate-modals absolute top-0 left-0"
    :style="{ zIndex: mainLayer === 'maps' ? 53 : 52 }"
  >
    <vue-draggable-resizable
      v-if="isFullscreenMapOpen"
      ref="map"
      :key="mapDragKey"
      :active="true"
      :x="separateWindowPositions.map.x"
      :y="separateWindowPositions.map.y"
      :w="separateWindowPositions.map.width"
      :h="separateWindowPositions.map.height"
      :drag-handle="'.modal-header'"
      :drag-cancel="'.drag-cancel'"
      :resizable="true"
      :minWidth="450"
      :minHeight="450"
      :handles="['tr', 'mr', 'br', 'bl', 'ml']"
      @dragStop="onDragStop"
      @resizeStop="onResizeStop"
      @activated="handleActivated"
      :onDrag="onDrag"
      :onResize="onResize"
    >
      <div class="modal h-full overflow-hidden">
        <div
          class="modal-header bg-gray-800 text-white p-2 cursor-move flex justify-between items-center"
        >
          <div>
            <span>Google Maps</span>
            <button
              @click="resetPosition"
              class="drag-cancel ml-4 p-2 cursor-pointer bg-white text-gray-800 rounded-md hover:bg-gray-100"
            >
              Reset position
            </button>
          </div>
          <button
            class="drag-cancel text-red-500 hover:text-red-700 font-bold"
            @click="handleCancel"
          >
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="modal-content relative h-full-available">
          <GoogleMaps
            mapId="fullscreenMap"
            :tracking="tracking"
            :selectedGaits="selectedGaits"
            :trackingMarkersLength="trackingMarkersLength"
            :isTrackColored="isTrackColored"
            :fromToCutValue="fromToCutValue"
            :cutValue="cutValue"
          />
        </div>
      </div>
    </vue-draggable-resizable>
  </div>
</template>

<script>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import VueDraggableResizable from "vue-draggable-resizable";
import GoogleMaps from "@/modules/training/submodules/tracking/components/GoogleMaps.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { defaultSeparateWindowsPosition } from "@/modules/training/constants";

export default {
  name: "GoogleMapsModal",
  components: {
    GoogleMaps,
    VueDraggableResizable,
    XMarkIcon,
  },
  props: {
    tracking: Array,
    selectedGaits: Array,
    trackingMarkersLength: Number,
    isTrackColored: Boolean,
    fromToCutValue: Array,
    cutValue: Array,
  },
  data() {
    return {
      mapDragKey: 0,
      safeMargin: 8,
      isUserInteractionBlocked: false,
      currentCoordinates: {
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "separateWindowPositions",
      "isFullscreenMapOpen",
      "mainLayer",
    ]),
  },
  watch: {
    isFullscreenMapOpen(newVal) {
      if (newVal) this.checkInitialSize();
    },
  },
  methods: {
    ...mapMutations(["SET_IS_FULLSCREEN_MAP_OPEN", "SET_MAIN_LAYER"]),
    ...mapActions(["updateWindowPosition"]),

    async checkInitialSize() {
      const root = document.getElementById("app");
      if (!root) return;

      const { width: appWidth, height: appHeight } =
        root.getBoundingClientRect();
      const modalWidth = this.separateWindowPositions.map.width;
      const modalHeight = this.separateWindowPositions.map.height;
      const modalX = this.separateWindowPositions.map.x;
      const modalY = this.separateWindowPositions.map.y;

      // Check if modal exceeds viewport borders
      const exceedsWidth =
        modalX + modalWidth > appWidth - this.safeMargin ||
        modalX < this.safeMargin;
      const exceedsHeight =
        modalY + modalHeight > appHeight - this.safeMargin ||
        modalY < this.safeMargin;

      // If modal size or position exceeds the viewport, reset position
      if (
        exceedsWidth ||
        exceedsHeight ||
        modalWidth > appWidth ||
        modalHeight > appHeight
      ) {
        await this.$nextTick();
        this.resetPosition();
      }
    },

    blockUserActions() {
      document.body.style.userSelect = "none";
      this.isUserInteractionBlocked = true;
    },

    unblockUserActions() {
      document.body.style.userSelect = "";
      this.isUserInteractionBlocked = false;
    },

    onDrag(left, top) {
      const root = document.getElementById("app");
      if (!root) return;

      const modal = this.$refs.map.$el;
      if (!modal) return;

      if (!this.isUserInteractionBlocked) this.blockUserActions();

      const rect = modal.getBoundingClientRect();
      const { width: appWidth, height: appHeight } =
        root.getBoundingClientRect();
      const modalWidth = rect.width;
      const modalHeight = rect.height;

      const safe = this.safeMargin;
      const offset = 10;

      const minLeft = safe + offset;
      const maxLeft = appWidth - modalWidth - safe - offset;
      const minTop = safe + offset;
      const maxTop = appHeight - modalHeight - safe - offset;

      let newLeft = left;
      let newTop = top;

      if (left < minLeft) {
        newLeft = minLeft;
      } else if (left > maxLeft) {
        newLeft = maxLeft;
      }

      if (top < minTop) {
        newTop = minTop;
      } else if (top > maxTop) {
        newTop = maxTop;
      }

      this.currentCoordinates = {
        x: newLeft,
        y: newTop,
      };

      if (newLeft !== left || newTop !== top) {
        modal.style.transform = `translate(${newLeft}px, ${newTop}px)`;
        return false;
      }
    },

    onDragStop() {
      this.unblockUserActions();
      this.saveCoordinates(
        this.currentCoordinates.x,
        this.currentCoordinates.y,
        "map"
      );
    },

    onResize(handle, x, y, width, height) {
      const root = document.getElementById("app");
      if (!root) return;

      const modal = this.$el;
      if (!modal) return;

      const { width: appWidth, height: appHeight } =
        root.getBoundingClientRect();

      const safe = this.safeMargin;
      const offset = 10;

      const minLeft = safe + offset;
      const maxLeft = appWidth - width - safe - offset;
      const minTop = safe + offset;
      const maxTop = appHeight - height - safe - offset;

      if (x < minLeft || x > maxLeft || y < minTop || y > maxTop) {
        return false;
      }
    },

    onResizeStop(left, top, width, height) {
      this.unblockUserActions();
      this.saveSize(left, top, width, height, "map");
    },

    handleCancel() {
      this.SET_IS_FULLSCREEN_MAP_OPEN(false);
    },

    async saveCoordinates(x, y, windowKey) {
      await this.updateWindowPosition({
        key: windowKey,
        ...this.separateWindowPositions[windowKey],
        x,
        y,
      });
    },

    async saveSize(x, y, width, height, windowKey) {
      await this.updateWindowPosition({
        key: windowKey,
        ...this.separateWindowPositions[windowKey],
        x,
        y,
        width,
        height,
      });
    },

    resetPosition() {
      this.updateWindowPosition({
        key: "map",
        ...defaultSeparateWindowsPosition.map,
      });
    },

    handleActivated() {
      this.SET_MAIN_LAYER("maps");
    },
  },

  mounted() {},

  beforeUnmount() {},
};
</script>

<style lang="scss" scoped>
.modal {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vdr {
  border: none;
}
</style>
